import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import theme from 'src/styles/theme'
import {
  removeInlineStyles,
  sanitizeHtmlString,
} from './RichTextRendererDangerously.utils'
import { RichTextRendererDangerouslyProps } from './RichTextRendererDangerously.types'

const compStyles = makeStyles({
  root: {
    '& p, & div, & span': { ...theme.typography.body1 },
    '& a': {
      ...theme.typography.body1,
      textDecoration: 'underline',
    },
    '& strong': { ...theme.typography.body2 },
    '& b': { ...theme.typography.body2 },
    '& em': {
      ...theme.typography.body1,
      fontStyle: 'italic',
    },
    '& u': {
      ...theme.typography.body1,
      textDecoration: 'underline',
    },
    '& ul, & ol': {
      paddingLeft: `${theme.spacing(3.125)}px`,
    },
    '& li': { ...theme.typography.body1 },
    '& ol': {
      margin: theme.spacing(1, 0),
    },
    '& p:first-of-type': {
      marginTop: 0,
    },
  },
})

export const RichTextRendererDangerously: React.FC<RichTextRendererDangerouslyProps> =
  ({ htmlString }) => {
    // Remove inline styles
    const htmlStringWithoutInlineStyles = removeInlineStyles(htmlString)

    // Reduce potential for XSS
    const sanitizedString = sanitizeHtmlString(htmlStringWithoutInlineStyles)

    const classes = compStyles()

    return (
      <div
        className={classes.root}
        dangerouslySetInnerHTML={{ __html: sanitizedString }}
      />
    )
  }
