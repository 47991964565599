import { useTranslation } from 'react-i18next'

export interface LoadingTranslations {
  LOADING: string
  LOADING_ERROR_TITLE: string
  LOADING_ERROR_SUBTITLE: string
  HOME_BUTTON: string
  REFRESH_BUTTON: string
}

export const useLoadingTranslations = (): LoadingTranslations => {
  const { t } = useTranslation()

  const translate = (key: string) => {
    return t(`Global.${key}`)
  }

  const translateLoadingError = (key: string) => {
    return t(`Global.loadingError.${key}`)
  }

  const translateButtonLabel = (key: string) => {
    return t(`Global.buttonLabels.${key}`)
  }

  return {
    LOADING: translate('loading'),
    LOADING_ERROR_TITLE: translateLoadingError('title'),
    LOADING_ERROR_SUBTITLE: translateLoadingError('subtitle'),
    HOME_BUTTON: translateButtonLabel('home'),
    REFRESH_BUTTON: translateButtonLabel('refresh'),
  }
}
