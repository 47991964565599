import useUser from '../useUser'
import { isErrorResult } from '../../utils/typeGuards'
import { useEffect, useState } from 'react'
import {
  AgreementDocumentResult,
  AgreementType,
  SignedAgreement,
} from '../../generated/graphql'
import {
  useGetAgreementDocumentLazyQuery,
  useSetSignedAgreementDataMutation,
} from '../../generated/hooks'
import { AgreementStatus } from './agreement.types'

const useAgreement = (agreementType: AgreementType) => {
  const {
    data: { fullUser },
    operations: { updateUser },
  } = useUser()

  const [status, setStatus] = useState(AgreementStatus.Loading)

  const [documentToSign, setDocumentToSign] = useState<null | Pick<
    AgreementDocumentResult,
    'version' | 'content'
  >>(null)

  // Check if the user has signed privacy agreement(s) already
  const getExistingSignedAgreements = () => {
    return (
      fullUser?.user.signedAgreements?.filter(
        item => item.type === agreementType
      ) ?? []
    )
  }
  const [existingSignedAgreements, setExistingSignedAgreements] = useState<
    SignedAgreement[]
  >(
    fullUser?.user.signedAgreements?.filter(
      item => item.type === agreementType
    ) ?? []
  )

  useEffect(() => {
    setExistingSignedAgreements(
      fullUser?.user.signedAgreements?.filter(
        item => item.type === agreementType
      ) ?? []
    )
  }, [fullUser?.user.signedAgreements, agreementType])

  const onError = () => {
    setStatus(
      existingSignedAgreements.length > 0
        ? AgreementStatus.SignedUnverified
        : AgreementStatus.FetchAgreementError
    )
  }

  const [getAgreementDocument, { data, loading, error }] =
    useGetAgreementDocumentLazyQuery()

  const [setSignedAgreementData] = useSetSignedAgreementDataMutation({})

  // 1. If instructor profile and use has no signed instructor agreement -> fetch the latest version
  // 2. If club profile and user has no signed privacy agreement -> fetch the latest version
  // 3. Otherwise, need to check which version they signed (don't over-fetch by
  //    fetching the whole agreement every time the user opens the app).
  useEffect(() => {
    setStatus(AgreementStatus.Loading)
    if (getExistingSignedAgreements().length === 0) {
      getAgreementDocument({
        variables: {
          input: {
            type: agreementType,
          },
        },
      })
    } else {
      getAgreementDocument({
        variables: {
          input: {
            type: agreementType,
          },
          versionOnly: true,
        },
      })
    }
  }, [agreementType]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setStatus(val => (loading ? AgreementStatus.Loading : val))
  }, [loading])

  // When the version data changes:
  // 1. If not club nor instructor
  // 2. If error or missing, call onError
  // 3. If success, check the latest version matches with the currently signed version (if any)
  //    a. No -> trigger fetch of the full document content
  //    b. Yes -> set status to Valid
  useEffect(() => {
    if (!data) {
      return
    }
    if (getExistingSignedAgreements().length > 0) {
      const documentData = data.getAgreementDocument
      if (documentData && !isErrorResult(documentData)) {
        const existingSignedAgreementValid =
          !!getExistingSignedAgreements().find(
            item => item.version === documentData.version
          )
        if (existingSignedAgreementValid) {
          setStatus(AgreementStatus.Valid)
        } else {
          if ('content' in documentData) {
            if (documentData && !isErrorResult(documentData)) {
              setStatus(AgreementStatus.SigningRequired)
              setDocumentToSign(documentData)
            } else {
              onError()
            }
          } else {
            getAgreementDocument({
              variables: {
                input: {
                  type: agreementType,
                },
              },
            })
          }
        }
      } else {
        onError()
      }
    } else {
      const documentData = data.getAgreementDocument

      if (documentData && !isErrorResult(documentData)) {
        setStatus(AgreementStatus.SigningRequired)
        setDocumentToSign(documentData)
      } else {
        onError()
      }
    }
  }, [data, agreementType]) // eslint-disable-line react-hooks/exhaustive-deps

  // If error fetching latest version or the latest document, call onError
  useEffect(() => {
    if (error) {
      onError()
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  const signDocument = async () => {
    setStatus(AgreementStatus.SigningInProgress)
    try {
      const result = await setSignedAgreementData({
        variables: {
          input: {
            version: documentToSign!.version,
            type: agreementType,
          },
        },
      })
      const data = result?.data?.setSignedAgreementData

      if (data && !isErrorResult(data)) {
        setStatus(AgreementStatus.Valid)

        // update cached user
        const { signedOn, version, agreementType } = data
        updateUser({
          user: {
            ...fullUser!.user,
            signedAgreements: [
              ...(fullUser!.user.signedAgreements ?? []),
              { signedOn, version, type: agreementType },
            ],
          },
        })
      } else {
        setStatus(AgreementStatus.SigningError)
      }
    } catch (e) {
      setStatus(AgreementStatus.SigningError)
    }
  }

  const retryGetAgreement = () => {
    setStatus(AgreementStatus.FetchAgreementErrorRetrying)
    getAgreementDocument()
  }

  return {
    data: {
      status,
      documentToSign,
    },
    operations: {
      retryGetAgreement,
      signDocument,
    },
  }
}

export default useAgreement
