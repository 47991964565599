import { useMemo } from 'react'
import {
  MessageBusNotificationType,
  NotificationItemFragment,
} from 'src/generated/graphql'
import { useTranslation } from 'react-i18next'

export const useGetNotificationContent = (
  notifications: NotificationItemFragment[]
): NotificationItemFragment[] => {
  const { t } = useTranslation()

  const processedNotifications = useMemo(() => {
    return notifications
      .map(notification => {
        const { isNew, contentType, params } = notification
        if (isNew) {
          switch (contentType) {
            case MessageBusNotificationType.ClubJobListingEnquiryFromProfile:
              return {
                // share content
                title: t(
                  'notificationEvents.club.instructor_listing_enquiry_from_profile.title'
                ),
                body: t(
                  'notificationEvents.club.instructor_listing_enquiry_from_profile.body',
                  { userName: params?.userName }
                ),
              }
            default:
              console.error(
                `no content key for notification ${notification.id}`
              )
              return undefined
          }
        }
        return notification
      })
      .filter(item => item) as NotificationItemFragment[]
  }, [notifications, t])

  return processedNotifications
}
