import React from 'react'

import useLogout from 'src/hooks/useLogout'
import PageNotFound from 'src/components/PageNotFound'
import useIsSupportedPath from 'src/hooks/useIsSupportedPath'

const Logout: React.FC = () => {
  const isSupportedPath = useIsSupportedPath()
  const { logout } = useLogout()
  if (!isSupportedPath) {
    return <PageNotFound />
  }
  logout()
  return null
}

export default Logout
