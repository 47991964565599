import React from 'react'
import { createPortal } from 'react-dom'
import styled, { css } from 'styled-components'
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import { Container, Slide, Typography, IconButton } from '@material-ui/core'
import { Box } from 'src/adaptor/materialUI'
import Illustration, {
  UtilityIcon,
} from 'src/components/dataDisplay/Illustration'

export enum ToastTypeEnum {
  INFO = 'info',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export interface ToastTypeProps {
  toastType: ToastTypeEnum | undefined
}

const anchorOrigin: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'center',
}

const ToastBar = styled(Snackbar)(
  ({ theme }) => css`
    width: 100vw;
    top: 0;
    left: 0;
    padding: 0;

    ${theme.breakpoints.up('sm')} {
      left: 50%;
    }
  `
)

const ToastContent = styled.div<{ $toastType: ToastTypeEnum | undefined }>(
  ({ $toastType, theme }) => css`
    padding: ${theme.spacing(2.5, 0, 2)};
    width: 100%;
    border-radius: 0;
    min-height: ${theme.spacing(7)}px;
    background-color: ${theme.palette.brand.offWhite};
    color: ${$toastType && theme.palette.toast[$toastType]};

    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing(2, 0)};
    }

    .MuiSnackbarContent-message {
      width: 100%;
      padding: ${theme.spacing(2, 0)};
    }
  `
)

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const MessageContainer = styled(Container)`
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
`

const getIcon = (variant?: ToastTypeEnum) => {
  switch (variant) {
    case ToastTypeEnum.ERROR:
      return <Illustration type={UtilityIcon.Error} />
    case ToastTypeEnum.SUCCESS:
      return <Illustration type={UtilityIcon.Success} />
    case ToastTypeEnum.WARNING:
      return <Illustration type={UtilityIcon.Warning} />
    default:
  }
}

interface ToastProps {
  isOpen: boolean
  message?: React.ReactNode
  icon?: React.ReactNode
  variant?: ToastTypeEnum
  handleClose: () => void
  duration?: number
  disableAutoClose?: boolean
}

const DEFAULT_AUTOHIDE_DURATION = 5000

const Toast: React.FC<ToastProps> = ({
  icon,
  isOpen,
  message,
  variant,
  duration,
  handleClose,
  disableAutoClose,
  ...props
}) => {
  const toastIcon = icon || getIcon(variant)
  return createPortal(
    <ToastBar
      open={isOpen}
      onExit={handleClose}
      onClose={disableAutoClose ? undefined : handleClose}
      anchorOrigin={anchorOrigin}
      TransitionComponent={Slide}
      disableWindowBlurListener
      autoHideDuration={duration || DEFAULT_AUTOHIDE_DURATION}
    >
      <ToastContent
        data-qa-id="toast-bar-content"
        $toastType={variant}
        {...props}
      >
        <MessageContainer data-testid={`toast-${variant}`}>
          <StyledBox flexGrow={1}>
            {toastIcon && <StyledBox mr={2}>{toastIcon}</StyledBox>}
            <Typography variant="body2">{message}</Typography>
          </StyledBox>
          <IconButton
            data-qa-id="toast-close-icon"
            data-testid="toast-close-button"
            disableRipple
            onClick={handleClose}
          >
            <CloseRoundedIcon />
          </IconButton>
        </MessageContainer>
      </ToastContent>
    </ToastBar>,
    document.body
  )
}

export default Toast
