import { PropsWithChildren } from 'react'
import { QueryClientProvider, QueryClient } from 'react-query'

const reactQueryClient = new QueryClient()

const ReactQueryProvider = ({ children }: PropsWithChildren<{}>) => {
  return (
    <QueryClientProvider client={reactQueryClient}>
      {children}
    </QueryClientProvider>
  )
}

export default ReactQueryProvider
