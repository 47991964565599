import { GraphQLClient } from 'graphql-request'

export const graphQLClient = new GraphQLClient(
  import.meta.env.REACT_APP_API_URL!,
  {
    headers: {
      'X-API-KEY': import.meta.env.REACT_APP_X_API_KEY!,
    },
  }
)
