import styled, { css } from 'styled-components'
import { IconButton } from '@material-ui/core'

const AppHeaderIconButton = styled(IconButton)<{
  $isMobileLayout?: boolean
  $isDashboard?: boolean
}>(
  ({ theme, $isMobileLayout, $isDashboard }) => css`
    width: 24px;
    height: 24px;
    color: ${theme.palette.brand.white};
    margin-left: ${theme.spacing(2)}px;
    padding: 0;
    ${!$isMobileLayout &&
    css`
      width: 48px;
      height: 48px;
      color: ${$isDashboard
        ? theme.palette.brand.black
        : theme.palette.brand.white};
    `}
  `
)

export default AppHeaderIconButton
