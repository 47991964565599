import React, { createContext, PropsWithChildren, useState } from 'react'
import Cookies from 'js-cookie'
import { PROFILE_ID_KEY } from 'src/contexts/constants'

interface UserState {
  selectedProfileId: string
  setSelectedProfileId: (profileId: string) => void
}

const initialState: UserState = {
  selectedProfileId: '',
  setSelectedProfileId: () => {},
}

export const profileContext = createContext<UserState>(initialState)

profileContext.displayName = 'ProfileContext'
const { Provider } = profileContext

export const ProfileProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [selectedProfileId, setSelectedProfileId] = useState(
    Cookies.get(PROFILE_ID_KEY) ?? ''
  )
  const handleSetSelectedProfile = (id: string) => {
    setSelectedProfileId(id)
    Cookies.set(PROFILE_ID_KEY, id)
  }
  return (
    <Provider
      value={{
        selectedProfileId,
        setSelectedProfileId: handleSetSelectedProfile,
      }}
    >
      {children}
    </Provider>
  )
}
