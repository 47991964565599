import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import useContentScroll from 'src/hooks/useContentScroll'
export default function ScrollToTop() {
  const { pathname } = useLocation()
  const { scrollToTop } = useContentScroll()

  useEffect(() => {
    scrollToTop()
  }, [pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}
