import React from 'react'

import ErrorBoundary from 'src/utils/ErrorBoundary'

import SharedPlatformNavigationDialog from 'src/modules/SharedPlatformNavigation/SharedPlatformNavigationDialog'

import useSharedPlatformNavigation from 'src/hooks/sharedPlatformNavitagion/useSharedPlatformNavigation'

// Analytics
import useAnalytics from 'src/hooks/useAnalytics'
import {
  LocalAnalyticsEvent,
  EventAction,
  EventCategory,
} from 'src/contexts/Analytics'
export interface SharedPlatformNavigationContainerProps {
  isDashboard: boolean
}

const SharedPlatformNavigationContainer: React.FC<SharedPlatformNavigationContainerProps> =
  ({ isDashboard }) => {
    const {
      data: {
        instructorProfile,
        clubProfiles,
        user,
        role,
        activeProfile,
        instructorItems,
        clubItems,
        websiteLink,
        isLoading,
        isRefetching,
        isError,
      },
      operations: { getSharedNavItems, refetch },
    } = useSharedPlatformNavigation()

    const analytics = useAnalytics()

    const handleReloadClick = () => {
      analytics.sendEvent(
        LocalAnalyticsEvent.SharedPlatformNavigationOpenDialog,
        EventCategory.Button,
        EventAction.Click
      )
      refetch?.()
    }

    const handleNavigationItemTrackingEvent = ({
      itemId,
      itemTitle,
    }: {
      itemId?: string
      itemTitle?: string
    }) =>
      analytics.sendEvent(
        LocalAnalyticsEvent.SharedPlatformNavitgationItem,
        EventCategory.Button,
        EventAction.Click,
        {
          navigationItemId: itemId,
          navigationItemTitle: itemTitle,
          market: user?.market.name,
          role: role.roleType,
        }
      )

    const trackOpenDialogEvent = () => {
      analytics.sendEvent(
        LocalAnalyticsEvent.SharedPlatformNavigationOpenDialog,
        EventCategory.Button,
        EventAction.Click
      )
      getSharedNavItems?.()
    }

    return (
      <SharedPlatformNavigationDialog
        isDashboard={isDashboard}
        trackAnalytics={trackOpenDialogEvent}
        headerContent={{
          instructorProfile,
          clubProfiles,
          user,
          activeProfile,
        }}
        bodyContent={{
          instructorItems,
          clubItems,
          isLoading,
          isRefetching,
          isError,
          handleReload: handleReloadClick,
          handleAnalyticsEvent: handleNavigationItemTrackingEvent,
        }}
        footerContent={{ websiteLink }}
      />
    )
  }

const SharedPlatformNavigationContainerWithErrorBoundary: React.FC<SharedPlatformNavigationContainerProps> =
  props => (
    <ErrorBoundary>
      <SharedPlatformNavigationContainer {...props} />
    </ErrorBoundary>
  )

export default SharedPlatformNavigationContainerWithErrorBoundary
