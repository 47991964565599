import React from 'react'

import { SvgIcon, SvgIconProps } from '@material-ui/core'

const HeartOutline: React.FC<SvgIconProps> = React.memo(props => (
  <SvgIcon fill="none" viewBox="0 0 24 24" {...props}>
    <path
      d="M16.5002 3c-1.74 0-3.41.81-4.5 2.09C10.9102 3.81 9.24024 3 7.50024 3c-3.08 0-5.5 2.42-5.5 5.5 0 3.78 3.4 6.86 8.54996 11.54l1.45 1.31 1.45-1.32c5.15-4.67 8.55-7.75 8.55-11.53 0-3.08-2.42-5.5-5.5-5.5zm-4.4 15.55l-.1.1-.1-.1C7.14024 14.24 4.00024 11.39 4.00024 8.5c0-2 1.5-3.5 3.5-3.5 1.21559 0 2.40626.61683 3.11466 1.55049.3394.4474.8256.80951 1.3872.80951.5647 0 1.0528-.36632 1.3938-.81653C14.1 5.61374 15.2877 5 16.5002 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z"
      fill="#7F7F7F"
    />
  </SvgIcon>
))

export default HeartOutline
