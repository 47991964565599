import React from 'react'
import { Button } from '@material-ui/core'
import { Box } from 'src/adaptor/materialUI'
import styled, { css } from 'styled-components'
import AvatarCard from 'src/components/dataDisplay/AvatarCard/AvatarCard'
import {
  Illustration,
  LinkTypeIcon,
  UtilityIcon,
} from 'src/components/dataDisplay/Illustration'

const StyledButton = styled(Button)<{ selected?: boolean }>(
  ({ theme, selected }) => css`
    width: 100%;
    padding: ${theme.spacing(2, 2)};
    border-color: ${selected
      ? theme.palette.brand.gold
      : theme.palette.brand.gray} !important;
    justify-content: space-between;
  `
)

interface ProfileSelectionItemProps {
  photoUrl?: string
  name: string
  description: string
  onClick?: () => void
  showArrow?: boolean
  selected?: boolean
  isActiveProfile?: boolean
  hideImage?: boolean
  fullWidth?: boolean
  isClubProfile?: boolean
  isClubItem?: boolean
}

const ProfileSelectionItem: React.FC<ProfileSelectionItemProps> = ({
  photoUrl,
  name,
  description,
  onClick,
  selected,
  showArrow,
  isActiveProfile,
  fullWidth,
  hideImage,
  isClubProfile,
  isClubItem,
}) => {
  const testIdValue = isClubItem
    ? 'club-profile-item'
    : isClubProfile
    ? 'club-profile'
    : 'instructor-profile'
  return (
    <StyledButton
      data-testid={testIdValue}
      variant="outlined"
      onClick={onClick}
      selected={selected}
    >
      <AvatarCard
        photoUrl={photoUrl}
        name={name}
        description={description}
        fullWidth={fullWidth}
        hideImage={hideImage}
      />
      <Box display="flex">
        {isActiveProfile && (
          <Illustration
            ml={2}
            type={UtilityIcon.GoldTick}
            width={16}
            height={16}
          />
        )}
        {showArrow && (
          <Illustration
            ml={0.5}
            type={LinkTypeIcon.Chevron}
            width={16}
            height={16}
          />
        )}
      </Box>
    </StyledButton>
  )
}

export default ProfileSelectionItem
