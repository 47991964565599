import { ReactElement } from 'react'
import { Flags, FlagsProps } from 'src/hooks/featureFlag/types'

const flagFilters = [
  {
    paths: [
      '/instructor/training-events',
      '/instructor/training-events/video-guidelines',
    ],
    flag: Flags.trainingAndAssessmentInitial,
  },
  {
    paths: ['/instructor/trainer', '/instructor/trainer/:eventId'],
    flag: Flags.trainingAndAssessmentInitialTrainer,
  },
  {
    paths: ['/club/content/:slug', '/instructor/content/:slug'],
    flag: Flags.prismicContent,
  },
]

export const getRoutesAfterFeatureFlags = (
  featureFlags: FlagsProps | undefined,
  routes: ReactElement[]
) => {
  if (!featureFlags) return routes

  return routes.filter(route => {
    const isFeatureFlaggedRoute = flagFilters.find(
      filter =>
        filter.paths.some(path => path === route.props.path) &&
        !featureFlags[filter.flag]
    )
    if (isFeatureFlaggedRoute) return false

    return true
  })
}
