import styled, { css } from 'styled-components'
import { Box } from 'src/adaptor/materialUI'

export const MetaInfo = styled(Box)(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    padding-top: ${theme.spacing(2.5)}px;
  `
)
