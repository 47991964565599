import { useEffect, useState } from 'react'
import { getProgramsByMarket, Program } from 'src/adaptor/programConfig'
import { useMarket } from './useMarket'
import { ProgramConfigDic } from 'src/contexts/ProgramProvider'

function useProgramConfigAdaptor(data: ProgramConfigDic | null) {
  const [transformedData, setTransformedData] = useState<Program[] | null>(null)
  const { getUserMarket } = useMarket()
  const userMarket = getUserMarket()

  useEffect(() => {
    if (!data || !userMarket) return

    setTransformedData(getProgramsByMarket(userMarket, data))
  }, [data, userMarket])

  return transformedData
}

export default useProgramConfigAdaptor
