import DOMPurify from 'dompurify'

export const removeInlineStyles = (htmlString: string) => {
  const htmlNode = document.createElement('div')
  htmlNode.innerHTML = htmlString
  htmlNode.querySelectorAll('*').forEach(function (node) {
    node.removeAttribute('style')
  })
  return htmlNode.innerHTML
}

export const sanitizeHtmlString = (htmlString: string) =>
  DOMPurify.sanitize(htmlString)
