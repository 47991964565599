import { useMemo, useContext } from 'react'

import { UserProfileType } from 'src/generated/graphql'
import { isErrorResult } from 'src/utils/typeGuards'
import { InitDataContext } from 'src/contexts/InitData'
import { UserProfile } from 'src/hooks/useProfile'

export const profileTypeValues = Object.values(UserProfileType)

// Note: these utility things are tricky
// we have to map the UserProfileTypeName values to UserProfileType values
// we use the UserProfileType as url path param (lowercase)
export enum UserProfileTypeName {
  Teaching = 'UserProfileTeaching',
  Club = 'UserProfileClub',
}
export const userProfileTypeToUserProfileTypename = (
  type?: UserProfileType
): UserProfileTypeName | null => {
  switch (type) {
    case UserProfileType.Instructor:
      return UserProfileTypeName.Teaching
    case UserProfileType.Club:
      return UserProfileTypeName.Club
    default:
      console.warn(`User profile type not recognized: ${type}`)
      return null
  }
}
export const userProfileTypenameToUserProfileType = (
  typename?: string
): UserProfileType => {
  switch (typename) {
    case UserProfileTypeName.Club:
      return UserProfileType.Club
    case UserProfileTypeName.Teaching:
      return UserProfileType.Instructor
    default:
      // the graphql typename fields always start with double underscore (__)
      return typename?.replace('__', '')?.toUpperCase() as UserProfileType
  }
}

const useProfiles = () => {
  const data = useContext(InitDataContext)

  const profiles: UserProfile[] | null = useMemo(
    () => (data?.user?.profiles as UserProfile[]) ?? null,
    [data]
  )

  const availableProfileTypes = useMemo(
    () =>
      profiles?.reduce((acc: UserProfileType[], profile) => {
        const profileType = userProfileTypenameToUserProfileType(
          profile.__typename
        )
        return acc.includes(profileType) ? acc : [...acc, profileType]
      }, []),
    [profiles]
  )

  const hasError = data && isErrorResult(data.user)

  return {
    data: {
      profiles,
      hasError,
      availableProfileTypes,
    },
  }
}

export default useProfiles
