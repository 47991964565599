import styled, { css } from 'styled-components'
import { Card } from '@material-ui/core'

export const FlexCard = styled(Card)(
  ({ theme }) => css`
    width: 298px; /* as per design */
    height: 396px; /* as per design */
    cursor: pointer;
    min-width: 298px; /* as per design */
    min-height: 419px; /* as per design */
    display: inline-flex;
    flex-direction: column;
    padding: ${theme.spacing(2.5)}px;
    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
    ${theme.breakpoints.up('sm')} {
      width: 298px; /* as per design */
    }
  `
)
