import React from 'react'
import {
  CircularProgress,
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@material-ui/core'

export interface ButtonProps extends MuiButtonProps {
  loading?: boolean
}

export const Button = <C extends React.ElementType>({
  to,
  loading,
  children,
  variant = 'contained',
  color = 'primary',
  ...props
}: MuiButtonProps<C, { component?: C }>) => {
  return (
    <MuiButton
      startIcon={
        loading ? <CircularProgress size={15} color="inherit" /> : undefined
      }
      disabled={loading}
      {...props}
      variant={variant}
      color={color}
      role={to && 'link'}
      to={to}
      disableElevation
      disableRipple
      {...props}
    >
      {children}
    </MuiButton>
  )
}

export default Button
