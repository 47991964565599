import styled from 'styled-components'
import { Box } from 'src/adaptor/materialUI'
import LoadingSpinner from 'src/assets/images/gifs/loadingSpinnerLmi.gif'
import { useLoadingTranslations } from './common.translations'

const ImageFrame = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const DefaultPage = () => {
  const i18n = useLoadingTranslations()
  return (
    <ImageFrame>
      <img src={LoadingSpinner} alt={i18n.LOADING} />
    </ImageFrame>
  )
}

export default DefaultPage
