import { AgreementGuardContainerProps } from './AgreementGuard.types'
import React from 'react'
import useAgreement from '../../../hooks/agreement/useAgreement'
import { AgreementStatus } from '../../../hooks/agreement/agreement.types'
import AppSkeleton from '../../pageSkeletons/App'
import ClubPrivacyAgreement from '../../Agreement/ClubPrivacyAgreement/ClubPrivacyAgreement'
import ClubPrivacyAgreementError from '../../Agreement/ClubPrivacyAgreement/ClubPrivacyAgreementError'
import { AgreementType } from '../../../generated/graphql'
import InstructorAgreementError from '../../Agreement/InstructorAgreement/InstructorAgreementError'
import { InstructorAgreement } from '../../Agreement/InstructorAgreement'

export const AgreementGuardContainer: React.FC<AgreementGuardContainerProps> =
  ({ agreementType, isInstructorAgreementEnabled, children }) => {
    const {
      data: { status, documentToSign },
      operations: { signDocument, retryGetAgreement },
    } = useAgreement(agreementType)

    switch (status) {
      case AgreementStatus.Loading:
        return <AppSkeleton />
      case AgreementStatus.SigningRequired:
      case AgreementStatus.SigningError:
      case AgreementStatus.SigningInProgress: {
        if (agreementType === AgreementType.DataPrivacy)
          return (
            <ClubPrivacyAgreement
              document={documentToSign!}
              signAgreement={signDocument}
              loading={status === AgreementStatus.SigningInProgress}
              error={status === AgreementStatus.SigningError}
            />
          )
        if (
          agreementType === AgreementType.InstructorAgreement &&
          isInstructorAgreementEnabled
        )
          return (
            <InstructorAgreement
              document={documentToSign!}
              signAgreement={signDocument}
              loading={status === AgreementStatus.SigningInProgress}
              error={status === AgreementStatus.SigningError}
            />
          )
        if (
          agreementType === AgreementType.InstructorAgreement &&
          !isInstructorAgreementEnabled
        )
          return <>{children}</>
        return null
      }
      case AgreementStatus.FetchAgreementError:
      case AgreementStatus.FetchAgreementErrorRetrying: {
        if (agreementType === AgreementType.DataPrivacy)
          return (
            <ClubPrivacyAgreementError
              retry={retryGetAgreement}
              isRetrying={
                status === AgreementStatus.FetchAgreementErrorRetrying
              }
            />
          )
        if (agreementType === AgreementType.InstructorAgreement)
          return (
            <InstructorAgreementError
              retry={retryGetAgreement}
              isRetrying={
                status === AgreementStatus.FetchAgreementErrorRetrying
              }
            />
          )
        return null
      }
      case AgreementStatus.SignedUnverified:
      case AgreementStatus.Valid:
      default:
        return <>{children}</>
    }
  }
