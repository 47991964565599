import { useMediaQuery, useTheme } from '@material-ui/core'

/*
value         |0px     600px    960px    1280px   1920px
key           |xs      sm       md       lg       xl
screen width  |--------|--------|--------|--------|-------->
range         |   xs   |   sm   |   md   |   lg   |   xl
*/

const useMediaQueries = () => {
  const theme = useTheme()
  // Match [0, sm)
  // Match [0, 600px)
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))

  // Match [0, md)
  // Match [0, 960px)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  // Match [md, lg)
  // Match [960px, 1280px)
  const isOnlyMediumScreen = useMediaQuery(theme.breakpoints.only('md'))

  // Match [md, ∞)
  // Match [960px, ∞)
  const isUpMediumScreen = useMediaQuery(theme.breakpoints.up('md'))

  // Match [lg, ∞)
  // Match [1280px, ∞)
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'))

  return {
    isExtraSmallScreen,
    isSmallScreen,
    isOnlyMediumScreen,
    isUpMediumScreen,
    isLargeScreen,
  }
}

export default useMediaQueries
