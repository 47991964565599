import styled, { css } from 'styled-components'
import LoadingSpinner from 'src/assets/images/gifs/loadingSpinnerLmi.gif'
import { Button, Typography, Box } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { ClubPaths, InstructorPaths } from 'src/routes'
import useProfile from 'src/hooks/useProfile'
import { isClubProfile } from 'src/utils/typeGuards'
import { useLoadingTranslations } from './common.translations'

const ImageFrame = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const TitleWrapper = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.spacing(2)}px;
    margin-bottom: ${theme.spacing(1)}px;
  `
)

const DefaultErrorPage = () => {
  const i18n = useLoadingTranslations()
  const {
    data: { profile },
  } = useProfile()

  const isClubProfileSelected = !!profile && isClubProfile(profile)

  return (
    <ImageFrame>
      <img src={LoadingSpinner} alt={i18n.LOADING} />
      <TitleWrapper>
        <Typography variant="h6">{i18n.LOADING_ERROR_TITLE}</Typography>
      </TitleWrapper>
      <Typography variant="subtitle1">{i18n.LOADING_ERROR_SUBTITLE}</Typography>
      <Box mt={2} display="flex" justifyContent="space-between">
        <Link
          to={
            isClubProfileSelected
              ? ClubPaths.Dashboard
              : InstructorPaths.Dashboard
          }
        >
          <Button variant="outlined">{i18n.HOME_BUTTON}</Button>
        </Link>
        <Box ml={1.5}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.location.reload()}
          >
            {i18n.REFRESH_BUTTON}
          </Button>
        </Box>
      </Box>
    </ImageFrame>
  )
}

export default DefaultErrorPage
