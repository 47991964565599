import React from 'react'

import { ElementCaptionProp } from './Caption.type'
import {
  CaptionBox,
  StyledIllustration,
  StyledWarningContent,
} from './Captions.styles'

export const ElementCaption: React.FC<ElementCaptionProp> = ({
  element,
  icon,
  isShrink,
  isDark,
  isOneLine,
}) => (
  <CaptionBox $isShrink={isShrink} $isDark={isDark} $isOneLine={isOneLine}>
    {icon && (
      <StyledIllustration type={icon} minWidth={16} $isShrink={isShrink} />
    )}
    <StyledWarningContent $isShrink={isShrink} $isDark={isDark}>
      {element}
    </StyledWarningContent>
  </CaptionBox>
)
