import { Theme } from '@material-ui/core'
import { BrandPalette } from '@material-ui/core/styles/createPalette'

const transformColor = (color: string, theme: Theme) => {
  const { palette } = theme
  if (color.startsWith('brand')) {
    const value =
      palette.brand[
        color.replace(/^brand/, '').toLocaleLowerCase() as keyof BrandPalette
      ]
    if (value) {
      return value
    }
  }
  switch (color) {
    case 'primary':
      return palette.primary.main
    case 'textPrimary':
      return palette.text.primary
    case 'secondary':
      return palette.secondary.main
    case 'textSecondary':
      return palette.text.secondary
    case 'error':
      return palette.error.main
    default:
      return color
  }
}

export default transformColor
