import { TOptions } from 'i18next'
import { useTranslation } from 'react-i18next'

export const useTranslationHelpers = (prefix: string) => {
  const { t } = useTranslation()

  const translate = (key: string, values?: TOptions | string) => {
    return values ? t(translationKey(key), values) : t(translationKey(key))
  }
  const translationKey = (key: string) => {
    return `${prefix}.${key}`
  }

  return { translate, translationKey, t }
}
