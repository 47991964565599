import { useEffect, useState, useCallback } from 'react'
import { useMutation, useQuery } from 'react-query'

import {
  Walkthrough,
  GetWalkthroughContentQuery,
  GetWalkthroughContentDocument,
  SetWalkthroughToSeenMutationVariables,
  SetWalkthroughToSeenMutation,
  SetWalkthroughToSeenDocument,
} from 'src/generated/graphql-react-query'

import { isErrorResult } from 'src/utils/typeGuards'
import { useGraphQLClientRequest } from 'src/hooks/useGraphQLClientRequest'
import { useWalkthroughUtils } from 'src/hooks/dialog/useWalkthroughUtils'

// Analytics events
import {
  LocalAnalyticsEvent,
  EventAction,
  EventCategory,
} from 'src/contexts/Analytics'
import useAnalytics from 'src/hooks/useAnalytics'

export const useWalkthroughDialog = () => {
  const [walkthroughContent, setWalkthroughContent] = useState<
    Walkthrough | undefined
  >()
  const [isWalkthroughErrorResult, setIsWalkthroughErrorResult] =
    useState(false)
  const analytics = useAnalytics()

  const graphQLClientRequest = useGraphQLClientRequest()

  const {
    selectedProfileId,
    currentRole,
    isClubProfileSelected,
    setCurrentProfileWalkthroughAsSeen,
    isClubProfileWalkthroughRequired,
    isInstructorProfileWalkthroughRequired,
  } = useWalkthroughUtils()

  const shouldShowClubWalkthrough =
    isClubProfileSelected && isClubProfileWalkthroughRequired
  const shouldShowInstructorWalkthroug =
    !isClubProfileSelected && isInstructorProfileWalkthroughRequired

  // Only fetch content if current session need it
  const shouldFetchWalkthroughContent =
    (isClubProfileSelected && shouldShowClubWalkthrough) ||
    (!isClubProfileSelected && shouldShowInstructorWalkthroug)

  // Query Walkthrough
  const {
    data: walkthroughResult,
    isLoading,
    isSuccess,
    isError,
  } = useQuery<GetWalkthroughContentQuery | undefined>(
    'walkthrough-content',
    () =>
      graphQLClientRequest(GetWalkthroughContentDocument, {
        role: { id: selectedProfileId, roleType: currentRole },
      }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: shouldFetchWalkthroughContent,
    }
  )

  // Mutation Walkthrough
  const { mutateAsync: updateWalkthroughToSeen } = useMutation(
    (variables: SetWalkthroughToSeenMutationVariables) =>
      graphQLClientRequest<SetWalkthroughToSeenMutation>(
        SetWalkthroughToSeenDocument,
        variables
      )
  )

  // only mutate after getting walkthrough content successfully
  const shouldSetProfileWalkthroughToSeen =
    isSuccess && !isErrorResult(walkthroughResult?.getWalkthrough)

  const setProfileWalkthroughToSeen = useCallback(async () => {
    try {
      await updateWalkthroughToSeen({
        role: {
          id: selectedProfileId,
          roleType: currentRole,
        },
      })
    } catch (err) {
      console.error(err)
    }
  }, [currentRole, updateWalkthroughToSeen, selectedProfileId])

  const currentEvent = isClubProfileSelected
    ? LocalAnalyticsEvent.ClubWalkthrough
    : LocalAnalyticsEvent.InstructorWalkthrough

  const walkthroughAnalyticsTrigger = ({
    isWelcomePage,
    isDonePage,
    isCloseDialog,
    totalPages,
    currentPage,
  }: {
    isWelcomePage?: boolean
    isDonePage?: boolean
    isCloseDialog?: boolean
    totalPages?: number
    currentPage?: number
  }) => {
    analytics.sendEvent(currentEvent, EventCategory.Button, EventAction.Click, {
      ...(isWelcomePage && { walkthroughWelcomeScreen: 'welcome screen' }),
      ...(isDonePage && {
        walkthroughCompletedScreen: 'completed the entire walkthrough',
      }),
      ...(isCloseDialog && {
        walkthroughCloseDialog: 'close walkthrough dialog',
      }),
      ...(!isWelcomePage &&
        currentPage &&
        currentPage > 0 && {
          screenStep: `screen ${currentPage}/${totalPages && totalPages - 1}`,
        }),
    })
  }

  useEffect(() => {
    if (isSuccess && isErrorResult(walkthroughResult?.getWalkthrough)) {
      setIsWalkthroughErrorResult(true)
    }

    if (isSuccess && walkthroughResult?.getWalkthrough) {
      setWalkthroughContent(walkthroughResult?.getWalkthrough as Walkthrough)
    }
  }, [isSuccess, walkthroughResult])

  useEffect(() => {
    if (shouldSetProfileWalkthroughToSeen) {
      setProfileWalkthroughToSeen()
    }
  }, [setProfileWalkthroughToSeen, shouldSetProfileWalkthroughToSeen])

  const shouldRenderWalkthroughDialog =
    !isLoading &&
    !isError &&
    !isWalkthroughErrorResult &&
    (shouldShowClubWalkthrough || shouldShowInstructorWalkthroug)

  return {
    walkthroughContent,
    walkthroughAnalyticsTrigger,
    setCurrentProfileWalkthroughAsSeen,
    shouldRenderWalkthroughDialog,
  }
}
