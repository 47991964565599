import { PropsWithChildren, useEffect, useMemo } from 'react'
import { Location } from 'history'
import { Redirect } from 'src/adaptor/routerV5'
import { matchPath, RouteComponentProps } from 'react-router-dom'

import {
  isSupportedProfileType,
  userHasProfileAvailable,
  filterProfilesByTypename,
  selectedProfileMatchParamProfile,
} from 'src/utils/history'
import useProfiles, {
  userProfileTypenameToUserProfileType,
  userProfileTypeToUserProfileTypename,
} from 'src/hooks/useProfiles'
import App from 'src/modules/App'
import useProfile from 'src/hooks/useProfile'
import { ProfileLinkType, UserProfileType } from 'src/generated/graphql'
import AppSkeleton from 'src/components/pageSkeletons/App'
import ProfileSelection from 'src/modules/ProfileSelection'
import useUser from 'src/hooks/useUser'
import useAnalytics from 'src/hooks/useAnalytics'
import { isClubProfile, isTeachingProfile } from 'src/utils/typeGuards'
import { ModalPaths } from 'src/routes'
import { useCookiebotConfig } from 'src/hooks/useCookiebotConfig'
import { AgreementGuard } from 'src/components/guards/agreementGuard'
import WalkthroughDialogGuard from 'src/components/guards/WalkthroughDialogGuard'
import PageNotFound from 'src/components/PageNotFound'
import { DefaultLanguage } from 'src/contexts/I18n'

const isDirectUrlToProfilePreview = (profilePathParam: string) =>
  // profilePathParam = 'profile' when user navigates to profile preview via url.
  profilePathParam.toLowerCase() === 'profile'

const Profile = ({
  match,
  location,
}: PropsWithChildren<
  RouteComponentProps<
    { profile?: string },
    {},
    { profileId?: string; background: Location }
  >
>) => {
  const {
    params: { profile: profilePathParam },
  } = match

  const {
    data: { profile: selectedProfile },
    operations: { switchProfile },
  } = useProfile()

  const {
    data: { profiles },
  } = useProfiles()

  const {
    data: { user, fullUser },
  } = useUser()

  const selectedProfileType = useMemo(
    () =>
      selectedProfile &&
      userProfileTypenameToUserProfileType(selectedProfile.__typename),
    [selectedProfile]
  )

  const filteredProfiles = useMemo(
    () =>
      profilePathParam && profiles
        ? filterProfilesByTypename(
            profiles,
            userProfileTypeToUserProfileTypename(
              profilePathParam?.toUpperCase() as UserProfileType
            )
          )
        : [],
    [profilePathParam, profiles]
  )

  const selectedProfileTypePathParam = selectedProfileType?.toLocaleLowerCase()

  // the user has only one profile, so we switch to it
  useEffect(() => {
    if (!selectedProfile && profiles?.length === 1) {
      switchProfile(profiles[0].id)
    }
  }, [profiles, selectedProfile, switchProfile])

  // the user has only one profile after filtering by profile type (instructor or club), so we switch to it
  useEffect(() => {
    if (!selectedProfile && filteredProfiles.length === 1) {
      switchProfile(filteredProfiles[0].id)
    }
  }, [filteredProfiles, selectedProfile, switchProfile])

  const analytics = useAnalytics()

  useEffect(() => {
    if (user && selectedProfile) {
      const commonProperties = {
        profileType: userProfileTypenameToUserProfileType(
          selectedProfile.__typename
        ),
        firstNameFilled: user.firstName.trim() !== '',
        lastNameFilled: user.lastName.trim() !== '',
        emailAddressFilled: user.email.trim() !== '',
        addressFilled:
          (typeof selectedProfile.address.location?.lat === 'number' &&
            typeof selectedProfile.address.location?.lon === 'number' &&
            selectedProfile.address.street !== '') ??
          false,
        personalBioAvailable: selectedProfile.bio != null,
        photosAvailable: !!selectedProfile.media?.length,
      }

      if (isClubProfile(selectedProfile)) {
        analytics.setUserProperties({
          ...commonProperties,
          phoneNumberFilled: selectedProfile.phone?.trim() !== '',
          hiringStatus: selectedProfile.hiring === true,
          livePrograms: selectedProfile.programs
            ?.map(program => program.id)
            .join(', '),
        })
      } else if (isTeachingProfile(selectedProfile)) {
        analytics.setUserProperties({
          ...commonProperties,
          teachingStatus: selectedProfile.availability ?? undefined,
          qualifiedPrograms: selectedProfile.certifications
            ?.map(program => program.id)
            .join(', '),
          qualificationsAndInterestsAvailable:
            selectedProfile.certifications != null &&
            selectedProfile.interests != null,
          socialLinks: selectedProfile.links?.map(link => link.type).join(', '),
          websiteAvailable:
            selectedProfile.links?.some(
              link => link.type === ProfileLinkType.PersonalWebsite
            ) ?? false,
          availableToSubstitute:
            selectedProfile?.availableToSubstitute ?? false,
        })
      }
    }
  }, [user, selectedProfile, analytics])

  // Class for styling components by market
  useEffect(() => {
    // Remove any previous class
    document.body.className = ''
    // ClassList cannot be empty so we assign a default class
    document.body.classList.add(user?.market.locale || DefaultLanguage)
  }, [user?.market.locale])

  useCookiebotConfig({ hideBanner: false })

  if (!profiles) {
    if (user) {
      console.warn('No profiles for the current user!')
    }
    return <AppSkeleton />
  }

  if (!profilePathParam) {
    if (selectedProfileType) {
      return <Redirect to={`/${selectedProfileTypePathParam}`} />
    }
    return <ProfileSelection />
  }

  if (!isSupportedProfileType(profilePathParam)) {
    const profilePreviewMatch = matchPath<{
      profileType: string
      id: string
    }>(location.pathname, `/${ModalPaths.ProfilePreview}`)

    if (selectedProfileType) {
      if (profilePreviewMatch) {
        const previousLocation = location.state?.background || {
          pathname: `/${selectedProfileTypePathParam}`,
        }
        return (
          <Redirect
            to={{
              ...location,
              state: { background: previousLocation },
            }}
          />
        )
      }

      // if user directly navigates to profile preview, show app skeleton as parent
      if (isDirectUrlToProfilePreview(profilePathParam)) return <AppSkeleton />

      return <PageNotFound />
    }
    return (
      <ProfileSelection
        filter={
          profilePreviewMatch
            ? profilePreviewMatch.params.profileType.toUpperCase() ===
              UserProfileType.Instructor
              ? UserProfileType.Club
              : UserProfileType.Instructor
            : undefined
        }
      />
    )
  }

  if (profiles && !userHasProfileAvailable(profilePathParam, profiles)) {
    if (selectedProfileType) {
      return <Redirect to={`/${selectedProfileTypePathParam}`} />
    }
  }

  if (
    !selectedProfileMatchParamProfile(profilePathParam, selectedProfileType)
  ) {
    return (
      <ProfileSelection
        filter={profilePathParam.toUpperCase() as UserProfileType}
      />
    )
  }

  return (
    <AgreementGuard fullUser={fullUser} profile={selectedProfile}>
      <WalkthroughDialogGuard>
        <App />
      </WalkthroughDialogGuard>
    </AgreementGuard>
  )
}

export default Profile
