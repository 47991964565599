import React, { PropsWithChildren } from 'react'

import { WalkthroughPage } from 'src/generated/graphql-react-query'

import { useWalkthroughDialog } from 'src/hooks/dialog/useWalkthroughDialog'
import WalkthroughDialog from 'src/components/dialog/WalkthroughDialog'

const WalkthroughDialogGuard: React.FC<PropsWithChildren> = ({ children }) => {
  const {
    walkthroughContent,
    walkthroughAnalyticsTrigger,
    shouldRenderWalkthroughDialog,
    setCurrentProfileWalkthroughAsSeen,
  } = useWalkthroughDialog()

  return (
    <>
      {children}

      {walkthroughContent && shouldRenderWalkthroughDialog && (
        <WalkthroughDialog
          contentPages={walkthroughContent?.pages as WalkthroughPage[]}
          totalContentPages={walkthroughContent?.pages.length || 0}
          analytics={walkthroughAnalyticsTrigger}
          onClose={setCurrentProfileWalkthroughAsSeen}
        />
      )}
    </>
  )
}

export default WalkthroughDialogGuard
