import useAppState from '../useAppState'
import { Flags } from './types'

export function useFeatureFlag(flag: Flags) {
  const {
    state: { featureFlags },
  } = useAppState()

  if (!featureFlags) {
    return false
  }

  return featureFlags?.[flag]
}
