import styled, { css } from 'styled-components'

import { Typography } from '@material-ui/core'

const SubTitle = styled(Typography)(
  ({ theme }) => css`
    line-height: 160%;
    font-style: normal;
    font-weight: normal;
    letter-spacing: -0.01em;
    padding-top: ${theme.spacing(1)}px;
    color: ${theme.palette.text.secondary};
    font-size: ${theme.typography.pxToRem(14)};
    font-family: ${theme.typography.fontFamily};
  `
)

export default SubTitle
