import React, { useMemo } from 'react'

import EllipseBase, {
  EllipseBaseProps,
} from 'src/components/feedback/ellipse/EllipseBase'
import getStatusColor from 'src/utils/getStatusColor'

export enum Status {
  Positive = 'positive',
  Neutral = 'neutral',
  Negative = 'negative',
}

export interface AvailabilityDotProps
  extends Omit<EllipseBaseProps, 'size' | 'color'> {
  value: Status
  size?: string | number
}

const StatusDot: React.FC<AvailabilityDotProps> = ({
  value,
  size = 6,
  ...props
}) => {
  const color = useMemo(() => getStatusColor(value), [value])
  return <EllipseBase {...props} color={color} size={size} />
}

export default StatusDot
