import React from 'react'
import styled, { css } from 'styled-components'
import { Fade, SvgIconProps } from '@material-ui/core'
import { Box } from 'src/adaptor/materialUI'

import { MenuItemIcon } from 'src/generated/graphql'

import {
  HomeIconActive,
  HomeIconInactive,
  MoreIconActive,
  MoreIconInactive,
  GoalsIconActive,
  GoalsIconInactive,
  PlansIconActive,
  PlansIconInactive,
  ProfileIconActive,
  ProfileIconInactive,
  ReleasesIconActive,
  ReleasesIconInactive,
  TrainerIconActive,
  TrainerIconInactive,
  ManageMyPartnershipIconActive,
  ManageMyPartnershipIconInactive,
  AssessorIconActive,
  AssessorIconInactive,
} from 'src/components/icons/navigation'

const sizeValueToCss = (size: number | string) =>
  typeof size === 'string' ? size : `${size}px`

const Container = styled(Box)<{ $active?: boolean }>(
  ({ theme, width, height, $active }) => css`
    cursor: pointer;
    position: relative;
    background-color: ${$active
      ? theme.palette.background.dark
      : theme.palette.brand.black};
    > svg {
      top: 0;
      left: 0;
      width: ${sizeValueToCss(width)};
      height: ${sizeValueToCss(height)};
      position: absolute;
    }
  `
)

interface MenuIconProps extends SvgIconProps {
  size?: number
  active?: boolean
  type: MenuItemIcon
}
const MenuIcon: React.FC<MenuIconProps> = ({
  type,
  active,
  size = 24,
  ...props
}) => {
  let ActiveIcon:
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    | React.FC
  let InactiveIcon:
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    | React.FC
  switch (type) {
    case MenuItemIcon.More:
      ActiveIcon = MoreIconActive
      InactiveIcon = MoreIconInactive
      break
    case MenuItemIcon.Goals:
      ActiveIcon = GoalsIconActive
      InactiveIcon = GoalsIconInactive
      break
    case MenuItemIcon.Plans:
      ActiveIcon = PlansIconActive
      InactiveIcon = PlansIconInactive
      break
    case MenuItemIcon.Profile:
      ActiveIcon = ProfileIconActive
      InactiveIcon = ProfileIconInactive
      break
    case MenuItemIcon.Releases:
      ActiveIcon = ReleasesIconActive
      InactiveIcon = ReleasesIconInactive
      break
    case MenuItemIcon.Trainer:
      ActiveIcon = TrainerIconActive
      InactiveIcon = TrainerIconInactive
      break
    case MenuItemIcon.ManageMyPartnership:
      ActiveIcon = ManageMyPartnershipIconActive
      InactiveIcon = ManageMyPartnershipIconInactive
      break
    case MenuItemIcon.Assessor:
      ActiveIcon = AssessorIconActive
      InactiveIcon = AssessorIconInactive
      break
    case MenuItemIcon.Home:
    default:
      ActiveIcon = HomeIconActive
      InactiveIcon = HomeIconInactive
      break
  }
  return (
    <Container width={size} height={size} $active={active}>
      <Fade in={active}>
        <ActiveIcon {...props} />
      </Fade>
      <Fade in={!active}>
        <InactiveIcon {...props} />
      </Fade>
    </Container>
  )
}

export default MenuIcon
