import React from 'react'
import { useHistory } from 'react-router-dom'
import useMediaQueries from 'src/hooks/useMediaQueries'
import NavBar from 'src/components/navigation/NavBar'
import NavBarSmall from 'src/components/navigation/NavBarSmall'
import useNavigation from 'src/hooks/useNavigation'
import { EventCategory, EventAction } from 'src/contexts/Analytics'
import useAnalytics from 'src/hooks/useAnalytics'
import { AnalyticsProps } from 'src/components/navigation/types'

const Navigation: React.FC = () => {
  const {
    data: { appMenu, loading, error },
  } = useNavigation()

  const history = useHistory()
  const analytics = useAnalytics()

  const { isSmallScreen: useSmallNavBar } = useMediaQueries()
  // TODO: Show error toast message once we have designs

  const handleAnalytics = ({
    item,
    parentMenuItem,
    isProfileMenu,
  }: AnalyticsProps) => {
    if (item.analyticsEvent != null) {
      analytics.sendEvent(
        item.analyticsEvent,
        EventCategory.Button,
        EventAction.Click,
        {
          destinationUrl: item.url?.value,
          originUrl: history.location.pathname,
        }
      )
    }

    analytics.pushToDatalayer({
      event: 'nav_select',
      form_name: isProfileMenu ? 'Profile Menu' : 'Primary Menu',
      menu_category_1:
        item.__typename === 'MenuItem' ? parentMenuItem?.label : item.label,
      menu_category_2: item.__typename === 'MenuItem' ? item.label : undefined,
    })
  }

  if (error) {
    return null
  }

  return (
    <>
      <NavBar
        data={appMenu}
        loading={loading}
        disabled={useSmallNavBar}
        data-qa-id="DesktopNavBar"
        trackAnalytics={handleAnalytics}
      />
      <NavBarSmall
        data={appMenu}
        loading={loading}
        disabled={!useSmallNavBar}
        data-qa-id="DeviceNavBar"
        trackAnalytics={handleAnalytics}
      />
    </>
  )
}

export default Navigation
