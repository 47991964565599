import React from 'react'
import { Trans } from 'react-i18next'

import { UserProfileType } from 'src/generated/graphql'
import ProfileSelectionComponent from 'src/components/profileSelection/ProfileSelection'
import useUser from 'src/hooks/useUser'
import FullScreenBackdropDialog from 'src/components/layout/FullScreenBackdropDialog'

export interface ProfileSelectionProps {
  filter?: UserProfileType
}

const ProfileSelection: React.FC<ProfileSelectionProps> = ({ filter }) => {
  const {
    data: { user },
  } = useUser()

  return (
    <FullScreenBackdropDialog>
      <ProfileSelectionComponent
        title={
          <Trans
            i18nKey={'ProfileSelection.hello'}
            values={{ name: user?.firstName }}
            components={[<br />]}
          />
        }
        filter={filter}
      />
    </FullScreenBackdropDialog>
  )
}

export default ProfileSelection
