import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'

// control the visibility of the components in the layout, e.g. the scroll to top button
interface ComponentOnLayoutVisibilityContextValue {
  hideScrollBtn: boolean
  setHideScrollBtn: (hide: boolean) => void
}

const ComponentOnLayoutVisibilityContext =
  createContext<ComponentOnLayoutVisibilityContextValue>({
    hideScrollBtn: false,
    setHideScrollBtn: () => {},
  })

export const useComponentOnLayoutVisibility = () =>
  useContext(ComponentOnLayoutVisibilityContext)

export const ComponentOnLayoutVisibilityProvider: React.FC<PropsWithChildren> =
  ({ children }) => {
    const [hideOnSpecificPage, setHideOnSpecificPage] = useState(false)

    return (
      <ComponentOnLayoutVisibilityContext.Provider
        value={{
          hideScrollBtn: hideOnSpecificPage,
          setHideScrollBtn: setHideOnSpecificPage,
        }}
      >
        {children}
      </ComponentOnLayoutVisibilityContext.Provider>
    )
  }
