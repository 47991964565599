import React from 'react'

import { SvgTypes } from './SVG.types'

// Make sure new icons added to the project are inside
// assets/images/icons/SVGComponentIcons if you want to use them here

// should move these over to /assets/images/icons/SVGComponentIcons at some point
import { ReactComponent as EncircledInfo } from 'src/assets/images/icons/encircledInfo.svg'
import { ReactComponent as BarbellIcon } from 'src/assets/images/icons/barbell.svg'
import { ReactComponent as Warning } from 'src/assets/images/icons/warnicon.svg'
import { ReactComponent as X } from 'src/assets/images/icons/x.svg'
import { ReactComponent as Goldtick } from 'src/assets/images/icons/goldentick.svg'
import { ReactComponent as Add } from 'src/assets/images/icons/encircledAdd.svg'
import { ReactComponent as Spinner } from 'src/assets/images/icons/spinner.svg'
import { ReactComponent as Link } from 'src/assets/images/icons/externalLinkTemplate.svg'
// --------------------------------------------------------------------------------

import { ReactComponent as LinkCopy } from 'src/assets/images/icons/SVGComponentIcons/LinkCopy.svg'
import { ReactComponent as Flag } from 'src/assets/images/icons/SVGComponentIcons/Flag.svg'
import { ReactComponent as EncircledTick } from 'src/assets/images/icons/SVGComponentIcons/EncircledTick.svg'
import { ReactComponent as Calendar } from 'src/assets/images/icons/SVGComponentIcons/Calendar.svg'
import { ReactComponent as CycleArrows } from 'src/assets/images/icons/SVGComponentIcons/CycleArrows.svg'
import { ReactComponent as SubstituteArrows } from 'src/assets/images/icons/SVGComponentIcons/SubstituteArrows.svg'
import { ReactComponent as Award } from 'src/assets/images/icons/SVGComponentIcons/Award.svg'
import { ReactComponent as Eye } from 'src/assets/images/icons/SVGComponentIcons/Eye.svg'

export enum SvgEnum {
  EncircledInfo = 'ENCIRCLED_INFO',
  BarbellBlack = 'BARBELL_BLACK',
  Warning = 'WARNING',
  X = 'X',
  GoldTick = 'GoldTick',
  Add = 'Add',
  LinkCopy = 'LinkCopy',
  Link = 'Link',
  Spinner = 'Spinner',
  Flag = 'Flag',
  EncircledTick = 'EncircledTick',
  Calendar = 'Calendar',
  CycleArrows = 'CycleArrows',
  SubstituteArrows = 'SubstituteArrows',
  Award = 'Award',
  Eye = 'Eye',
}

export const svgList: {
  [k in SvgTypes]: React.FC<React.SVGProps<SVGSVGElement>>
} = {
  // Link Type Icons
  [SvgEnum.EncircledInfo]: EncircledInfo,
  [SvgEnum.BarbellBlack]: BarbellIcon,
  [SvgEnum.Warning]: Warning,
  [SvgEnum.X]: X,
  [SvgEnum.GoldTick]: Goldtick,
  [SvgEnum.Add]: Add,
  [SvgEnum.LinkCopy]: LinkCopy,
  [SvgEnum.Link]: Link,
  [SvgEnum.Spinner]: Spinner,
  [SvgEnum.Flag]: Flag,
  [SvgEnum.EncircledTick]: EncircledTick,
  [SvgEnum.Calendar]: Calendar,
  [SvgEnum.CycleArrows]: CycleArrows,
  [SvgEnum.SubstituteArrows]: SubstituteArrows,
  [SvgEnum.Award]: Award,
  [SvgEnum.Eye]: Eye,
}
