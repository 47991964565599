import {
  useContext,
  createContext,
  PropsWithChildren,
  useState,
  useEffect,
} from 'react'
import useInitApplication from 'src/hooks/useInitApplication'
import {
  FullUserFieldFragment,
  Locale,
  UserProfileType,
} from 'src/generated/graphql-react-query'
import { isErrorResult, isUserResult } from 'src/utils/typeGuards'
import { filterProfilesByProfileType } from 'src/utils/helpers'

import { UserProfile } from 'src/hooks/useProfile'
import axios from 'axios'
import { Flags } from '../hooks/featureFlag/types'
import { useGetUserLevelQuery } from 'src/generated/hooks'

type UserResult = FullUserFieldFragment | null
type TranslationsResult = {
  locale: Locale
  result: Record<string, any>
} | null

export type InitData = {
  user: UserResult
  translations: TranslationsResult
  updateUser: (user: Partial<UserResult>) => void
  hasUserSeenOnboardingModal: boolean // TODO: remove this values when LCS-333 is ready
  setHasUserSeenOnboardingModal: (value: boolean) => void // TODO: remove this values when LCS-333 is ready
  isChainUser?: boolean
  isInstructorLead: boolean
  userLevel?: string
}

export const InitDataContext = createContext<InitData | null>(null)

export const InitDataProvider = ({ children }: PropsWithChildren<{}>) => {
  const fetchTranslations = (
    locale: Locale,
    onFetch: (translationResult: TranslationsResult) => void,
    onError: () => void
  ) => {
    const translationBaseUrl = import.meta.env.REACT_APP_TRANSLATION_BASE_URL
    const translationUrl = `${translationBaseUrl}/${locale
      .replace('_', '-')
      .toLowerCase()}.json`

    axios
      .get(translationUrl)
      .then(response => {
        const data = response.data
        onFetch({
          locale: locale,
          result: JSON.parse(JSON.stringify(data)),
        })
      })
      .catch(() => {
        onError()
      }) // TODO: Error handling like Datadog event
  }

  const { data } = useInitApplication()
  const { data: userLevelData } = useGetUserLevelQuery()

  const [user, setUser] = useState<UserResult>(null)
  const [isChainUser, setIsChainUser] = useState<boolean | undefined>(undefined)
  const [translations, setTranslations] = useState<TranslationsResult>(null)
  const [hasUserSeenOnboardingModal, setHasUserSeenOnboardingModal] =
    useState<boolean>(true) // TODO: get this value when LCS-333 is ready
  const [isInstructorLead, setIsInstructorLead] = useState<boolean>(false)
  const [userLevel, setUserLevel] = useState<string>()

  useEffect(() => {
    if (
      userLevelData?.getCurrentUser &&
      isUserResult(userLevelData?.getCurrentUser)
    ) {
      setIsInstructorLead(
        userLevelData?.getCurrentUser.user.isInstructorLead ?? false
      )
      setUserLevel(userLevelData?.getCurrentUser.user.recordType ?? undefined)
    }

    if (data && !isErrorResult(data?.getUser)) {
      const clubProfiles = filterProfilesByProfileType(
        data?.getUser?.profiles as UserProfile[],
        UserProfileType.Club
      )
      setUser(data.getUser ?? null)
      setIsChainUser(clubProfiles.length > 1)

      const featureFlagRes = data.getFeatureFlag
      fetchTranslations(
        data?.getUser?.user.market.locale ?? Locale.EnGb,
        setTranslations,
        () => {} // TODO: Error handling like Datadog event
      )
      // Todo: This will need refactoring since we're moving to
      // program config. Logic may need to move to ProgramsProvider.
      // as long as ProgramProvider is consuming InitData (via useUser), it should be fine.
      if (
        !isErrorResult(featureFlagRes) &&
        featureFlagRes?.flags?.find(
          flag => flag.featureFlag === Flags.lesMillsDance && !flag.enabled
        )
      ) {
        if (data.getUser?.user.market.programs) {
          data.getUser.user.market.programs =
            data.getUser?.user.market.programs?.filter(
              program => program.code !== 'DN'
            )
        }
        setUser(data.getUser ?? null)
      }
    }
  }, [data, userLevelData])

  const updateUser = (data: Partial<UserResult>) => {
    setUser(user ? { ...user, ...data } : user)
  }

  const value: InitData = {
    user,
    translations,
    updateUser,
    hasUserSeenOnboardingModal, // TODO: remove this values when LCS-333 is ready
    setHasUserSeenOnboardingModal, // TODO: remove this values when LCS-333 is ready
    isChainUser,
    isInstructorLead,
    userLevel,
  }

  return (
    <InitDataContext.Provider value={value}>
      {children}
    </InitDataContext.Provider>
  )
}

export const useInitData = () => {
  const context = useContext(InitDataContext)
  if (context === undefined) {
    throw new Error(`useInitData must be used within a InitDataProvider`)
  }
  return context
}
