import { useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import {
  UpdateClubProfileInput,
  UpdateTeachingProfileInput,
  ClubProfileFieldsFragment,
  InstructorProfileFieldsFragment,
  Maybe,
  ClubProgram,
} from 'src/generated/graphql'
import {
  useUpdateClubProfileMutation,
  useUpdateTeachingProfileMutation,
} from 'src/generated/hooks'
import useProfiles, {
  userProfileTypenameToUserProfileType,
} from 'src/hooks/useProfiles'
import { profileContext } from 'src/contexts/Profile'
import { isClubProfile, isTeachingProfile } from 'src/utils/typeGuards'

import useUser from 'src/hooks/useUser'
import validateAddress from 'src/utils/validateAddress'
import { filterNonLiveProgramsFromProfile } from 'src/utils/helpers'

export type ClubProfileWithPrograms = Omit<
  ClubProfileFieldsFragment,
  'programs'
> & {
  programs?: Maybe<
    Array<
      { __typename: 'ClubProgram' } & Pick<
        ClubProgram,
        'id' | 'name' | 'code' | 'initials' | 'programType'
      >
    >
  >
}

export type ClubProfile = ClubProfileWithPrograms
export type InstructorProfile = InstructorProfileFieldsFragment
export type UserProfile = ClubProfile | InstructorProfile

const useProfile = () => {
  const {
    data: { fullUser, isChainUser },
    operations: { updateUser },
  } = useUser()

  const history = useHistory()

  const { setSelectedProfileId, selectedProfileId } = useContext(profileContext)

  const {
    data: { profiles },
  } = useProfiles()

  const [
    updateInstructorProfile,
    {
      error: instructorPorfileUpdateError,
      loading: instructorProfileUpdateInProgress,
    },
  ] = useUpdateTeachingProfileMutation()

  const [
    updateClubProfile,
    { error: clubPorfileUpdateError, loading: clubProfileUpdateInProgress },
  ] = useUpdateClubProfileMutation()

  const profile = useMemo(
    (): UserProfile | undefined =>
      profiles?.find(profile => profile.id === selectedProfileId),
    [profiles, selectedProfileId]
  )

  const switchProfile = (nextProfileId: string) => {
    const nextProfile = profiles?.find(profile => profile.id === nextProfileId)
    if (!nextProfile) {
      return false
    }
    setSelectedProfileId(nextProfileId)
    return nextProfile
  }

  const switchProfileAndGoTo = (profileId: string, pathname: string) => {
    if (switchProfile(profileId)) {
      history.push(pathname)
    }
  }

  // @ToDo: make this useable for components with cache update
  const update = (
    profileInput: UpdateTeachingProfileInput | UpdateClubProfileInput
  ) => {
    if (!profile) {
      return
    }
    return isClubProfile(profile)
      ? updateClubProfile({
          variables: {
            input: {
              ...profileInput,
              id: profile.id,
            },
          },
        })
      : updateInstructorProfile({
          variables: {
            input: profileInput,
          },
        })
  }

  const isUpdating = useMemo(
    () =>
      profile &&
      (isTeachingProfile(profile)
        ? instructorProfileUpdateInProgress
        : clubProfileUpdateInProgress),
    [clubProfileUpdateInProgress, instructorProfileUpdateInProgress, profile]
  )
  const updateError = useMemo(
    () =>
      profile &&
      (isTeachingProfile(profile)
        ? instructorPorfileUpdateError
        : clubPorfileUpdateError),
    [clubPorfileUpdateError, instructorPorfileUpdateError, profile]
  )

  // TODO change to profile.type when available
  const profileType = useMemo(
    () =>
      userProfileTypenameToUserProfileType(profile?.__typename! as string) ??
      undefined,
    [profile?.__typename]
  )

  function updateActiveProfile<T extends ClubProfile | InstructorProfile>(
    updatedProfile: Partial<T>
  ): void {
    updateUser({
      ...fullUser!,
      profiles: fullUser!.profiles.map(profile => {
        if (profile.id === selectedProfileId) {
          return {
            ...profile,
            ...updatedProfile,
          }
        }
        return profile
      }),
    })
  }

  function updateAnotherProfile<T extends ClubProfile | InstructorProfile>(
    updatedProfile: Partial<T>
  ): void {
    updateUser({
      ...fullUser!,
      profiles: fullUser!.profiles.map(profile => {
        if (profile.id === updatedProfile.id) {
          return {
            ...profile,
            ...updatedProfile,
          }
        }
        return profile
      }),
    })
  }

  function updateMultipleProfiles<T extends ClubProfile | InstructorProfile>(
    requestedProfileChanges: Partial<T>[]
  ): void {
    const updatedProfiles = fullUser!.profiles.map(profile => {
      const index = requestedProfileChanges.findIndex(
        requestedProfile => requestedProfile.id === profile.id
      )
      if (index > -1) {
        return {
          ...profile,
          ...requestedProfileChanges[index],
        }
      }
      return profile
    })
    updateUser({
      ...fullUser!,
      profiles: updatedProfiles,
    })
  }

  const addressIsValid = useMemo(() => {
    const address =
      profile &&
      (isClubProfile(profile)
        ? profile.address
        : profile.preferences?.travel?.address)
    return !!address?.location && validateAddress(address)
  }, [profile])

  // Profile with non-MMP programs
  const filteredClubProfile = useMemo(() => {
    return profile ? filterNonLiveProgramsFromProfile(profile) : undefined
  }, [profile])

  return {
    data: {
      profile,
      isUpdating,
      updateError,
      profileType,
      addressIsValid,
      selectedProfileId,
      filteredClubProfile,
      isChainUser,
    },
    operations: {
      update,
      switchProfile,
      switchProfileAndGoTo,
      updateActiveProfile,
      updateAnotherProfile,
      updateMultipleProfiles,
    },
  }
}

export default useProfile
