import { useLocation, useRouteMatch, matchPath } from 'react-router-dom'
import { ModalPaths } from 'src/routes'
import { localizeRoutePath } from 'src/utils/i18n'

const useIsSupportedPath = (paths?: string[]) => {
  const { pathname } = useLocation()
  const match = useRouteMatch()
  const windowPathname = window.location.pathname

  if (
    windowPathname.includes(ModalPaths.SwitchProfile) ||
    windowPathname.includes('/profile/instructor') ||
    windowPathname.includes('/profile/club') || // Unable to use ModalPaths.ProfilePreview as it is '/profile/:profileType/:id'
    windowPathname.includes('/enquire/') ||
    windowPathname.includes('/manage/')
  ) {
    return true
  }

  if (!paths) {
    const { url } = match

    return pathname === url
  }

  return paths.find(path => {
    const localizedPath = localizeRoutePath(path)

    return matchPath(windowPathname, {
      exact: true,
      path: localizedPath as string,
    })
  })
}

export default useIsSupportedPath
