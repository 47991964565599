import React, { PropsWithChildren, useEffect } from 'react'
import * as History from 'history'
import { QueryParamProvider } from 'use-query-params'
import { useHistory, useLocation } from 'react-router-dom'
import { BrowserRouter, Route, Switch } from 'src/adaptor/routerV5'

import Logout from 'src/pages/logout'
import { modalPaths } from 'src/routes'
import ScrollToTop from 'src/utils/ScrollToTop'
import useAnalytics from 'src/hooks/useAnalytics'
import PageNotFound from 'src/components/PageNotFound'
import useAppState from 'src/hooks/useAppState'
import UnsupportedMarket from 'src/pages/unsupported-market'
import Profile from 'src/modules/RouterComponents/Profile'
import { useLanguageParam } from 'src/contexts/LanguageParamProvider'
import AppSkeleton from 'src/components/pageSkeletons/App'
import I18nProvider from 'src/contexts/I18n'

const AnalyticsListener: React.FC<PropsWithChildren> = ({ children }) => {
  const analytics = useAnalytics()
  const history = useHistory()

  useEffect(() => {
    if (!history || !analytics) {
      return
    }

    return history.listen(location => {
      const { pathname, search, state } = location as History.Location<{
        shouldTriggerPageView?: boolean
      }>

      // By default, shouldTriggerPageView is undefined, therefore we assume we want to trigger a page view event
      const shouldTriggerPageView = state?.shouldTriggerPageView ?? true
      const currentPath = pathname + search

      shouldTriggerPageView && analytics.sendPageView(currentPath)
    })
  }, [analytics, history])

  return <>{children}</>
}

const AppSwitch: React.FC = () => {
  const location = useLocation<{ background: History.Location }>()
  const background = location?.state?.background
  return (
    <>
      <Switch location={background || location}>
        <Route path="/logout" exact component={Logout} />
        <Route path="/:profile?" component={Profile} />
        <Route component={PageNotFound} />
      </Switch>
      <Switch>{modalPaths}</Switch>
    </>
  )
}

const AppRouter: React.FC = () => {
  const {
    state: { isUnsupportedMarket },
  } = useAppState()
  const { value } = useLanguageParam()
  const { state } = useAppState()

  if (!(state.initialDataLoaded && state.i18nLoaded)) {
    return <AppSkeleton />
  }

  if (isUnsupportedMarket) {
    return (
      <I18nProvider>
        <UnsupportedMarket />
      </I18nProvider>
    )
  }

  return (
    <BrowserRouter basename={`/${value || ''}`}>
      <AnalyticsListener>
        <ScrollToTop />
        <QueryParamProvider ReactRouterRoute={Route}>
          <AppSwitch />
        </QueryParamProvider>
      </AnalyticsListener>
    </BrowserRouter>
  )
}

export default AppRouter
