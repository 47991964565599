import React from 'react'
import styled from 'styled-components'
import i18n from 'i18next'

import { Box } from 'src/adaptor/materialUI'
import Skeleton from '@material-ui/lab/Skeleton'

import useMediaQueries from 'src/hooks/useMediaQueries'
import LoadingSpinner from 'src/assets/images/gifs/loadingSpinnerLmi.gif'

const ImageFrame = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: -96px;
`

const AppSkeleton: React.FC = () => {
  const { isSmallScreen } = useMediaQueries()

  return (
    <Box height="100%" width="100%" display="flex" overflow="hidden">
      {!isSmallScreen && (
        <Box width={96} height="100%">
          <Skeleton variant="rect" width="100%" height="100%" />
        </Box>
      )}
      <Box flex={1} height="100%" width="100%" maxWidth="100%" overflow="auto">
        <Box
          position="sticky"
          top={0}
          width="100%"
          height={96}
          display="flex"
          alignItems="center"
        >
          <Box ml={2} display="flex" flexDirection="column">
            <Skeleton variant="text" width={133} height={45} />
          </Box>
          <Box
            display="flex"
            flex={1}
            justifyContent="flex-end"
            alignItems="center"
            pr={6}
          >
            <Skeleton variant="circle" width={26} height={26} />
          </Box>
        </Box>
        <ImageFrame>
          <img src={LoadingSpinner} alt={i18n.t('Global.loading')} />
        </ImageFrame>
      </Box>
    </Box>
  )
}

export default AppSkeleton
