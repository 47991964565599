import React, { PropsWithChildren } from 'react'
import { Grid } from '@material-ui/core'
import { Box } from 'src/adaptor/materialUI'
import styled from 'styled-components'

import welcome from 'src/assets/images/background-account-signup.jpg'
import { Illustration, LogoIcon } from 'src/components/dataDisplay/Illustration'

const FullScreenContainer = styled(Grid)`
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-image: url(${welcome});
`

const StyledLogo = styled(Illustration)`
  position: absolute;
  left: ${({ theme }) => theme.spacing(8)}px;
  top: ${({ theme }) => theme.spacing(11)}px;
`

export type FullScreenBackdropDialogProps = PropsWithChildren<{}>

const FullScreenBackdropDialog = ({
  children,
}: FullScreenBackdropDialogProps) => (
  <FullScreenContainer container>
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <StyledLogo type={LogoIcon.LesMillsWhite} />
      {children}
    </Box>
  </FullScreenContainer>
)

export default FullScreenBackdropDialog
