import { Location } from 'history'
import { UserProfileType } from 'src/generated/graphql'
import {
  profileTypeValues,
  UserProfileTypeName,
  userProfileTypenameToUserProfileType,
} from 'src/hooks/useProfiles'
import { UserProfile } from 'src/hooks/useProfile'

export const locationToUrl = ({
  pathname,
  search,
  hash,
}: Location<unknown>): string => {
  let path = pathname
  if (path.endsWith('/')) {
    path = path.slice(0, -1)
  }
  const url = `${path}${search}${hash}`
  return url
}

export const filterProfilesByTypename = (
  profiles?: UserProfile[],
  filter?: UserProfileTypeName | null
) => profiles?.filter(profile => profile.__typename === filter) ?? []

export const isSupportedProfileType = (profile?: string) =>
  profileTypeValues.includes(profile?.toUpperCase() as UserProfileType)

export const userHasProfileAvailable = (
  profile: string,
  profiles: UserProfile[] | undefined
) =>
  profiles?.findIndex(
    p =>
      userProfileTypenameToUserProfileType(p.__typename).toLowerCase() ===
      profile.toLowerCase()
  ) !== -1

export const selectedProfileMatchParamProfile = (
  profile: string,
  userProfile?: string
) => {
  return profile.toUpperCase() === userProfile?.toUpperCase()
}
