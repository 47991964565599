import React from 'react'
import { BoxProps } from '@material-ui/core'

import {
  NotificationItemFragment as Notification,
  NotificationType,
} from 'src/generated/graphql-react-query'
import Illustration, {
  IconTypes,
  NotificationIcon,
} from 'src/components/dataDisplay/Illustration'

interface NotificationTypeIconProps extends BoxProps {
  item: Notification
}
const NotificationTypeIcon: React.FC<NotificationTypeIconProps> = ({
  item,
  ...props
}) => {
  const { type } = item
  let iconType: IconTypes | undefined
  switch (type) {
    case NotificationType.Plans:
      iconType = NotificationIcon.Plans
      break
    case NotificationType.Releases:
      iconType = NotificationIcon.Release
      break
    case NotificationType.Positions:
      iconType = NotificationIcon.Position
      break
  }
  return iconType ? (
    <Illustration
      width={15}
      height={15}
      display="inline-block"
      type={iconType}
      {...props}
    />
  ) : null
}

export default NotificationTypeIcon
