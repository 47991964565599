import React from 'react'

import { SvgIcon, SvgIconProps } from '@material-ui/core'

const HeartFilled: React.FC<SvgIconProps> = React.memo(props => (
  <SvgIcon fill="none" viewBox="0 0 24 24" {...props}>
    <path
      d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3z"
      fill="#A79966"
    />
  </SvgIcon>
))

export default HeartFilled
