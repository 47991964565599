import React from 'react'
import styled, { css } from 'styled-components'

import {
  Fab,
  Slide,
  Divider,
  useTheme,
  IconButton,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  useMediaQuery,
  IconButtonProps,
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogProps as MuiDialogProps,
  Grid,
} from '@material-ui/core'
import { Box } from 'src/adaptor/materialUI'
import { Close } from '@material-ui/icons'
import { TransitionProps } from '@material-ui/core/transitions/transition'
import Title from 'src/components/dialog/Title'
import Button from 'src/components/button/Button'
import SubTitle from 'src/components/dialog/SubTitle'
import { allowNewline } from 'src/utils/helpers'

import Illustration, {
  LinkTypeIcon,
} from 'src/components/dataDisplay/Illustration'
import Link from 'src/components/navigation/Link'

import { LinkInput, UrlType } from 'src/generated/graphql'

export const DialogTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const CloseIcon = styled(Close)(
  ({ theme }) => css`
    color: ${theme.palette.brand.black};
  `
)

const StyledDivider = styled(Divider)(
  ({ theme }) => css`
    margin: ${theme.spacing(3, 0)};
  `
)

const DialogActions = styled(MuiDialogActions)(
  ({ theme }) => css`
    padding: ${theme.spacing(0, 0)};
    margin-bottom: 0px;
  `
)

const LinkContainer = styled(Box)(
  ({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `
)

export const MobileDialog = styled(MuiDialog)(
  ({ theme, fullScreen }) => css`
    ${fullScreen &&
    css`
      .MuiDialog-container {
        padding-top: ${theme.spacing(7)}px;
      }
      .MuiDialog-paperFullScreen {
        border-radius: 20px 20px 0 0;
      }
    `}
  `
)

export const CloseIconButton = styled(IconButton)(
  ({ theme }) => css`
    top: 30px;
    right: 30px;
    position: absolute;
    padding: 0;
    width: ${theme.typography.pxToRem(14)};
    height: ${theme.typography.pxToRem(14)};
    border: none;
  `
)

export const MobileCloseIconButton = styled(Fab)(
  ({ theme }) => css`
    width: 80px;
    height: 80px;
    bottom: 20px;
    position: fixed;
    left: calc(50% - 40px);
    background-color: ${theme.palette.brand.offWhite};
  `
)

const StyledButton = styled(Button)(
  ({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
      padding: ${theme.spacing(1.75, 1.125)};
    }
  `
)

export const CloseButton: React.FC<IconButtonProps> = props => (
  <CloseIconButton {...props}>
    <CloseIcon />
  </CloseIconButton>
)

export const MobileCloseButton: React.FC<IconButtonProps> = props => (
  <MobileCloseIconButton {...props}>
    <CloseIcon />
  </MobileCloseIconButton>
)

export const DialogTitle = styled(MuiDialogTitle)<{
  $disablePadding?: boolean
}>(
  ({ theme, $disablePadding }) => css`
    padding: ${theme.spacing($disablePadding ? 0 : 3, 0, 0, 0)};
  `
)

export interface DialogAction {
  actionDisabled?: boolean
  label: string
  primary?: boolean
  link?: LinkInput
  testid?: string
  handler: () => void
}

export interface DialogProps extends Omit<MuiDialogProps, 'title'> {
  title?: React.ReactNode
  subTitle?: React.ReactNode
  disabled?: boolean
  actions?: DialogAction[]
  loading?: boolean
  disableCloseButton?: boolean
  fullScreenOnlyOnMobile?: boolean
  fullSizeOnTablet?: boolean
  noFullScreen?: boolean
  removePaddingXs?: boolean
}

export const DialogModal = styled(Box)<{
  $largePaddingOnTablet?: boolean
  $removePaddingXs: boolean
}>(
  ({ theme, $largePaddingOnTablet, $removePaddingXs }) => css`
    ${theme.breakpoints.up('sm')} {
      padding: ${`${theme.spacing(5)}px`};
    }
    ${theme.breakpoints.down('sm')} {
      padding: ${$largePaddingOnTablet
        ? `${theme.spacing(5)}px`
        : theme.spacing(2, 3)};
    }
    ${$removePaddingXs &&
    css`
      ${theme.breakpoints.down('xs')} {
        padding: 0;
      }
    `}
  `
)

const DialogContent = styled(MuiDialogContent)(
  () => css`
    padding: 0px;
    overflow-y: inherit;
  `
)

const Dialog: React.FC<DialogProps> = ({
  title,
  actions,
  disabled,
  subTitle,
  children,
  loading,
  disableCloseButton,
  fullScreenOnlyOnMobile = false,
  fullSizeOnTablet = false,
  noFullScreen = false,
  removePaddingXs = false,
  ...props
}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(
    theme.breakpoints.down(fullScreenOnlyOnMobile ? 'xs' : 'sm')
  )
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'))
  const isSingleAction = actions?.length === 1
  const isLastAction = (index: number) => actions?.length === index + 1

  return (
    <MobileDialog
      TransitionComponent={DialogTransition}
      fullScreen={noFullScreen ? false : fullScreen}
      scroll="body"
      fullWidth={isTablet}
      maxWidth={fullSizeOnTablet ? 'md' : 'sm'}
      {...props}
    >
      <DialogModal
        mb={fullScreen && !noFullScreen ? 12.5 : 0}
        display="flex"
        flexDirection="column"
        $largePaddingOnTablet={fullSizeOnTablet}
        $removePaddingXs={removePaddingXs}
      >
        {!disableCloseButton && !fullScreen && (
          <CloseButton
            data-testid="dialog-close-btn"
            disabled={loading}
            onClick={event => props.onClose?.(event, 'backdropClick')}
          />
        )}
        {(title || subTitle) && (
          <>
            <DialogTitle $disablePadding={!disableCloseButton && !fullScreen}>
              {title && <Title>{title}</Title>}
              {subTitle && <SubTitle {...allowNewline}>{subTitle}</SubTitle>}
            </DialogTitle>
            <StyledDivider />
          </>
        )}
        {!noFullScreen ? (
          <DialogContent>{children}</DialogContent>
        ) : (
          <div>{children}</div>
        )}
        {actions?.length && (
          <DialogActions>
            <Grid container spacing={2}>
              {actions?.map(
                (
                  { label, handler, link, primary, actionDisabled, testid },
                  index
                ) => {
                  return link ? (
                    <Grid
                      item
                      container
                      justify={
                        isSingleAction || isLastAction(index)
                          ? 'flex-end'
                          : 'flex-start'
                      }
                      sm={actions.length === 1 ? 12 : 6}
                      xs={actions.length === 1 ? 12 : 7}
                      key={index}
                    >
                      <LinkContainer>
                        <Link url={link.url} onClick={handler}>
                          <StyledButton
                            data-testid={testid}
                            variant="contained"
                            color={primary ? 'primary' : 'secondary'}
                            disabled={actionDisabled}
                            endIcon={
                              link.url.type === UrlType.External && (
                                <Illustration
                                  type={LinkTypeIcon.ExternalLink}
                                />
                              )
                            }
                          >
                            {link.text}
                          </StyledButton>
                        </Link>
                      </LinkContainer>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      container
                      justify={
                        isSingleAction || isLastAction(index)
                          ? 'flex-end'
                          : 'flex-start'
                      }
                      sm={actions.length === 1 ? 12 : 6}
                      xs={actions.length === 1 ? 12 : 5}
                      key={`${label}-${index}`}
                    >
                      <StyledButton
                        variant="contained"
                        data-testid={testid}
                        onClick={handler}
                        color={primary ? 'primary' : 'secondary'}
                        disabled={disabled || actionDisabled || loading}
                        {...(primary && { loading: loading })}
                      >
                        {label}
                      </StyledButton>
                    </Grid>
                  )
                }
              )}
            </Grid>
          </DialogActions>
        )}
      </DialogModal>
      {fullScreen && !disableCloseButton && (
        <MobileCloseButton
          data-testid="dialog-close-btn"
          disabled={loading}
          onClick={event => props.onClose?.(event, 'backdropClick')}
        />
      )}
    </MobileDialog>
  )
}

export default Dialog
