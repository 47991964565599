import styled from 'styled-components'

import { Grid } from '@material-ui/core'

import { Box } from 'src/adaptor/materialUI'
import Skeleton from '@material-ui/lab/Skeleton'
import useMediaQueries from 'src/hooks/useMediaQueries'

const Container = styled(Box)`
  width: 100%;
`

const FullGrid = styled(Grid)`
  width: 100%;
  height: 100%;
`

const InstructorDashboard = () => {
  const { isSmallScreen } = useMediaQueries()

  return (
    <>
      <Box>
        <Box>
          <Skeleton variant="text" width={372} height={42} />
        </Box>
        <Container height={isSmallScreen ? 146 : 375}>
          <FullGrid container spacing={1}>
            <Grid item xs={4}>
              <Skeleton variant="rect" width="100%" height="100%" />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="rect" width="100%" height="100%" />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="rect" width="100%" height="100%" />
            </Grid>
          </FullGrid>
        </Container>
        <Box mt={isSmallScreen ? 7 : 23} height={isSmallScreen ? 890 : 445}>
          <FullGrid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Box mb={5} width="80%" height={42}>
                <Skeleton variant="text" width="100%" height="100%" />
              </Box>
              <Skeleton variant="rect" width="100%" height="100%" />
            </Grid>
            <Grid item xs={12} md={8}>
              <Box mb={5} width="80%" height={42}>
                <Skeleton variant="text" width="80%" height={42} />
              </Box>
              <Skeleton variant="rect" width="100%" height="100%" />
            </Grid>
          </FullGrid>
        </Box>
        <Box mt={23}>
          <Box>
            <Skeleton variant="text" width={372} height={42} />
          </Box>
          <Box
            height={298}
            width="100%"
            display="flex"
            flexWrap="nowrap"
            overflow="auto hidden"
          >
            <Box minWidth={298} height="100%" mr={1} display="inline-block">
              <Skeleton variant="rect" width="100%" height="100%" />
            </Box>
            <Box minWidth={298} height="100%" mr={1} display="inline-block">
              <Skeleton variant="rect" width="100%" height="100%" />
            </Box>
            <Box minWidth={298} height="100%" mr={1} display="inline-block">
              <Skeleton variant="rect" width="100%" height="100%" />
            </Box>
            <Box minWidth={298} height="100%" mr={1} display="inline-block">
              <Skeleton variant="rect" width="100%" height="100%" />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default InstructorDashboard
