import { ProgramConfigDic } from 'src/contexts/ProgramProvider'

// Only typing values we need for adapting
export type ProgramConfig = {
  id: string
  programCategory: Maybe<ProgramTypeEnum>
  marketVisibility: MarketVisibility[]
  bannerImage: {}
  displayName: string
  programShortCode: string
} & { [key: string]: any }

export type LiveProgramConfig = ProgramConfig & {
  bannerImage: {
    name: string
    url: string
  }
  programCategory: ProgramTypeEnum.Live
}

export type MarketVisibility = {
  market: string
  visibility: boolean
}

export type ProgramImage = {
  src: string
  alt: string
  width?: number
  height?: number
}

type DeserializedData = Record<string, string[]>

export type Program = {
  __typename?: 'Program'
  code: Scalars['String']
  displayName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  initials: Scalars['String']
  name: Scalars['String']
  programType?: Maybe<ProgramTypeEnum>
}

export type ClubProgram = {
  __typename?: 'ClubProgram'
  code: Scalars['String']
  displayName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  initials: Scalars['String']
  name: Scalars['String']
  programType?: Maybe<ProgramTypeEnum>
  // Remove instructorCount, status from this type as currently not used
  // Will add back in when needed
}

export enum ProgramTypeEnum {
  Live = 'LIVE',
  Virtual = 'VIRTUAL',
  AtHome = 'AT_HOME',
}

// GraphQL types to match shape of generated schema
// Todo: remove this when Program is removed from GraphQL schema
type Scalars = {
  ID: string
  String: string
}
export type Maybe<T> = T | null

/**
 * Deserialize the data by market for quick access.
 *
 * @param data - Array of program data.
 * @returns - A map of market to program IDs.
 */
export function deserializeByMarket(data: {
  [key: string]: ProgramConfig
}): DeserializedData {
  const marketMap: DeserializedData = {}

  for (const key in data) {
    const program = data[key]
    for (const marketItem of program.marketVisibility) {
      if (marketItem.visibility) {
        if (!marketMap[marketItem.market]) {
          marketMap[marketItem.market] = []
        }
        marketMap[marketItem.market].push(program.id)
      }
    }
  }

  return marketMap
}

/**
 * Get programs by market.
 *
 * @param market - The market to retrieve programs for.
 * @param data - The program data.
 * @returns - An array of programs for a given market.
 */
export function getProgramsByMarket(
  market: string,
  data: ProgramConfigDic
): any {
  const marketData = deserializeByMarket(data)
  const programs: Program[] = []

  if (marketData[market]) {
    for (const programId of marketData[market]) {
      const programData = data[programId]
      if (programData) {
        const { id, programShortCode, displayName, programCategory } =
          programData

        const program: Program = {
          __typename: 'Program',
          code: programShortCode,
          displayName,
          id: `program-${programShortCode}`,
          initials: programShortCode,
          name: id,
          programType: programCategory,
        }

        programs.push(program)
      }
    }
  }

  // Display name will always be present
  // Only optional on type to conform to previous schema
  return programs.sort((a, b) => a.displayName!.localeCompare(b.displayName!))
}

/**
 * Return a single club program by programId
 *
 * @param programId - program string - 'BODYCOMBAT'
 * @param data program config data
 *
 * @returns - A single club program
 */
export const getSingleProgramById = (
  programId: string,
  programs: { [key: string]: ProgramConfig } | null
): ClubProgram | undefined => {
  // to cater for strength development
  programId = programId.replace(/_/g, ' ')
  const programData = programs?.[programId]
  if (programData) {
    const { id, programShortCode, displayName, programCategory } = programData

    return {
      __typename: 'ClubProgram',
      code: programShortCode,
      displayName,
      id: `program-${programShortCode}`,
      initials: programShortCode,
      name: id,
      programType: programCategory,
    } as ClubProgram
  }
  return undefined
}

/**
 * Enrich club programs by config and exposes as ClubProgram Type
 *
 * @param clubPrograms programs string array
 * @param data program config data
 */
export const enrichClubProgramsByConfig = (
  clubPrograms: string[],
  data: ProgramConfigDic
): ClubProgram[] => {
  const enrichPrograms = clubPrograms
    .map(programId => enrichClubProgramByConfig(programId, data))
    .filter(item => item && item.programType !== 'VIRTUAL') as ClubProgram[]

  // Display name will always be present
  // Only optional on type to conform to previous schema
  enrichPrograms.sort((a, b) => a.displayName!.localeCompare(b.displayName!))

  return enrichPrograms || []
}

/**
 * Enrich club programs by config and exposes as ClubProgramWithLogo Type
 *
 * @param clubPrograms programs string array
 * @param data program config data
 */

export type ClubProgramWithLogo = ClubProgram & { logoImage: ProgramImage }

export const enrichClubProgramsWithLogoByConfig = (
  clubPrograms: string[],
  data: { [key: string]: ProgramConfig }
): ClubProgramWithLogo[] => {
  const enrichPrograms = clubPrograms
    .map(programId => {
      const programData = data[programId]
      if (programData) {
        const {
          id,
          programShortCode,
          displayName,
          programCategory,
          logoImage,
        } = programData
        return {
          __typename: 'ClubProgram',
          code: programShortCode,
          displayName,
          id: `program-${programShortCode}`,
          initials: programShortCode,
          name: id,
          programType: programCategory,
          logoImage: { src: logoImage.url, alt: displayName },
        } as ClubProgramWithLogo
      }
      return undefined
    })
    .filter(
      item => item && item.programType !== 'VIRTUAL'
    ) as ClubProgramWithLogo[]

  // Display name will always be present
  // Only optional on type to conform to previous schema
  enrichPrograms.sort((a, b) => a.displayName!.localeCompare(b.displayName!))

  return enrichPrograms || []
}

/**
 * @param clubProgram program Id string array, eg: 'BODYCOMBAT'
 * @param data program config data
 */
export const enrichClubProgramByConfig = (
  clubProgramId: string,
  data: { [key: string]: ProgramConfig }
): ClubProgram | undefined => {
  const programData = data[clubProgramId]
  if (programData) {
    const { id, programShortCode, displayName, programCategory } = programData

    return {
      __typename: 'ClubProgram',
      code: programShortCode,
      displayName,
      id: `program-${programShortCode}`,
      initials: programShortCode,
      name: id,
      programType: programCategory,
    } as ClubProgram
  }
  return undefined
}

/**
 * Get program image from program config by programId.
 */
export const getProgramLogoImageByProgramId = (
  programConfig: Record<string, any> | null,
  programId: string
): ProgramImage | null => {
  if (
    programConfig &&
    programConfig[programId] &&
    programConfig[programId].logoImage &&
    programConfig[programId].logoImage.url
  ) {
    return {
      src: programConfig[programId].logoImage.url,
      alt: programConfig[programId].logoImage.alt || programId,
      ...programConfig[programId].logoImage.dimensions,
    }
  }
  return null
}

export type CategorizedPrograms = {
  LIVE: Record<string, ProgramConfig>
  VIRTUAL: Record<string, ProgramConfig>
}

/**
 * Transform program config to short code map categorized by program category.
 * @param programConfig - Object of programs with ID as keys
 * @returns Object categorized by 'LIVE' or 'VIRTUAL' with programConfigs using short code as keys
 */
export const programsToCategoryShortCodeMap = (
  programConfig?: Record<string, ProgramConfig> | null
): CategorizedPrograms => {
  if (!programConfig) {
    return { LIVE: {}, VIRTUAL: {} }
  }

  return Object.values(programConfig).reduce(
    (acc, program) => {
      acc[program.programCategory as 'LIVE' | 'VIRTUAL'][
        program.programShortCode
      ] = program
      return acc
    },
    { LIVE: {}, VIRTUAL: {} } as CategorizedPrograms
  )
}
