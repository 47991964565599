import React from 'react'
import styled, { css } from 'styled-components'

import {
  Typography,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  MenuProps,
} from '@material-ui/core'
import { Box } from 'src/adaptor/materialUI'

export const MenuItem = styled(MuiMenuItem)(
  ({ theme }) => css`
    align-items: flex-start;
    flex-direction: column;
    padding: ${theme.spacing(3, 3, 3, 3)};
  `
)

export const StyledMuiMenu = styled(MuiMenu)(
  ({ theme }) => css`
    margin-top: ${theme.spacing(1.5)}px;
    .MuiMenu-paper {
      border: 1px solid ${theme.palette.brand.gray};
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    }
    .MuiMenu-list {
      padding: 0;
    }
    ${MenuItem} {
      border-bottom: 1px solid ${theme.palette.brand.gray};
      :last-child {
        border-bottom: 0 none;
      }
    }
  `
)

const Title = styled(Box)(
  ({ theme }) => css`
    display: flex;
    font-weight: 400;
    align-items: center;
    white-space: normal;
    padding-bottom: ${theme.spacing(1)}px;
    font-size: ${theme.typography.pxToRem(14)};
  `
)

const Description = styled(Typography)(
  ({ theme }) => css`
    font-weight: 400;
    white-space: normal;
    color: ${theme.palette.brand.darkGray};
    font-size: ${theme.typography.pxToRem(12)};
  `
)

export interface MenuAction {
  id?: string
  title: string
  description: string
  handler: (event: React.MouseEvent, action: MenuAction) => void
}

interface GenericMenuProps extends MenuProps {
  actions: MenuAction[]
}
const Menu: React.FC<GenericMenuProps> = ({ actions, ...props }) => {
  return (
    <StyledMuiMenu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      getContentAnchorEl={null}
      {...props}
    >
      {actions.map((item, index) => {
        const { id, title: label, description, handler } = item
        return (
          <MenuItem
            key={id || `${label}-${index}`}
            onClick={event => {
              handler(event, item)
            }}
          >
            <Title>{label}</Title>
            {description && <Description>{description}</Description>}
          </MenuItem>
        )
      })}
    </StyledMuiMenu>
  )
}

export default Menu
