import { useEffect, useCallback } from 'react'

interface CookiebotConfigProps {
  hideBanner?: boolean
}

export const useCookiebotConfig = ({ hideBanner }: CookiebotConfigProps) => {
  const delayInSeconds = 0.75

  const runCookieBotConfig = useCallback(() => {
    const cookieBotContent = document.getElementById(
      'CybotCookiebotDialog'
    ) as HTMLElement

    !!cookieBotContent &&
      cookieBotContent?.setAttribute(
        'style',
        `display: ${hideBanner ? 'none' : 'flex'};`
      )
  }, [hideBanner])

  useEffect(() => {
    const timer = setTimeout(() => runCookieBotConfig(), delayInSeconds * 1000)
    return () => clearTimeout(timer)
  }, [delayInSeconds, hideBanner, runCookieBotConfig])
}
