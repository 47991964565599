import { useContext } from 'react'

import { InitDataContext } from 'src/contexts/InitData'

const useUser = () => {
  const {
    user: userResult,
    updateUser,
    isChainUser,
    isInstructorLead,
    userLevel,
  } = useContext(InitDataContext)!
  const user = userResult?.user ?? null

  return {
    data: {
      id: userResult?.id ?? null,
      settings: userResult?.settings ?? null,
      fullUser: userResult,
      user,
      isChainUser,
      isInstructorLead,
      userLevel,
    },
    operations: {
      updateUser,
    },
  }
}

export default useUser
