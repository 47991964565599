import { Typography } from '@material-ui/core'
import { Box } from 'src/adaptor/materialUI'
import { useTranslation } from 'react-i18next'
import {
  ActionButton,
  AgreementContent,
  StyledButton,
  StyledCard,
  BaseCardContent,
  StyledDivider,
} from 'src/components/Agreement/Agreement.styles'
import FullScreenBackdropDialog from 'src/components/layout/FullScreenBackdropDialog'
import useLogout from 'src/hooks/useLogout'

type ClubPrivacyAgreementErrorProps = {
  retry: () => void
  isRetrying: boolean
}

const ClubPrivacyAgreementError = ({
  retry,
  isRetrying,
}: ClubPrivacyAgreementErrorProps) => {
  const { t } = useTranslation()
  const { logout } = useLogout()

  return (
    <FullScreenBackdropDialog>
      <StyledCard raised>
        <BaseCardContent>
          <Typography variant="h6">
            {t('ClubPrivacyAgreement.title')}
          </Typography>
          <StyledDivider />
          <AgreementContent>
            <Typography variant="body1">
              {t('ClubPrivacyAgreement.fetchErrorText')}
            </Typography>
          </AgreementContent>
          <Box
            mt={8}
            mb={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <StyledButton
              variant="outlined"
              color="primary"
              onClick={logout}
              disabled={isRetrying}
            >
              {t('ClubPrivacyAgreement.signOutLabel')}
            </StyledButton>
            <ActionButton
              variant="contained"
              color="primary"
              onClick={retry}
              loading={isRetrying}
            >
              {t('ClubPrivacyAgreement.retryLabel')}
            </ActionButton>
          </Box>
        </BaseCardContent>
      </StyledCard>
    </FullScreenBackdropDialog>
  )
}

export default ClubPrivacyAgreementError
