import styled, { css } from 'styled-components'

import { Divider as MuiDivider } from '@material-ui/core'

const Divider = styled(MuiDivider)<{
  m?: number
  mt?: number
  mb?: number
  my?: number
}>(
  ({ theme, m, mt, mb, my }) => css`
    ${m !== undefined ? `margin: ${theme.spacing(m)}px;` : ''}
    ${my !== undefined ? `margin-top: ${theme.spacing(my)}px;` : ''}
    ${my !== undefined ? `margin-bottom: ${theme.spacing(my)}px;` : ''}
    ${mt !== undefined ? `margin-top: ${theme.spacing(mt)}px;` : ''}
    ${mb !== undefined ? `margin-bottom: ${theme.spacing(mb)}px;` : ''}
  `
)

export default Divider
