import React from 'react'

import Button, { ButtonProps } from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

interface Props extends ButtonProps {
  loading: boolean
}

const LoaderButton: React.FC<Props> = ({
  size,
  loading,
  children,
  disabled,
  ...props
}) => (
  <Button {...props} size={size} disabled={disabled || loading}>
    {loading ? (
      <CircularProgress size={size === 'large' ? 20 : 15} color="inherit" />
    ) : (
      children
    )}
  </Button>
)

export default React.memo(LoaderButton)
