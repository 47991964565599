import React, { useMemo, useState } from 'react'
import styled, { css } from 'styled-components'

import { Popper, Grow, Drawer, PopperProps } from '@material-ui/core'

import useUser from 'src/hooks/useUser'
import useProfile from 'src/hooks/useProfile'
import { NavMenu } from 'src/components/navigation/NavMenu'
import { NavBarProps } from 'src/components/navigation/types'
import { MenuItem as MenuItemType } from 'src/generated/graphql'

import { SubMenuContainer } from 'src/components/navigation/common'
import { SubMenu } from 'src/components/navigation/SubMenu'
import { Profile } from 'src/components/navigation/ProfileSection'
import useNavigation from 'src/hooks/useNavigation'

const NavContainer = styled(Drawer)(
  ({ theme }) => css`
    height: 100%;
    .MuiPaper-root {
      display: flex;
      padding: ${theme.spacing(3, 0, 0, 0)};

      align-items: center;
      flex-direction: column;
      width: 80px;
      background-color: ${theme.palette.brand.black};
      justify-content: space-between;

      transition: width 1s;
    }
    .MuiDrawer-paper {
      border: 0 none;
      position: relative;
    }
  `
)

const LargeDesktopSubMenuContainer = styled(SubMenuContainer)(
  ({ theme }) => css`
    background-color: ${theme.palette.brand.black};
    border-radius: 0px 5px 5px 0px;
  `
)

const NavBar: React.FC<NavBarProps> = ({
  data,
  disabled,
  loading,
  trackAnalytics,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<PopperProps['anchorEl'] | null>(null)
  const [activeMenuItem, setActiveMenuItem] = useState<string | undefined>()
  const subMenuData = useMemo(
    () => data?.find(item => item.id === activeMenuItem),
    [activeMenuItem, data]
  )
  const hasSubMenuEntries = !!subMenuData?.items
  const {
    data: { user },
  } = useUser()
  const {
    data: { profile },
  } = useProfile()
  const {
    data: { userMenu },
  } = useNavigation()
  const isProfile = activeMenuItem === userMenu?.id

  const activeSubMenuData = isProfile ? userMenu : subMenuData

  const handleHoverLeave = () => {
    setAnchorEl(null)
    setActiveMenuItem('')
  }

  const handleHover = (item: MenuItemType, event: React.MouseEvent) => {
    setActiveMenuItem(item.id)
    setAnchorEl(event?.currentTarget)
  }

  const handleProfileHover = (event: React.MouseEvent) => {
    setAnchorEl(event?.currentTarget)
    setActiveMenuItem(userMenu?.id)
  }

  return (
    <NavContainer
      onMouseLeave={handleHoverLeave}
      variant="persistent"
      {...props}
      open={!disabled}
    >
      <NavMenu
        data={data}
        onHover={handleHover}
        onSelect={trackAnalytics}
        activeMenuItem={activeMenuItem}
        disableTitle
        loading={loading}
      />
      {profile && user && (
        <Profile
          user={user}
          profile={profile}
          loading={loading}
          isActive={isProfile}
          onHover={handleProfileHover}
          onSelect={trackAnalytics}
        />
      )}
      {activeSubMenuData && (
        <Popper
          transition
          open={!!anchorEl}
          anchorEl={anchorEl}
          placement={isProfile ? 'right-end' : 'right-start'}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={150}>
              <LargeDesktopSubMenuContainer
                component="ul"
                pt={isProfile ? 2 : 0}
                pb={hasSubMenuEntries && !isProfile ? 2 : 0}
              >
                <SubMenu
                  data={activeSubMenuData}
                  isProfileMenu={isProfile}
                  onSelect={trackAnalytics}
                />
              </LargeDesktopSubMenuContainer>
            </Grow>
          )}
        </Popper>
      )}
    </NavContainer>
  )
}

export default NavBar
