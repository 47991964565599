import { Address } from 'src/generated/graphql'

export default function validateAddress({ location }: Address): boolean {
  // if location is null or undefined => invalid
  if (!location) {
    return false
  }
  const { lat, lon } = location
  // if either lat or lon null or undefined => invalid
  if (lat === null || lat === undefined || lon === null || lon === undefined) {
    return false
  }
  // if any of them not 0 => valid
  return lat !== 0 || lon !== 0
}
