import { useState } from 'react'
import { ToastTypeEnum } from 'src/components/feedback/Toast'

export interface ToastProps {
  message: string
  variant: ToastTypeEnum
  testId?: string
}

interface ToastInterface {
  hideToast: () => void
  showToast: (args: ToastProps) => void
  isToastOpen: boolean
  toastState: ToastProps | undefined
}

export const useToast = (): ToastInterface => {
  const [isToastOpen, setIsToastOpen] = useState(false)
  const [toastState, setToastState] = useState<ToastProps>()
  const showToast = (args: ToastProps) => {
    setToastState(args)
    setIsToastOpen(true)
  }

  const hideToast = () => {
    setToastState(undefined)
    setIsToastOpen(false)
  }

  return {
    showToast,
    hideToast,
    toastState,
    isToastOpen,
  }
}

export default useToast
