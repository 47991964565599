import React from 'react'
import { ThemeProvider } from 'styled-components'
import { Auth0Provider, AppState } from '@auth0/auth0-react'
import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles'
import {
  ThemeProvider as MUIv5ThemeProvider,
  THEME_ID,
} from '@mui/material/styles'
import { CssBaseline } from '@material-ui/core'
import { ReactQueryDevtools } from 'react-query/devtools'
import { HelmetProvider } from 'react-helmet-async'

import Cookie from 'js-cookie'
import theme from 'src/styles/theme'
import MUItheme from 'src/styles/themeMUIv5'
import AppRouter from 'src/AppRouter'
import I18nProvider from 'src/contexts/I18n'
import GlobalStyles from 'src/styles/GlobalStyles'
import { ProfileProvider } from 'src/contexts/Profile'
import { AppStateProvider } from 'src/contexts/AppState'
import { InitDataProvider } from 'src/contexts/InitData'
import { AnalyticsProvider } from 'src/contexts/Analytics'
import Security from 'src/modules/RouterComponents/Security'
import ApiClientProvider from 'src/contexts/ApiClientProvider'
import LanguageParamProvider from 'src/contexts/LanguageParamProvider'
import { ComponentOnLayoutVisibilityProvider } from 'src/contexts/ComponentOnLayoutVisibilityProvider'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { ProgramProvider } from './contexts/ProgramProvider'

import { PROFILE_ID_KEY } from './contexts/constants'

const Providers: React.FC = () => {
  const handleAuth0RedirectCallback = (appState: AppState) => {
    // remove selectedProfileId in the cookie when user logs in, import 'js-cookie' and use Cookies.remove('profileId')
    Cookie.remove(PROFILE_ID_KEY)
    if (appState.returnTo) {
      window.history.pushState(null, document.title, appState.returnTo)
    }
  }

  return (
    <Auth0Provider
      domain={import.meta.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={import.meta.env.REACT_APP_AUTH0_CLIENT_ID!}
      redirectUri={window.location.origin}
      onRedirectCallback={handleAuth0RedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens
    >
      <LanguageParamProvider>
        <AppStateProvider>
          <ApiClientProvider>
            <MuiThemeProvider theme={theme}>
              {/* @ts-ignore */}
              <ThemeProvider theme={theme}>
                <StylesProvider injectFirst>
                  <MUIv5ThemeProvider theme={{ [THEME_ID]: MUItheme }}>
                    {/* @ts-ignore */}
                    <GlobalStyles />
                    <CssBaseline />
                    <Security>
                      <InitDataProvider>
                        <ProgramProvider>
                          <HelmetProvider>
                            <I18nProvider>
                              <AnalyticsProvider>
                                <ProfileProvider>
                                  <ComponentOnLayoutVisibilityProvider>
                                    <MuiPickersUtilsProvider
                                      utils={MomentUtils}
                                    >
                                      <AppRouter />
                                    </MuiPickersUtilsProvider>
                                  </ComponentOnLayoutVisibilityProvider>
                                </ProfileProvider>
                              </AnalyticsProvider>
                            </I18nProvider>
                          </HelmetProvider>
                        </ProgramProvider>
                      </InitDataProvider>
                    </Security>
                  </MUIv5ThemeProvider>
                </StylesProvider>
              </ThemeProvider>
            </MuiThemeProvider>
            <ReactQueryDevtools position="bottom-right" />
          </ApiClientProvider>
        </AppStateProvider>
      </LanguageParamProvider>
    </Auth0Provider>
  )
}

export default Providers
