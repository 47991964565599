import React from 'react'
import { SvgCaptionProp } from './Caption.type'
import { CaptionBox, StyledWarningContent } from './Captions.styles'
import styled from 'styled-components'
import { SVG } from '../SVG/SVG'

const SVGWrapper = styled.div`
  width: fit-content;
  display: flex;
`
export const SvgCaption: React.FC<SvgCaptionProp> = ({
  textColor,
  icon,
  isShrink,
  isDark,
  isOneLine,
  backgroundColor,
  element,
}) => (
  <CaptionBox
    $isShrink={isShrink}
    $isDark={isDark}
    $isOneLine={isOneLine}
    $color={backgroundColor}
    $noMargin
  >
    {icon && (
      <SVGWrapper>
        <SVG {...icon} />
      </SVGWrapper>
    )}
    <StyledWarningContent $color={textColor}>{element}</StyledWarningContent>
  </CaptionBox>
)
