import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Typography } from '@material-ui/core'
import { Box } from 'src/adaptor/materialUI'
import { useAuth0, IdToken } from '@auth0/auth0-react'

import useUser from 'src/hooks/useUser'
import useToast from 'src/hooks/useToast'
import { isErrorResult } from 'src/utils/typeGuards'
import { useRevertAppMetadataMutation } from 'src/generated/hooks'
import Toast, { ToastTypeEnum } from 'src/components/feedback/Toast'
import LoaderButton from 'src/components/inputs/LoaderButton'

const Banner = styled(Box)(
  ({ theme }) => css`
    z-index: 1;
    flex: 0 0 50px;
    background-color: ${theme.palette.warning.xlight};
    display: flex;
    align-items: center;
    justify-content: center;
  `
)

const LogoutButton = styled(LoaderButton)(
  ({ theme }) => css`
    min-width: 80px;
    height: 32px;
    margin-left: ${theme.spacing(2)}px;
  `
)

const AdminBanner = () => {
  const { getIdTokenClaims, logout } = useAuth0()
  const [idToken, setIdToken] = useState<IdToken | null>(null)
  const [isLoggingOut, setIsLoggingOut] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    const getIdToken = async () => {
      setIdToken(await getIdTokenClaims())
    }
    getIdToken()
  }, [getIdTokenClaims])

  const {
    data: { user },
  } = useUser()
  const { showToast, isToastOpen, hideToast, toastState } = useToast()

  const [revertAppMetadata] = useRevertAppMetadataMutation()

  const isAdmin = /@lesmills.com$/.test(idToken?.email ?? '')
  const isImpersonating = idToken?.email !== user?.email
  const showBanner = isAdmin && isImpersonating

  const onLogoutError = () => {
    setIsLoggingOut(false)
    showToast({
      message: t('AdminBanner.toastErrorMessage'),
      variant: ToastTypeEnum.ERROR,
    })
  }

  const onLogoutClick = async () => {
    setIsLoggingOut(true)
    const response = await revertAppMetadata({
      variables: {
        input: {
          email: idToken!.email!,
        },
      },
    })
    const result = response.data?.revertAppMetadata

    if (isErrorResult(result)) {
      onLogoutError()
    } else if ((result as any)?.isSuccess) {
      logout()
      window.location.href = import.meta.env.REACT_APP_ADMIN_URL!
    } else {
      onLogoutError()
    }
  }

  if (!showBanner) {
    return null
  }

  return (
    <>
      <Banner boxShadow={2}>
        <Typography variant="body1">
          {t('AdminBanner.title', { email: user?.email })}
        </Typography>
        <LogoutButton
          onClick={onLogoutClick}
          variant="contained"
          color="primary"
          loading={isLoggingOut}
        >
          {t('AdminBanner.logoutButtonLabel')}
        </LogoutButton>
      </Banner>
      <Toast
        isOpen={isToastOpen}
        handleClose={hideToast}
        message={toastState?.message}
        variant={toastState?.variant}
      />
    </>
  )
}

export default AdminBanner
