import React from 'react'
import { Box } from 'src/adaptor/materialUI'
import { SubMenuItemProps } from 'src/components/navigation/types'
import { isInternalLink } from 'src/utils/navigation'
import { LinkTypeIcon } from 'src/components/dataDisplay/Illustration'
import useMediaQueries from 'src/hooks/useMediaQueries'
import {
  EndAdornment,
  SubMenuItemTitle,
  MenuItemLink,
  StyledSubMenuItem,
} from 'src/components/navigation/common'

export const SubMenuItem: React.FC<SubMenuItemProps> = ({
  isProfileMenu,
  parentItem,
  item,
  onHover,
  onLeave,
  onSelect,
  isActive,
}) => {
  const { label, url } = item
  const { isSmallScreen } = useMediaQueries()

  const subMenuDetails = (
    <Box
      width="100%"
      justifyContent="space-between"
      display="flex"
      alignItems="center"
      mr={isSmallScreen ? 1 : 0}
    >
      <SubMenuItemTitle $isSmall={isSmallScreen}>{label}</SubMenuItemTitle>
      {url ? (
        <EndAdornment
          $isActive={isActive}
          type={
            isInternalLink(url)
              ? LinkTypeIcon.Chevron
              : LinkTypeIcon.ExternalLink
          }
        />
      ) : null}
    </Box>
  )

  const subMenuContent = url ? (
    <MenuItemLink url={url} $isSubMenu>
      {subMenuDetails}
    </MenuItemLink>
  ) : (
    subMenuDetails
  )

  return (
    <StyledSubMenuItem
      disableRipple
      $isLink={!!url?.value}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      onTouchStart={onHover}
      onClick={() =>
        url?.value &&
        onSelect &&
        onSelect({ item, parentMenuItem: parentItem, isProfileMenu })
      }
      data-testid={`SubMenuItem-${item.label}`}
      data-qa-id={label}
    >
      {subMenuContent}
    </StyledSubMenuItem>
  )
}
