import { useMemo, useRef } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { GraphQLClient } from 'graphql-request'
import gql from 'graphql-tag'

import { graphQLClient } from 'src/utils/graphQLClient'
import { SetSessionInvalidAction } from 'src/contexts/AppState'
import useAppState from 'src/hooks/useAppState'
import { isErrorResult } from 'src/utils/typeGuards'
import {
  Error as GQLError,
  ErrorResult,
} from 'src/generated/graphql-react-query'
import { useLanguageParam } from 'src/contexts/LanguageParamProvider'

export const useGraphQLClientRequest = () => {
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0()
  const { dispatch } = useAppState()
  const { acceptLanguageHeaderValue } = useLanguageParam()

  const isRefreshingToken = useRef(false)
  const refreshTokenPromise = useRef<Promise<void>>()

  const refreshAuth = useMemo(
    () => async () => {
      isRefreshingToken.current = true
      refreshTokenPromise.current = getAccessTokenSilently()
        .then(() => {
          isRefreshingToken.current = false
        })
        .catch(err => {
          if (err.error === 'login_required') {
            dispatch(SetSessionInvalidAction())
          }
          return Promise.reject(err)
        })
    },
    [dispatch, getAccessTokenSilently]
  )

  function retryAfterRefresh(retryFn: () => Promise<any>) {
    if (!isRefreshingToken.current) {
      refreshAuth()
    }
    return refreshTokenPromise.current!.then(retryFn)
  }

  async function interceptedRequest<T>(
    ...args: Parameters<GraphQLClient['request']>
  ): Promise<T | undefined> {
    const token = await getIdTokenClaims()

    if (!token) {
      return retryAfterRefresh(() => interceptedRequest(...args))
    }

    graphQLClient.setHeader('Authorization', token?.__raw)
    graphQLClient.setHeader('Accept-Language', acceptLanguageHeaderValue)

    // Add _cacheKey
    const variables = (args[1] ?? {}) as Record<string, any>
    variables._cacheKey = token?.__raw

    const response = await graphQLClient.request(
      gql(args[0] as string),
      variables,
      args[2]
    )
    const data = Object.values(response)
    const errorResult = data.find(item => isErrorResult(item)) as
      | ErrorResult
      | undefined

    if (errorResult) {
      switch (errorResult.type) {
        case GQLError.AuthExpiredToken:
          return retryAfterRefresh(() => interceptedRequest(...args))
        case GQLError.AuthInvalidHeader:
        case GQLError.AuthHeaderMissing:
        case GQLError.AuthInternalError:
          dispatch(SetSessionInvalidAction())
          break
        default:
        // do nothing
      }
    }
    return response
  }

  return interceptedRequest
}
