import { useState, useEffect, useMemo } from 'react'

import { useGetNavigationQuery } from 'src/generated/hooks'
import { RootMenuItem, User } from 'src/generated/graphql'
import useUser from 'src/hooks/useUser'
import useProfile from 'src/hooks/useProfile'
import { userProfileTypenameToUserProfileType } from 'src/hooks/useProfiles'
import { isTeachingProfile } from 'src/utils/typeGuards'

const useNavigation = () => {
  const {
    data: { profile },
  } = useProfile()
  const {
    data: { user },
  } = useUser()

  const { market } = (user as User) || {}

  const profileType = useMemo(
    () => userProfileTypenameToUserProfileType(profile?.__typename),
    [profile]
  )

  const instructorRoles =
    profile && isTeachingProfile(profile) ? profile.roles : undefined

  const { data, loading, refetch } = useGetNavigationQuery({
    variables: {
      input: {
        role: profileType,
        market: market?.name,
        subroles: instructorRoles,
      },
    },
  })
  const [appMenu, setAppMenu] = useState<RootMenuItem[]>()
  const [userMenu, setUserMenu] = useState<RootMenuItem>()
  const [error, setError] = useState<string | undefined | null>()

  useEffect(() => {
    refetch({
      input: {
        role: profileType,
        market: market.name,
        subroles: instructorRoles,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileType])

  useEffect(() => {
    const result = data?.getNavigation
    if (result?.__typename === 'ErrorResult' && !loading) {
      return setError(result?.message)
    }
    if (result?.__typename === 'Navigation') {
      const { appMenu, userMenu } = result
      setAppMenu(appMenu.items)
      setUserMenu(userMenu)
    }
  }, [data, loading])

  return {
    data: {
      appMenu,
      userMenu,
      loading,
      error,
    },
  }
}

export default useNavigation
