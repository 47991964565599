import { IconButton } from '@material-ui/core'
import styled, { css } from 'styled-components'

export const ButtonContainer = styled.div<{
  $isMobileLayout?: boolean
}>(
  ({ $isMobileLayout }) => css`
    flex: 1;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    z-index: 0;
    padding-left: ${$isMobileLayout ? 0 : '80px'};
    scroll-behavior: smooth;
  `
)

export const StyledIconButton = styled(IconButton)<{
  $isVisible?: boolean
  $isMobileLayout?: boolean
}>(
  ({ theme, $isVisible, $isMobileLayout }) => css`
    width: 44px; /* as per designs */
    height: 44px; /* as per designs */
    margin: 0 auto;
    position: fixed;
    bottom: ${$isMobileLayout
      ? `${theme.spacing(1)}px`
      : `${theme.spacing(2)}px`};
    right: ${$isMobileLayout
      ? `${theme.spacing(1)}px`
      : `${theme.spacing(2)}px`};
    background-color: ${theme.palette.brand.white};
    &:hover {
      background-color: ${theme.palette.brand.lightGray};
      border: 1px solid ${theme.palette.brand.gray};
    }
    opacity: ${$isVisible ? '1' : '0'};
    pointer-events: ${$isVisible ? 'visible' : 'none'};
    transition: opacity 0.3s ease-in;
    filter: drop-shadow(
      0px 1.31343px 7.8806px rgba(205, 205, 205, 0.7)
    ); /* as per designs */
    font-size: 30px;
    z-index: 1;
  `
)
