import React from 'react'
import { Card, Typography, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import FullScreenBackdropDialog from 'src/components/layout/FullScreenBackdropDialog'
import useLogout from 'src/hooks/useLogout'

const StyledTypography = styled(Typography)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(4)}px;
  `
)

const StyledCard = styled(Card)(
  ({ theme }) => css`
    width: 100%;
    max-width: 494px;
    padding: ${theme.spacing(8, 5, 5)};
  `
)

const defaultTranslations = {
  title: 'Sorry an error occurred when logging you in.',
  description: `Something has gone wrong and we weren’t able to load your content properly. Please try logging in again or contact our support team at support@lesmills.com so we can get your account back up and running.`,
  login: 'Try logging in again',
}

const InvalidSession = () => {
  const { t, ready } = useTranslation()
  const { logout } = useLogout()

  const retryLogin = () => {
    // calling logout clears the local storage and triggers the login redirection again
    logout()
  }

  return (
    <FullScreenBackdropDialog>
      <StyledCard raised>
        <StyledTypography variant="h6">
          {ready
            ? t('InvalidSession.title', defaultTranslations.title)
            : defaultTranslations.title}
        </StyledTypography>
        <StyledTypography variant="body1">
          {ready
            ? t('InvalidSession.description', defaultTranslations.description)
            : defaultTranslations.description}
        </StyledTypography>
        <Button variant="contained" color="primary" onClick={retryLogin}>
          {ready
            ? t('InvalidSession.login', defaultTranslations.login)
            : defaultTranslations.login}
        </Button>
      </StyledCard>
    </FullScreenBackdropDialog>
  )
}

export default InvalidSession
