import React from 'react'
import styled, { css } from 'styled-components'

import { InputAdornment, TextField, TextFieldProps } from '@material-ui/core'

import SearchIcon from 'src/components/icons/Search'

export const StyledSearchIcon = styled(SearchIcon)(
  ({ theme }) => css`
    fill: ${theme.palette.brand.darkGray};
  `
)

const StyledTextField = styled(TextField)(
  ({ theme }) => css`
    .MuiOutlinedInput-root {
      min-height: ${theme.spacing(7)}px;
    }

    .Mui-focused {
      padding-left: ${theme.spacing(1.625)}px;
    }
  `
)

const TextSearch = React.forwardRef<HTMLDivElement, TextFieldProps>(
  (props, ref) => (
    <StyledTextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <StyledSearchIcon fontSize="small" />
          </InputAdornment>
        ),
        ref,
      }}
      {...props}
    />
  )
)

export default TextSearch
