import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { AppSwitch } from 'src/routes'
import useUser from 'src/hooks/useUser'
import SkipLink from 'src/components/navigation/SkipLink'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import AuthorizedAppLayout from 'src/components/layout/AuthorizedAppLayout'
import useAppState from 'src/hooks/useAppState'
import useLogout from 'src/hooks/useLogout'

const App: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const {
    data: { user },
  } = useUser()
  const { logout } = useLogout()
  const {
    state: { emailHasChanged },
  } = useAppState()

  useEffect(() => {
    // if the email has changed we block the history, so the user can not navigate away
    if (emailHasChanged) {
      history.block(() => false)
    }
  }, [emailHasChanged, history, logout])

  return (
    <>
      {/* Logout Warning Dialog */}
      <ConfirmDialog
        open={!!emailHasChanged}
        title={t('AccountSettings.logoutDialog.title')}
        description={t('AccountSettings.logoutDialog.description', {
          email: user?.email,
        })}
        confirmButtonText={t('AccountSettings.logoutDialog.logout')}
        onConfirm={logout}
      />
      <SkipLink href="#main-pass">Skip to main content</SkipLink>
      <AuthorizedAppLayout>
        <AppSwitch />
      </AuthorizedAppLayout>
      <script
        id="CookieDeclaration"
        src={`https://consent.cookiebot.com/${
          import.meta.env.REACT_APP_COOKIEBOT_DOMAIN_GROUP_ID
        }/cd.js`}
        type="text/javascript"
      />
    </>
  )
}

export default App
