import React, { useRef } from 'react'

import useContentScroll from 'src/hooks/useContentScroll'
import { ExpandLessOutlined } from '@material-ui/icons'
import { StyledIconButton } from './ScrollToTopButton.styles'
import { ScrollButtonProps } from './ScrollToTopButton.types'

const ScrollToTopButton: React.FC<ScrollButtonProps> = ({
  isVisible,
  isMobileLayout,
}) => {
  const buttonRef = useRef()

  const { scrollToTop } = useContentScroll()
  return (
    <StyledIconButton
      onClick={scrollToTop}
      ref={buttonRef.current}
      $isVisible={isVisible}
      $isMobileLayout={isMobileLayout}
    >
      <ExpandLessOutlined color="primary" fontSize="inherit" />
    </StyledIconButton>
  )
}

export default React.memo(ScrollToTopButton)
