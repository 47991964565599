import { useQuery } from 'react-query'
import {
  InitApplicationDocument,
  InitApplicationQuery,
} from 'src/generated/graphql-react-query'
import { useEffect } from 'react'
import { isEqual } from 'lodash'
import { useGraphQLClientRequest } from 'src/hooks/useGraphQLClientRequest'

import useAppState from 'src/hooks/useAppState'
import {
  InitialDataLoaded,
  SetCurrentFlags,
  UserHasUnsupportedMarket,
} from 'src/contexts/AppState'
import { isErrorResult } from 'src/utils/typeGuards'
import {
  isUnsupportedMarket,
  isUnreleasedNordicMarket,
} from 'src/utils/helpers'
import { useFeatureFlagQuery } from 'src/hooks/featureFlag/useFeatureFlagQuery'

const useInitApplication = () => {
  const { state, dispatch } = useAppState()

  const {
    data: { featureFlagsToQuery },
    operations: { getFeatureFlags },
  } = useFeatureFlagQuery()

  const request = useGraphQLClientRequest()

  const { data, isSuccess, refetch, isFetching } = useQuery<
    InitApplicationQuery | undefined
  >(
    'init-application',
    () =>
      request(InitApplicationDocument, {
        featureFlags: featureFlagsToQuery,
      }),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !state.initialDataLoaded,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  )

  useEffect(() => {
    if (isSuccess && !state.initialDataLoaded) {
      dispatch(InitialDataLoaded())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, state.initialDataLoaded])

  useEffect(() => {
    if (
      isSuccess &&
      data?.getFeatureFlag &&
      !isErrorResult(data?.getFeatureFlag)
    ) {
      const featureFlags = getFeatureFlags(data?.getFeatureFlag)
      featureFlags &&
        !isEqual(featureFlags, state.featureFlags) &&
        dispatch(SetCurrentFlags(featureFlags))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, data])

  useEffect(() => {
    if (
      isSuccess &&
      !isErrorResult(data?.getUser) &&
      isUnsupportedMarket(data?.getUser?.user)
    ) {
      dispatch(UserHasUnsupportedMarket())
    }
  }, [isSuccess, data, dispatch])

  // FEATURE FLAG
  // don't let Nordic users in if the feature flag is not turned on for them
  useEffect(() => {
    if (
      isSuccess &&
      !isErrorResult(data?.getUser) &&
      !isUnsupportedMarket(data?.getUser?.user) &&
      state.featureFlags &&
      isUnreleasedNordicMarket(data?.getUser?.user, state.featureFlags)
    ) {
      dispatch(UserHasUnsupportedMarket())
    }
  }, [isSuccess, data, state.featureFlags, dispatch])
  // FEATURE FLAG

  return {
    data,
    refetch,
    isFetching,
  }
}

export default useInitApplication
