import { useTranslationHelpers } from 'src/hooks/useTranslationHelpers'
import { useMarket } from '../../hooks/useMarket'
import { LMME } from 'src/utils/constants'

export const useAgreementTranslation = () => {
  const { translate: globalTranslate } = useTranslationHelpers('Global')
  const { translate } = useTranslationHelpers('InstructorAgreement')
  const { isMarketMatched: isLMME } = useMarket(LMME)

  return {
    HEADER: translate('header'),
    AGREE_TO_TERMS_TEXT: translate('agreeToTermsText'),
    ACCEPT_AND_CONTINUE: translate('acceptAndContinue'),
    PROCEED_PROMPT: translate('proceedPrompt'),
    NEED_HELP: translate('needHelp'),
    CONTACT_COMPANY_NAME: translate(
      !isLMME ? 'contactCompanyName' : 'lmmeContactCompanyName'
    ),
    CONTACT_EMAIL: translate(!isLMME ? 'contactEmail' : 'lmmeContactEmail'),
    CONTACT_PHONE: translate(!isLMME ? 'contactPhone' : 'lmmeContactPhone'),
    CONTACT_ADDRESS: translate(
      !isLMME ? 'contactAddress' : 'lmmeContactAddress'
    ),
    RETRY: translate('retry'),
    ERROR: translate('error'),
    SWITCH_PROFILE: translate('switchProfile'),
    PHONE: globalTranslate('phone'),
    EMAIL: globalTranslate('email'),
    CLOSE: globalTranslate('close'),
  }
}
