import React, {
  Dispatch,
  createContext,
  useReducer,
  PropsWithChildren,
} from 'react'
import { IdToken } from '@auth0/auth0-react'
import { FlagsProps } from 'src/hooks/featureFlag/types'

export enum AppActions {
  OpenMenu = 'app/menu/open',
  CloseMenu = 'app/menu/close',
  SetSessionInvalid = 'app/session/invalid',
  SetToken = 'app/token/set',
  I18nLoaded = 'app/i18n/loaded',
  initialDataLoaded = 'app/initialData/loaded',
  EmailHasChanged = 'app/email/changed',
  IsUnsupportedMarket = 'app/market/isUnsupported',
  IsNotificationOpen = 'app/notification/isNotificationOpen',
  FeatureFlags = 'app/featureFlags/set',
}

export interface AppState {
  menuIsOpen: boolean
  lastUpdate?: Date // not used
  sessionInvalid: boolean
  token?: IdToken
  i18nLoaded?: boolean
  initialDataLoaded?: boolean
  emailHasChanged?: boolean
  isUnsupportedMarket?: boolean
  isNotificationOpen: boolean
  featureFlags?: FlagsProps
}

export interface Action {
  type: AppActions
  payload?: any
}

export const OpenMenuAction = () => ({
  type: AppActions.OpenMenu,
})
export const CloseMenuAction = () => ({
  type: AppActions.CloseMenu,
})

export const SetSessionInvalidAction = () => ({
  type: AppActions.SetSessionInvalid,
})

export const SetTokenAction = (token: IdToken) => ({
  type: AppActions.SetToken,
  payload: token,
})

export const I18nLoaded = () => ({
  type: AppActions.I18nLoaded,
})

export const InitialDataLoaded = () => ({
  type: AppActions.initialDataLoaded,
})
export const EmailHasChangedAction = () => ({
  type: AppActions.EmailHasChanged,
})

export const UserHasUnsupportedMarket = () => ({
  type: AppActions.IsUnsupportedMarket,
})

export const SetIsNotificationOpen = (isNavOpen: boolean) => ({
  type: AppActions.IsNotificationOpen,
  payload: isNavOpen,
})

export const SetCurrentFlags = (currentFlags: FlagsProps) => ({
  type: AppActions.FeatureFlags,
  payload: currentFlags,
})

const initialState: AppState = {
  menuIsOpen: false,
  sessionInvalid: false,
  isNotificationOpen: false,
}

const reducer = (state: AppState, action: Action): AppState => {
  const { type, payload } = action
  switch (type) {
    case AppActions.IsUnsupportedMarket:
      return state.isUnsupportedMarket
        ? state
        : {
            ...state,
            isUnsupportedMarket: true,
          }
    case AppActions.OpenMenu:
      return state.menuIsOpen
        ? state
        : {
            ...state,
            menuIsOpen: true,
          }
    case AppActions.CloseMenu:
      return state.menuIsOpen
        ? {
            ...state,
            menuIsOpen: false,
          }
        : state
    case AppActions.SetSessionInvalid:
      return state.sessionInvalid
        ? state
        : {
            ...state,
            sessionInvalid: true,
          }
    case AppActions.SetToken:
      return state.token === payload
        ? state
        : {
            ...state,
            token: payload,
          }
    case AppActions.I18nLoaded:
      return state.i18nLoaded
        ? state
        : {
            ...state,
            i18nLoaded: true,
          }
    case AppActions.initialDataLoaded:
      return state.initialDataLoaded
        ? state
        : {
            ...state,
            initialDataLoaded: true,
          }
    case AppActions.EmailHasChanged:
      return state.emailHasChanged
        ? state
        : {
            ...state,
            emailHasChanged: true,
          }
    case AppActions.IsNotificationOpen:
      return state.isNotificationOpen === payload
        ? state
        : {
            ...state,
            isNotificationOpen: payload,
          }
    case AppActions.FeatureFlags:
      return state.featureFlags === payload
        ? state
        : {
            ...state,
            featureFlags: payload,
          }
    default:
      return state
  }
}

interface AppContextType {
  state: AppState
  dispatch: Dispatch<Action>
}

export const appStateContext = createContext<AppContextType>({
  state: initialState,
  dispatch: () => {},
})
appStateContext.displayName = 'AppStateContext'
const { Provider } = appStateContext

export const AppStateProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </Provider>
  )
}
