import moment from 'moment'
import 'moment/min/locales.min' // Required for languages to be available

import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  PropsWithChildren,
} from 'react'
import { Locale } from 'src/generated/graphql'
import {
  languageToLocale,
  localeToLanguage,
  isSupportedLocale,
  isValidLocaleFormat,
  isValidLanguageParamFormat,
} from 'src/utils/i18n'

interface LanguageParamContextType {
  value?: string
  acceptLanguageHeaderValue: string
  set: (lang: string) => void
}
const LanguageParamContext = createContext<LanguageParamContextType>({
  set: () => {},
  acceptLanguageHeaderValue: navigator.languages
    .map(languageToLocale)
    .join(','),
})
LanguageParamContext.displayName = 'LanguageParamContext'
const { Provider } = LanguageParamContext

const updateLanguageParamInUrl = (lang: string) => {
  if (!isValidLanguageParamFormat(lang)) {
    throw new Error(`Invalid language param format: ${lang}`)
  }
  const pathnameArray = window.location.pathname.split('/').slice(1)
  if (
    isValidLanguageParamFormat(pathnameArray[0]) ||
    isValidLocaleFormat(pathnameArray[0])
  ) {
    pathnameArray[0] = lang
  } else {
    pathnameArray.unshift(lang)
  }
  window.history.replaceState(
    window.history.state,
    document.title,
    `/${pathnameArray.join('/')}${window.location.search}${
      window.location.hash
    }`
  )
}

const LanguageParamProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [language, setLanguage] = useState<string>()

  useEffect(() => {
    // get the language from the url on first render
    let path = window.location.pathname.split('/')[1]

    if (!path) {
      return
    }

    // backward compatibility
    if (isValidLocaleFormat(path)) {
      path = localeToLanguage(path as Locale)
      updateLanguageParamInUrl(path)
    }

    if (isValidLanguageParamFormat(path)) {
      setLanguage(path)
    }
  }, [])

  const acceptLanguageHeaderValue: string = useMemo(() => {
    return languageToLocale(language || navigator.language)
    // TODO: update BE to accept multiple languages
    // TODO: update BE to accept the standard language codes with dash (eg: en-GB)
    // const langs = navigator.languages.slice(0)
    // if (language) {
    //   langs.unshift(language)
    // }
    // return langs.map(languageToLocale).join(',')
  }, [language])

  const set = (lang: string) => {
    if (!isSupportedLocale(languageToLocale(lang))) {
      throw new Error('Not supported language')
    }
    updateLanguageParamInUrl(lang)
    setLanguage(lang)
  }

  useEffect(() => {
    moment.locale(language)
  }, [language])

  return (
    <Provider value={{ acceptLanguageHeaderValue, value: language, set }}>
      {children}
    </Provider>
  )
}

export const useLanguageParam = () => useContext(LanguageParamContext)

export default LanguageParamProvider
