import { Status } from 'src/components/inputs/StatusDropdown/StatusDot'
import { palette } from 'src/styles/theme'

export const getStatusColor = (value: Status) => {
  const {
    brand: { green, orange, red, gray },
  } = palette
  switch (value) {
    case Status.Positive:
      return green
    case Status.Neutral:
      return orange
    case Status.Negative:
      return red
    default:
      return gray
  }
}

export default getStatusColor
