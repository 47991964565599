import styled, { css } from 'styled-components'

import { Grid } from '@material-ui/core'

import { Box } from 'src/adaptor/materialUI'
import Skeleton from '@material-ui/lab/Skeleton'
import useMediaQueries from 'src/hooks/useMediaQueries'
import { useMemo } from 'react'
import ListingCardSkeleton from '../findWork/ListingCardSkeleton'
import PageContainer from '../layout/PageContainer'

const TitleContainer = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 20px 0px 80px 0px;
`

const ButtonSkeleton = styled(Skeleton)(
  ({ theme }) => `
    display: inline-block;
    border-radius: ${theme.spacing(1.25)}px;
    margin-right: ${theme.spacing(2)}px`
)

const SubtitleSkeleton = styled(Skeleton)(
  ({ theme }) => `
    display: inline-block;
    margin-right: ${theme.spacing(2)}px`
)

const SearchHeader = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacing(7.5, 0, 5, 0)};
    flex: 1 1 0%;
    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(2.5, 0, 2.5, 0)};
      flex-direction: column;
      align-items: flex-start;
    }
  `
)

const SelectSkeleton = styled(Skeleton)(
  ({ theme }) => `
  flex: 1 0 188px;
  margin: 0;
  border-radius: ${theme.spacing(1.25)}px;`
)

const GridContainer = styled(Grid)(
  ({ theme }) => css`
    padding-top: ${theme.spacing(4.5)}px;
    ${theme.breakpoints.down('md')} {
      padding-top: 0;
    }
  `
)

const FilterGrid = styled(Grid)(
  ({ theme }) => css`
    background-color: ${theme.palette.brand.white};
    ${theme.breakpoints.down('md')} {
      z-index: 1;
      width: 100%;
      position: sticky;
      top: 64px; /* as per design */
    }
  `
)

const StickyFilterBox = styled(Box)(
  ({ theme }) => css`
    width: 100%;
    background-color: ${theme.palette.brand.white};
    ${theme.breakpoints.up('lg')} {
      top: 96px;
      z-index: 1;
      position: sticky;
    }
  `
)

const ListGrid = styled(Grid)(
  ({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    ${theme.breakpoints.down('md')} {
      justify-content: center;
    }
    ${theme.breakpoints.up('lg')} {
      padding-top: ${theme.spacing(4.5)}px;
    }
    > * {
      display: inline-flex;
      margin-bottom: ${theme.spacing(4)}px;
      margin-right: ${theme.spacing(2)}px;
      &:last-child {
        margin-right: 0;
      }
    }
  `
)

const FilterFieldsetBox = styled(Grid)(
  ({ theme }) => css`
    padding-top: ${theme.spacing(2.5)}px;
    ${theme.breakpoints.up('lg')} {
      padding-bottom: ${theme.spacing(2.5)}px;
      border-bottom: 1px solid ${theme.palette.brand.lightGray};
    }
  `
)

const FilterLabel = styled(Box)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(1.25)}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `
)

const ProgramList = styled(Box)(
  ({ theme }) => css`
    > * {
      display: inline-flex;
    }
    .MuiDivider-root {
      width: 100%;
      display: block;
      margin: ${theme.spacing(2.5, 0)};
      ${theme.breakpoints.down('md')} {
        display: none;
      }
    }
  `
)

const ClassCard = styled(Skeleton)(
  ({ theme }) => css`
    padding: ${theme.spacing(1)}px;
    margin: ${theme.spacing(0, 1, 0, 0)};
  `
)

const TeachingPositions = () => {
  const { isSmallScreen } = useMediaQueries()
  const loadingSkeletons = useMemo(
    () =>
      new Array(12)
        .fill(null)
        .map((_, index) => <ListingCardSkeleton key={index} />),
    []
  )
  return (
    <>
      <Box display="flex" width="100%" height="100%" overflow="hidden">
        <Box
          flex={1}
          height="100%"
          width="100%"
          maxWidth="100%"
          overflow="auto"
        >
          <Box pt={12} pl={2} pr={2}>
            <Box>
              <TitleContainer>
                <Skeleton variant="text" width={250} height={90} />
                <Skeleton variant="text" width={500} height={45} />
              </TitleContainer>
              <PageContainer>
                <SearchHeader>
                  <Box flex={1} display="flex" alignItems="center">
                    <SubtitleSkeleton variant="text" width={100} height={60} />
                    <SubtitleSkeleton variant="text" width={120} height={60} />
                    <ButtonSkeleton variant="text" width={100} height={60} />
                  </Box>
                  <Box display="block">
                    <SelectSkeleton variant="text" width={100} height={60} />
                  </Box>
                </SearchHeader>
                <GridContainer container spacing={2}>
                  <FilterGrid item lg={3} md={12} sm={12}>
                    {!isSmallScreen && (
                      <StickyFilterBox>
                        <FilterFieldsetBox container>
                          <Grid item xs={12}>
                            <Skeleton variant="text" width={100} height={40} />
                          </Grid>
                          <Grid xs={12} md={6} lg={12} item>
                            <Skeleton variant="text" width={310} height={60} />
                          </Grid>
                          <Grid xs={12} md={6} lg={12} item>
                            <Skeleton variant="text" width={310} height={60} />
                          </Grid>
                        </FilterFieldsetBox>
                        <FilterFieldsetBox>
                          <FilterLabel>
                            <Skeleton variant="text" width={100} height={40} />
                            <Skeleton variant="text" width={50} height={40} />
                          </FilterLabel>
                          <ProgramList>
                            <ClassCard variant="text" width={100} height={60} />
                            <ClassCard variant="text" width={100} height={60} />
                          </ProgramList>
                          <ProgramList>
                            <ClassCard variant="text" width={65} height={30} />
                            <ClassCard variant="text" width={72} height={30} />
                          </ProgramList>
                        </FilterFieldsetBox>
                      </StickyFilterBox>
                    )}
                  </FilterGrid>
                  <ListGrid item lg={9} md={12} sm={12}>
                    {loadingSkeletons}
                  </ListGrid>
                </GridContainer>
              </PageContainer>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default TeachingPositions
