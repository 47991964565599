import React from 'react'
import styled, { css } from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Illustration, {
  UtilityIcon,
} from 'src/components/dataDisplay/Illustration'

const CaptionBox = styled.div<{ $color?: string }>(
  ({ theme, $color }) => css`
    display: flex;
    align-items: center;
    background-color: ${$color || theme.palette.brand.offWhite};
    margin: ${theme.spacing(1, 0, 1)};
    min-height: ${theme.spacing(5.375)}px;
    padding: ${theme.spacing(1, 1.5)};
    border-radius: ${theme.spacing(1.25)}px;
  `
)

const StyledWarningText = styled(Typography)<{ $color?: string }>(
  ({ theme, $color }) => css`
    color: ${$color || theme.palette.text.secondary};
    font-size: ${theme.typography.pxToRem(12)};
    margin-left: ${theme.spacing(1)}px;
  `
)

const Caption: React.FC<{ text: string; icon?: UtilityIcon }> = ({
  text,
  icon,
}) => (
  <CaptionBox>
    {icon && <Illustration type={icon} minWidth={16} />}
    <StyledWarningText>{text}</StyledWarningText>
  </CaptionBox>
)

export const ElementCaption: React.FC<{
  element: React.ReactElement
  icon?: UtilityIcon
}> = ({ element, icon }) => (
  <CaptionBox>
    {icon && <Illustration type={icon} minWidth={16} />}
    <StyledWarningText>{element}</StyledWarningText>
  </CaptionBox>
)

export default Caption
