import React, { ReactNode, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  Card,
  Grid,
  Button,
  Divider,
  Typography,
  CardContent,
  InputLabel,
  InputAdornment,
} from '@material-ui/core'
import { Box } from 'src/adaptor/materialUI'
import ClearIcon from '@material-ui/icons/Clear'

import { UserProfileType } from 'src/generated/graphql'
import Dialog from 'src/components/dialog'
import useProfiles, {
  userProfileTypeToUserProfileTypename,
} from 'src/hooks/useProfiles'
import { AppPaths } from 'src/routes'
import useUser from 'src/hooks/useUser'
import useProfile, {
  UserProfile,
  InstructorProfile,
  ClubProfile,
} from 'src/hooks/useProfile'
import { filterProfilesByTypename } from 'src/utils/history'
import { isClubProfile, isTeachingProfile } from 'src/utils/typeGuards'
import ProfileSelectionItem from 'src/components/profileSelection/ProfileSelectionItem'
import clubDefault from 'src/assets/images/clubDefault.png'
import TextSearch, { StyledSearchIcon } from 'src/components/inputs/TextSearch'
import { filterProfilesByProfileType } from 'src/utils/helpers'

const StyledClearIcon = styled(ClearIcon)(
  ({ theme }) => css`
    fill: ${theme.palette.brand.darkGray};
    :hover {
      cursor: pointer;
    }
  `
)

const StyledCardContent = styled(CardContent)(
  ({ theme }) => css`
    padding: ${theme.spacing(1)}px;

    ${theme.breakpoints.down('sm')} {
      padding: 0;
    }
  `
)

const ProfileTypeContainer = styled(Grid)`
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`

const ProfileType = styled(Grid)`
  flex: 1;
`

const ClubProfileContainer = styled(Grid)`
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`

const ClubProfileGrid = styled(Grid)`
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
`

const StyledDialog = styled(Dialog)(
  ({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      .MuiDialog-paperWidthSm {
        min-width: 600px;
        max-width: 740px; /* as per design */
      }
    }
  `
)

const StyledTextSearch = styled(TextSearch)(
  ({ theme }) => css`
    margin-top: ${theme.spacing(1)}px;
    width: 100%;
  `
)

interface ProfileSelectionProps {
  title: ReactNode
  subtitle?: string
  filter?: UserProfileType
  onClose?: React.MouseEventHandler<HTMLElement> // TODO implement close button using this
  onSwitchProfile?: (profileId: string) => void
}

const ProfileSelection: React.FC<ProfileSelectionProps> = ({
  title,
  filter,
  onClose,
  subtitle,
  onSwitchProfile,
}) => {
  const {
    data: { user },
  } = useUser()
  const {
    data: { profiles },
  } = useProfiles()
  const {
    data: { profile },
    operations: { switchProfile },
  } = useProfile()
  const { t } = useTranslation()
  const isClubOnly =
    filterProfilesByProfileType(profiles, UserProfileType.Instructor).length ===
    0
  const [showClubProfiles, setShowClubProfiles] = useState(isClubOnly)
  const [quickFindValue, setQuickFindValue] = useState('')

  const handleSwitchProfile = (profileId: string) => {
    if (onSwitchProfile) {
      onSwitchProfile(profileId)
      return
    }
    switchProfile(profileId)
  }

  const handleClear = () => setQuickFindValue('')
  const handleQuickFind = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuickFindValue(e.target.value)
  }

  const handleShowClubProfiles = () =>
    !isClubOnly && setShowClubProfiles(!showClubProfiles)

  // if the user has only one profile we just use this
  useEffect(() => {
    if (!profiles) {
      return
    }
    if (profiles?.length === 1) {
      switchProfile(profiles[0].id)
      return
    }
    if (!filter) {
      return
    }
    const filteredProfiles = filterProfilesByProfileType(profiles, filter)
    if (filteredProfiles?.length === 1) {
      switchProfile(filteredProfiles[0].id)
    }
  }, [filter, switchProfile, profiles])

  if (!profiles) {
    return null
  }

  const filteredProfiles: UserProfile[] = filter
    ? filterProfilesByTypename(
        profiles,
        userProfileTypeToUserProfileTypename(filter)
      )
    : profiles

  const instructorProfile: InstructorProfile | undefined =
    filteredProfiles.find(isTeachingProfile)
  const clubProfiles: ClubProfile[] =
    filteredProfiles.filter(isClubProfile) ?? []

  const filteredClubProfiles = clubProfiles
    .filter(profile =>
      profile.name.toLowerCase().includes(quickFindValue.toLowerCase())
    )
    .sort((a, b) => (a.name > b.name ? 1 : -1))

  if (!profiles?.length) {
    return (
      <Card raised>
        <StyledCardContent>
          <Typography variant="body1">{t('ProfileSelection.error')}</Typography>
          <Box mt={4} mb={5}>
            <Divider />
          </Box>
          <Grid container direction="row" alignItems="center" justify="center">
            <Button variant="text" href={AppPaths.Logout}>
              {t('ProfileSelection.signOut')}
            </Button>
          </Grid>
        </StyledCardContent>
      </Card>
    )
  }

  return (
    <StyledDialog
      open
      title={title}
      subTitle={subtitle}
      onClose={onClose}
      disableCloseButton={!profile?.id}
    >
      <StyledCardContent>
        <ProfileTypeContainer container direction="row" spacing={2}>
          {clubProfiles.length > 0 && (
            <ProfileType
              item
              xs={12}
              md={showClubProfiles || !!filteredClubProfiles.length ? 6 : 12}
            >
              <ProfileSelectionItem
                isClubProfile
                photoUrl={clubDefault}
                name={t('ProfileSelection.clubs')}
                description={t('ProfileSelection.location', {
                  count: clubProfiles.length,
                })}
                selected={showClubProfiles}
                onClick={handleShowClubProfiles}
              />
            </ProfileType>
          )}
          {instructorProfile && (
            <>
              <ProfileType
                item
                xs={12}
                md={showClubProfiles || !!filteredClubProfiles.length ? 6 : 12}
              >
                <ProfileSelectionItem
                  photoUrl={instructorProfile?.profileImage?.url}
                  name={user?.firstName ?? ''}
                  description={t('ProfileSelection.myDashboard')}
                  onClick={() => handleSwitchProfile(instructorProfile.id)}
                  showArrow
                  isActiveProfile={instructorProfile.id === profile?.id}
                  fullWidth={!clubProfiles.length}
                />
              </ProfileType>
            </>
          )}
        </ProfileTypeContainer>
        {showClubProfiles && clubProfiles.length > 4 && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            width="100%"
            mb={1}
          >
            <Box width="100%">
              <InputLabel>{t('ProfileSelection.quickFind')}</InputLabel>
              <StyledTextSearch
                onChange={handleQuickFind}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <StyledSearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <StyledClearIcon fontSize="small" onClick={handleClear} />
                    </InputAdornment>
                  ),
                }}
                value={quickFindValue}
              />
            </Box>
          </Box>
        )}
        {showClubProfiles && (
          <ClubProfileContainer container>
            {filteredClubProfiles?.map(({ id, name, address }) => (
              <ClubProfileGrid item key={id} xs={12}>
                <ProfileSelectionItem
                  isClubItem
                  name={name}
                  description={t('Global.address.full', { ...address })}
                  onClick={() => handleSwitchProfile(id)}
                  showArrow
                  isActiveProfile={id === profile?.id}
                  fullWidth
                  hideImage
                />
              </ClubProfileGrid>
            ))}
          </ClubProfileContainer>
        )}
        <Grid container direction="row" alignItems="center" justify="center">
          <Typography>{t('ProfileSelection.notYou')}</Typography>
          <Button variant="text" href={AppPaths.Logout}>
            {t('ProfileSelection.signOut')}
          </Button>
        </Grid>
      </StyledCardContent>
    </StyledDialog>
  )
}

export default ProfileSelection
