import React, { PropsWithChildren } from 'react'
import styled, { css, StyledProps } from 'styled-components'

import { Typography } from '@material-ui/core'
import { Box } from 'src/adaptor/materialUI'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'

import useMediaQueries from 'src/hooks/useMediaQueries'
import AppBar, { APPBAR_HEIGHT } from 'src/components/layout/AppBar'
import ErrorBoundary from 'src/utils/ErrorBoundary'
import Navigation from 'src/modules/_core/Navigation'
import AdminBanner from 'src/components/admin/AdminBanner'
import useUser from 'src/hooks/useUser'
import { ScrollToTopWrapper } from 'src/components/inputs/ScrollToTopButton'

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Main = styled.main`
  display: flex;
  flex: 1;
  overflow: auto;
`

const NavigationContainer = styled.div<{
  $isMobileLayout?: boolean
}>(
  ({ $isMobileLayout }) => css`
    transition: width 0.5s;
    overflow-x: hidden;
    ${$isMobileLayout
      ? css`
          width: 0;
        `
      : css`
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
        `}
  `
)

const MainWrapper = styled(Box)(
  ({ $isMobileLayout }: StyledProps<{ $isMobileLayout?: boolean }>) => css`
    padding-top: ${$isMobileLayout ? APPBAR_HEIGHT : 0}px;
  `
)

// TODO: Placeholder - no designs exist
const FallbackBox = styled(Box)(
  ({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${theme.palette.background.grey};
  `
)

const NavFallback = (
  <FallbackBox>
    <ReportProblemIcon color="error" />
    <Typography color="error">Error loading navigation.</Typography>
  </FallbackBox>
)

// Since our layout is driven by the Main and the ContentContaineer
// we need reference for the content container for example to handle the scroll
export const ContentContainerId = 'ContentView'

const AuthorizedAppLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { isSmallScreen: isMobileLayout } = useMediaQueries()
  const {
    data: { user },
  } = useUser()

  return (
    <Container>
      <AdminBanner />
      <Main>
        <NavigationContainer $isMobileLayout={isMobileLayout}>
          <ErrorBoundary fallbackComponent={NavFallback}>
            {user && <Navigation />}
          </ErrorBoundary>
        </NavigationContainer>
        <ScrollToTopWrapper
          id={ContentContainerId}
          isMobileLayout={isMobileLayout}
        >
          <AppBar />
          <MainWrapper $isMobileLayout={isMobileLayout}>{children}</MainWrapper>
        </ScrollToTopWrapper>
      </Main>
    </Container>
  )
}

export default AuthorizedAppLayout
