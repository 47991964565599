import styled, { css } from 'styled-components'

import { Typography } from '@material-ui/core'

const Title = styled(Typography)(
  ({ theme }) => css`
    font-family: ${theme.typography.fontFamily};
    font-style: normal;
    font-weight: normal;
    font-size: ${theme.typography.pxToRem(24)};
    line-height: 120%;
    letter-spacing: -0.02em;
  `
)

export default Title
