import { useState, useEffect } from 'react'
import axios from 'axios'
import { ProgramConfigDic } from 'src/contexts/ProgramProvider'

const useProgramConfig = () => {
  const [programConfig, setProgramConfig] = useState<ProgramConfigDic | null>(
    null
  )
  const [error, setError] = useState(null)

  useEffect(() => {
    const programConfigUrl = `${
      import.meta.env.REACT_APP_TRANSLATION_BASE_URL
    }/program-config.json`

    axios
      .get(programConfigUrl)
      .then(response => {
        setProgramConfig(response.data)
      })
      .catch(err => {
        setError(err)
      })
  }, [])

  return { programConfig, error }
}

export default useProgramConfig
