import React, { useEffect } from 'react'
import { Container, Typography } from '@material-ui/core'
import { Box } from 'src/adaptor/materialUI'
import styled, { css } from 'styled-components'

import Button from 'src/components/button/Button'
import useLogout from 'src/hooks/useLogout'
import { ClubPaths, InstructorPaths } from 'src/routes'
import { useTranslation } from 'react-i18next'
import useProfile from 'src/hooks/useProfile'
import { isClubProfile } from 'src/utils/typeGuards'
import { Link } from 'react-router-dom'
import { allowNewline } from 'src/utils/helpers'
import { datadogRum } from '@datadog/browser-rum'

const StyledTitle = styled(Typography)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(1)}px;
  `
)

const StyledSubtitle = styled(Typography)(
  ({ theme }) => css`
    margin-top: ${theme.spacing(4)}px;
  `
)

const StyledBodyText = styled(Typography)(
  ({ theme }) => css`
    margin-top: ${theme.spacing(1)}px;
    max-width: 700px;
  `
)

const StyledContainer = styled(Container)(
  ({ theme }) => css`
    height: calc(100vh - 64px);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    ${theme.breakpoints.up('sm')} {
      padding-left: 20%;
    }
    ${theme.breakpoints.down('sm')} {
      padding-left: ${theme.spacing(2)}px;
    }
  `
)

export const NotFoundActionButton = styled(Button)(
  ({ theme }) => css`
    margin-right: ${theme.spacing(2.5)}px;
    ${theme.breakpoints.down('sm')} {
      margin-bottom: ${theme.spacing(2)}px;
    }
  `
)

interface PageNotFoundProps {
  title?: string | false
  subTitle?: string | false
  bodyText?: string | false
  hideHomeLink?: boolean
  buttons?: React.ReactNode | React.ReactNodeArray
}
const PageNotFound: React.FC<PageNotFoundProps> = ({
  title,
  subTitle,
  buttons,
  bodyText,
  hideHomeLink,
}) => {
  const { logout } = useLogout()
  const { t } = useTranslation()

  const {
    data: { profile },
  } = useProfile()

  const isClubProfileSelected = !!profile && isClubProfile(profile)

  useEffect(() => {
    const error = new Error(
      `Error Occurred - Page Not Found - URL: ${window.location.href}`
    )
    datadogRum.addError(error)
  }, [])

  return (
    <StyledContainer>
      <Box display={'flex'} flexDirection="column" justifyContent={'center'}>
        {title === false ? null : (
          <StyledTitle variant="h3">
            {title || t('PageNotFound.title')}
          </StyledTitle>
        )}

        {subTitle === false ? null : (
          <StyledSubtitle variant="h6">
            {subTitle || t('PageNotFound.subtitle')}
          </StyledSubtitle>
        )}

        {bodyText === false ? null : (
          <StyledBodyText variant="body1" {...allowNewline}>
            {bodyText || t('PageNotFound.bodyText')}
          </StyledBodyText>
        )}

        <Box mt={4}>
          {!hideHomeLink && (
            <Link
              to={
                isClubProfileSelected
                  ? ClubPaths.Dashboard
                  : InstructorPaths.Dashboard
              }
            >
              <NotFoundActionButton color={'primary'}>
                {t('PageNotFound.homeButtonText')}
              </NotFoundActionButton>
            </Link>
          )}

          {buttons || (
            <NotFoundActionButton onClick={logout} color={'secondary'}>
              {t('PageNotFound.logoutButtonText')}
            </NotFoundActionButton>
          )}

          <Link
            to={
              isClubProfileSelected
                ? ClubPaths.Support
                : InstructorPaths.Support
            }
          >
            <NotFoundActionButton color={'secondary'}>
              {t('PageNotFound.contactSupportButtonText')}
            </NotFoundActionButton>
          </Link>
        </Box>
      </Box>
    </StyledContainer>
  )
}

export default PageNotFound
