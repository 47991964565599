import React, { useEffect, useRef, useState } from 'react'
import { ScrollToTopButtonContainerProps } from './ScrollToTopButton.types'
import { ButtonContainer } from './ScrollToTopButton.styles'
import ScrollToTopButton from './ScrollToTopButton.ui'
import { useComponentOnLayoutVisibility } from 'src/contexts/ComponentOnLayoutVisibilityProvider'

export const ScrollToTopButtonContainer: React.FC<ScrollToTopButtonContainerProps> =
  ({ children, id, isMobileLayout }) => {
    const ref = useRef<HTMLDivElement>(null)
    const [scrollButtonVisible, setScrollButtonVisible] = useState(false)
    const { hideScrollBtn } = useComponentOnLayoutVisibility()

    const handleScroll = () => {
      if (ref.current) {
        if (ref.current.scrollTop > 348) {
          if (!scrollButtonVisible) setScrollButtonVisible(true)
        } else {
          if (scrollButtonVisible) setScrollButtonVisible(false)
        }
      }
    }

    useEffect(() => {
      const temp = ref.current
      if (temp) temp.addEventListener('scroll', handleScroll)
      if (temp) return () => temp.removeEventListener('scroll', handleScroll)
    })

    return (
      <ButtonContainer id={id} $isMobileLayout={isMobileLayout} ref={ref}>
        {!hideScrollBtn && (
          <ScrollToTopButton
            isVisible={scrollButtonVisible}
            isMobileLayout={isMobileLayout}
          />
        )}
        {children}
      </ButtonContainer>
    )
  }
