import useUser from './useUser'

export const useMarket = (marketName?: string) => {
  const {
    data: { user },
  } = useUser()

  const userMarket = user?.market.name ?? undefined

  const getUserMarket = () => {
    return user?.market.name
  }
  const getUserLocale = () => {
    return user?.market.locale
  }

  return {
    isMarketMatched: userMarket === marketName,
    getUserLocale,
    getUserMarket,
  }
}
