// Adaptor to make sure react-router-dom v5 types will not cause type check errors
// TODO remove this file when all components are migrated to v6

import React from 'react'
import {
  BrowserRouter as OriginalBrowserRouter,
  BrowserRouterProps,
  MemoryRouter as OriginalMemoryRouter,
  MemoryRouterProps,
  Route as OriginalRoute,
  RouteProps,
  Switch as OriginalSwitch,
  SwitchProps,
  Redirect as OriginalRedirect,
  RedirectProps,
  Prompt as OriginalPrompt,
  PromptProps,
} from 'react-router-dom'

export function BrowserRouter(props: BrowserRouterProps) {
  // @ts-ignore
  return <OriginalBrowserRouter {...props} />
}

export function MemoryRouter(props: MemoryRouterProps) {
  // @ts-ignore
  return <OriginalMemoryRouter {...props} />
}

export function Route(props: RouteProps) {
  // @ts-ignore
  return <OriginalRoute {...props} />
}

export function Switch(props: SwitchProps) {
  // @ts-ignore
  return <OriginalSwitch {...props} />
}

export function Redirect(props: RedirectProps) {
  // @ts-ignore
  return <OriginalRedirect {...props} />
}

export function Prompt(props: PromptProps) {
  // @ts-ignore
  return <OriginalPrompt {...props} />
}
