import React from 'react'
import { Divider, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import { FlexCard } from 'src/components/findWork/FlexCard'
import { MetaInfo } from 'src/components/findWork/MetaInfo'
import { CardHeader } from 'src/components/findWork/CardHeader'
import HeartCheckbox from 'src/components/inputs/HeartCheckbox'
import { ListingInfo } from 'src/components/findWork/ListingInfo'
import { Description } from 'src/components/findWork/Description'
import { ProgramInfo } from 'src/components/findWork/ProgramInfo'
import { ProgramName } from 'src/components/findWork/ProgramName'
import { CardContent } from 'src/components/findWork/CardContent'
import { LastUpdateDate } from 'src/components/findWork/LastUpdateDate'

const ListingCardSkeleton: React.FC = () => (
  <FlexCard elevation={1}>
    <CardHeader
      avatar={<Skeleton variant="circle" width={50} height={50} />}
      action={<HeartCheckbox />}
    />
    <CardContent>
      <ProgramInfo>
        <ProgramName variant="body1">
          <Skeleton variant="text" width={160} height={22} />
        </ProgramName>
        <Typography variant="body1">
          <Skeleton variant="text" width={80} height={22} />
        </Typography>
        <Skeleton variant="text" width={170} height={22} />
      </ProgramInfo>
      <ListingInfo>
        <Description variant="body1">
          <Skeleton variant="text" width={250} height={44} />
        </Description>
        <Skeleton variant="rect" width={36} height={36} />
      </ListingInfo>
      <Divider />
      <MetaInfo>
        <LastUpdateDate>
          <Skeleton variant="text" width={89} height={19} />
        </LastUpdateDate>
      </MetaInfo>
    </CardContent>
  </FlexCard>
)

export default ListingCardSkeleton
