import { FormControlLabel, Typography } from '@material-ui/core'
import { Box } from 'src/adaptor/materialUI'
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ActionButton,
  AgreementContent,
  StyledCard,
  StyledCardContent,
  StyledDivider,
  StyledCheckbox,
} from 'src/components/Agreement/Agreement.styles'
import Toast, { ToastTypeEnum } from 'src/components/feedback/Toast'
import FullScreenBackdropDialog from 'src/components/layout/FullScreenBackdropDialog'
import { AgreementDocumentResult } from 'src/generated/graphql'
import useToast from 'src/hooks/useToast'
import ModalLink from 'src/components/navigation/ModalLink'
import { ModalPaths } from 'src/routes'

export type ClubTermsProps = {
  document: Pick<AgreementDocumentResult, 'content'>
  signAgreement: () => void
  loading: boolean
  error: boolean
}

const ClubPrivacyAgreement = ({
  document,
  signAgreement,
  error,
  loading,
}: ClubTermsProps) => {
  const { t } = useTranslation()

  const [isAccepted, setIsAccepted] = useState(false)
  const toggleAccepted = () => setIsAccepted(val => !val)

  const { toastState, isToastOpen, showToast, hideToast } = useToast()

  useEffect(() => {
    if (error) {
      showToast({
        variant: ToastTypeEnum.ERROR,
        message: t('ClubPrivacyAgreement.acceptErrorToast'),
      })
    } else {
      hideToast()
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FullScreenBackdropDialog>
      <StyledCard raised>
        <StyledCardContent>
          <Typography variant="h6">
            {t('ClubPrivacyAgreement.title')}
          </Typography>
          <StyledDivider />
          <StyledCardContent>
            <AgreementContent>
              <Typography variant="body1">{document.content}</Typography>
            </AgreementContent>
            <FormControlLabel
              label={<strong>{t('ClubPrivacyAgreement.agreeText')}</strong>}
              control={
                <StyledCheckbox
                  checked={isAccepted}
                  onChange={toggleAccepted}
                  icon={<RadioButtonUnchecked />}
                  checkedIcon={<RadioButtonChecked />}
                />
              }
            />
            <Box
              mt={8}
              mb={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <ModalLink to={ModalPaths.SwitchProfile}>
                <ActionButton
                  variant="contained"
                  color="secondary"
                  loading={loading}
                >
                  {t('Global.buttonLabels.cancel')}
                </ActionButton>
              </ModalLink>
              <ActionButton
                variant="contained"
                color="primary"
                disabled={!isAccepted}
                onClick={signAgreement}
                loading={loading}
              >
                {t('ClubPrivacyAgreement.nextLabel')}
              </ActionButton>
            </Box>
          </StyledCardContent>
          <Toast
            isOpen={isToastOpen}
            handleClose={hideToast}
            message={toastState?.message}
            variant={toastState?.variant}
          />
        </StyledCardContent>
      </StyledCard>
    </FullScreenBackdropDialog>
  )
}

export default ClubPrivacyAgreement
