export enum AgreementStatus {
  // Data is loading to determine the state
  Loading = 'loading',

  // Agreement is signed and valid
  Valid = 'valid',

  // Agreement not signed (or outdated version is signed)
  SigningRequired = 'signing_required',

  // Request to BE to sign agreement is currently in progress
  SigningInProgress = 'signing_in_progress',

  // Request to BE to sign agreement has failed
  SigningError = 'signing_error',

  // There is a signed privacy agreement but an error occurred while
  // trying to verify if it is the latest version or during attempting
  // to fetch the latest version
  SignedUnverified = 'signed_unverified',

  // User needs to sign a DPA but we couldn't fetch it
  FetchAgreementError = 'fetch_agreement_error',

  // User is on the fetch error state and triggered a retry
  FetchAgreementErrorRetrying = 'fetch_agreement_error_retrying',
}
