import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Toast, { ToastTypeEnum } from 'src/components/feedback/Toast'
import useToast from 'src/hooks/useToast'
import { InstructorAgreementContainerProps } from './InstructorAgreement.types'
import { InstructorAgreement } from './InstructorAgreement.ui'

export const InstructorAgreementContainer: React.FC<InstructorAgreementContainerProps> =
  ({ document, signAgreement, error, loading }) => {
    const { t } = useTranslation()

    const [isAccepted, setIsAccepted] = useState(false)
    const [showWarning, setShowWarning] = useState(false)
    const toggleAccepted = () =>
      setIsAccepted(val => {
        setShowWarning(val)
        return !val
      })
    const setWarningShowing = () => {
      setShowWarning(true)
    }
    const { toastState, isToastOpen, showToast, hideToast } = useToast()

    useEffect(() => {
      if (error) {
        showToast({
          variant: ToastTypeEnum.ERROR,
          message: t('ClubPrivacyAgreement.acceptErrorToast'),
        })
      } else {
        hideToast()
      }
    }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Toast
          isOpen={isToastOpen}
          handleClose={hideToast}
          message={toastState?.message}
          variant={toastState?.variant}
        />
        {document.content ? (
          <InstructorAgreement
            documentContent={document.content}
            showWarning={showWarning}
            loading={loading}
            isAccepted={isAccepted}
            onAccept={toggleAccepted}
            setWarningShowing={setWarningShowing}
            signAgreement={signAgreement}
          />
        ) : null}
      </>
    )
  }
