import { RumEvent, datadogRum } from '@datadog/browser-rum'
import { ANNOUNCEMENT_BANNERS, CTA_TILES } from './constants'

const prod = /^connect\.lesmills\.com/
const uat = /^uat\.connect\.lesmills\.com/
const test = /^test[1-4]?\.connect\.lesmills\.com/

export function initRUM(): void {
  try {
    const { host } = window.location
    const clientToken = import.meta.env.REACT_APP_DATADOG_RUM_TOKEN
    const isProd = prod.test(host)
    const isUat = uat.test(host)
    const isTest = test.test(host)

    if (!clientToken) return

    const env = isProd ? 'prod' : isUat ? 'uat' : isTest ? 'test' : 'dev'

    const shouldDiscard = (event: RumEvent) => {
      if (event.type === 'resource' && event.resource.status_code === 404) {
        if (
          event.resource.url.includes(CTA_TILES) ||
          event.resource.url.includes(ANNOUNCEMENT_BANNERS)
        ) {
          return true
        }
      }
    }

    datadogRum.init({
      applicationId: 'bb70e67b-a700-4f49-bd26-eeb107c99ca7',
      clientToken,
      site: 'datadoghq.com',
      service: 'lmi-connect',
      env,
      version: import.meta.env.REACT_APP_CI_COMMIT_SHORT_SHA,
      sampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      actionNameAttribute: 'data-dd-action-name',
      beforeSend: (event: RumEvent) => {
        if (shouldDiscard(event)) {
          return false
        }
      },
    })

    datadogRum.startSessionReplayRecording()
  } catch (error) {
    // silence the error
    console.error(error)
  }
}
