import styled, { css, StyledProps } from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Illustration from 'src/components/dataDisplay/Illustration'

export const CaptionBox = styled.div(
  ({
    theme,
    $isShrink,
    $isOneLine,
    $isDark,
    $color,
    $noMargin,
  }: StyledProps<{
    $isShrink?: boolean
    $isOneLine?: boolean
    $isDark?: boolean
    $color?: string
    $noMargin?: boolean
  }>) => css`
    display: flex;
    align-items: ${$isShrink ? 'flex-start' : 'center'};
    background-color: ${$color ||
    ($isDark ? theme.palette.brand.lightGray : theme.palette.brand.offWhite)};
    width: ${$isShrink ? 'fit-content' : 'auto'};
    margin: ${$noMargin ? 0 : theme.spacing(1, 0, 1)};
    min-height: ${theme.spacing($isShrink ? 3 : 5.375)}px;
    padding: ${$isShrink ? theme.spacing(0.5, 1) : theme.spacing(1, 1.5)};
    border-radius: ${theme.spacing(1.25)}px;

    ${theme.breakpoints.down('xs')} {
      padding: ${$isShrink ? theme.spacing(0.5, 1) : theme.spacing(1.5, 1.5)};
      flex-direction: ${$isShrink || $isOneLine ? 'row' : 'column'};
      align-items: ${!$isOneLine ? 'flex-start' : 'center'};
      gap: ${theme.spacing(1.25)}px;
    }
  `
)

export const StyledIllustration = styled(Illustration)(
  ({ theme, $isShrink }: StyledProps<{ $isShrink?: boolean }>) => css`
    padding-top: ${$isShrink ? theme.spacing(0.5) : 0}px;
  `
)

export const StyledWarningContent = styled(Typography)(
  ({
    theme,
    $isShrink,
    $isDark,
    $color,
  }: StyledProps<{
    $isShrink?: boolean
    $isDark?: boolean
    $color?: string
  }>) => css`
    color: ${$color ||
    ($isDark ? theme.palette.text.primary : theme.palette.text.secondary)};
    font-size: ${theme.typography.pxToRem(12)};
    margin-left: ${theme.spacing(1)}px;
    padding-top: ${$isShrink ? theme.spacing(0.125) : 0}px;
    ${theme.breakpoints.down('xs')} {
      margin-left: ${theme.spacing(0)}px;
    }
  `
)
