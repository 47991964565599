import React, { useMemo, createContext, PropsWithChildren } from 'react'
import GTM from 'react-gtm-module'
import { ReportClubProfileReasonEnum } from 'src/generated/graphql'
import {
  UserProfileType,
  InstructorAvailabilityValue,
  PrivacySettingValue,
  ReportClubListingReasonEnum,
} from 'src/generated/graphql-react-query'
import useUser from 'src/hooks/useUser'

export const EVENT = 'event_'

export enum LocalAnalyticsEvent {
  // Walkthrough V2
  TutorialBegin = 'tutorial_begin',
  TutorialClose = 'tutorial_close',
  TutorialDone = 'tutorial_done',

  // Dashboard Instructor & Club
  DashboardInstructorClicked = 'dashboard_instructor_clicked',
  DashboardClubClicked = 'dashboard_club_clicked',

  // Instructor profile
  InstructorProfileTeachingStatus = 'instructor_profile_teaching_status',
  InstructorProfileFindWork = 'instructor_profile_find_work',
  InstructorProfilePreview = 'instructor_profile_preview',
  InstructorProfilePrivacySettingsSave = 'instructor_profile_privacy_settings_save',
  InstructorProfileAddAffiliate = 'instructor_profile_add_affiliate',
  InstructorWalkthrough = 'instructor_walkthrough',
  InstructorProfilePreviewAddToShortlist = 'instructor_profile_preview_add_to_shortlist',

  // Club profile
  ClubWalkthrough = 'club_walkthrough',

  // Find work
  FindWorkSearchResults = 'find_work_search_results',
  FindWorkSearchWatchlist = 'find_work_search_watchlist',
  FindWorkSearchSave = 'find_work_search_save',
  FindWorkSearchView = 'find_work_search_view',
  FindWorkSearchEnquire = 'find_work_search_enquire',
  FindWorkSearchReportListing = 'find_work_search_report_listing',
  FindWorkSearchCopyLink = 'find_work_search_copy_link',

  // Watchlist
  WatchlistEnquire = 'watchlist_enquire',
  WatchlistView = 'watchlist_enquire_view',

  // Enquiry
  EnquiryViewListing = 'enquiry_view_listing',
  EnquiryViewClubProfile = 'enquiry_view_club_profile',

  // Find instructors
  FindInstructorsResultsAddToShortList = 'find_instructors_results_add_to_shortlist',

  // Single job listing
  // TODO: consider changing this to 'job_listing_create_single_listing' for consistency
  SingleJobListing = 'single_job_listing',

  // MMP - In Studio
  InStudioManageProgram = 'in_studio_manage_program',
  InStudioMakeEnquiry = 'in_studio_make_enquiry',
  InStudioToolkitCard = 'in_studio_toolkit_card',
  InStudioExploreLiveAndVirtualPrograms = 'in_studio_explore_live_and_virtual_programs',

  // MMP - At Home
  AtHomeManageProgramDialog = 'at_home_manage_program_dialog',
  AtHomeEnquireProgramDialog = 'at_home_enquire_program_dialog',
  AtHomeManageProgram = 'at_home_manage_program',
  AtHomeMakeEnquiry = 'at_home_make_enquiry',
  AtHomeLinkButton = 'at_home_link_button',

  // MMP - ClubDashboard
  MMPClubDashboardAtHome = 'mmp_club_dashboard_at_home',
  MMPClubDashboardMyPrograms = 'mmp_club_dashboard_my_programs',
  MMPClubDashboardStandardTile = 'mmp_club_dashboard_standard_tile',
  MMPClubDashboardBusinessSupport = 'mmp_club_dashboard_business_support',

  // MMP - Business Support
  MMPBusinessSupport = 'mmp_business_support',

  // Shared Platform Navigation
  SharedPlatformNavigationOpenDialog = 'shared_platform_navigation_open_dialog',
  SharedPlatformNavitgationItem = 'shared_platform_navigation_item',

  // MMP - In Studio Explore Programs
  InStudioExploreProgramsCard = 'in_studio_explore_programs_card',
  InStudioExploreProgramsDialogSeeMore = 'in_studio_explore_programs_dialog_see_more',

  // MMP - In Studio Explore Programs - Enquiry Form
  InStudioExploreProgramsDialogEnquiryForm = 'in_studio_explore_programs_dialog_enquiry_form',

  // My People - Affiliated Instructors
  AffiliatedInstructorsLocationFilter = 'affiliated_instructors_location_filter',
  AffiliatedInstructorsNameSearchFilter = 'affiliated_instructors_name_search_filter',
  AffiliatedInstructorsProgramFilter = 'affiliated_instructors_program_filter',
  AffiliatedInstructorsExport = 'affiliated_instructors_export',
  AffiliatedInstructorsPrint = 'affiliated_instructors_print',
  AffiliatedInstructorsEditBilling = 'affiliated_instructors_edit_billing',
  AffiliatedInstructorsAddInstructor = 'affiliated_instructors_add_instructor',
  AffiliatedInstructorsSortInstructorsList = 'affiliated_instructors_sort_instructors_list',
  AffiliatedInstructorsExpandInstructorsList = 'affiliated_instructors_expand_instructors_list',
  AffiliatedInstructorsExpandInstructor = 'affiliated_instructors_expand_instructor',
  AffiliatedInstructorsViewProfile = 'affiliated_instructors_view_profile',
  AffiliatedInstructorsRemoveInstructor = 'affiliated_instructors_remove_instructor',
  AffiliatedInstructorConfirmRemove = 'affiliated_instructor_confirm_remove',
  AffiliatedInstructorDigitalOnlyCta = 'affiliated_instructor_digital_only_cta',

  // My People - Affiliated Instructors search and send request
  AffiliatedInstructorSendRequest = 'affiliated_instructor_send_request',

  // My People - Affiliated Requests
  AffiliatedRequestsNameSearchFilter = 'affiliated_requests_name_search_filter',
  AffiliatedRequestsLocationSort = 'affiliated_requests_location_sort',
  AffiliatedRequestsNameSort = 'affiliated_requests_name_sort',
  AffiliatedRequestsDecline = 'affiliated_requests_decline',
  AffiliatedRequestsRemoved = 'affiliated_requests_removed',
  AffiliatedRequestsAccepted = 'affiliated_requests_accepted',

  // Job Listing - Chain Improvements
  JobListingDeleteListingAction = 'job_listing_delete_listing_action',
  JobListingDeleteListingFromLocations = 'job_listing_delete_listing_from_locations',
  JobListingEditSingleListingAction = 'job_listing_edit_single_listing',
  JobListingEditSingleListingConfirmAction = 'job_listing_edit_single_listing_confirm',
  JobListingLocationFilter = 'job_listing_location_filter',
  JobListingProgramFilter = 'job_listing_program_filter',
  JobListingStatusFilter = 'job_listing_status_filter',
  JobListingCreateSingleListing = 'job_listing_create_listing',
  JobListingMultiListing = 'job_listing_multi_listing',
  JobListingClubProfile = 'job_listing_club_profile',
  JobListingSingleListing = 'job_listing_single_listing',
  JobListingPreviewSingleListing = 'job_listing_preview_single_listing',
  JobListingProgramSelectionNextButton = 'job_listing_program_selection_next_button',
  JobListingMultiLocationsNextButton = 'job_listing_multi_locations_next_button',
  JobListingDescriptionPreviewButton = 'job_listing_description_preview_button',
  JobListingPublishListingsButton = 'job_listing_publish_listings_button',
  JobListingDigitalOnlyExplorePrograms = 'job_listing_digital_only_explore_programs',
  JobListingCopyLink = 'job_listing_copy_link',

  // Instructor Account
  InstructorAccountQuickLinks = 'instructor_account_quick_links',

  // MMP - In Studio - Digital Only - Enquiry InStudio button
  InStudioDigitalOnlyEnquiryStudioPrograms = 'in_studio_digital_only_enquiry_studio_programs',
  InStudioDigitalOnlyMakeEnquiry = 'in_studio_digital_only_make_enquiry',

  // --------Training & Assessment--------
  // video section
  EventOutcomeUploadVideo = 'event_outcome_upload_video',
  EventOutcomeVideoGuidelinesView = 'event_outcome_video_guidelines_view',
  EventOutcomeVideoViewSubmission = 'event_outcome_video_view_submission',

  // Prework events page
  EventPreworkUploadVideo = 'event_prework_upload_video',
  EventPreworkVideoGuidelinesView = 'event_prework_video_guidelines_view',
  EventPreworkVideoViewSubmission = 'event_prework_video_view_submission',
  EventPreworkInteraction = 'event_prework_interaction',

  // booked events page
  EventPastEventsTrainingButton = 'event_past_events_training_button',
  EventIxPortalButton = 'event_ix_portal_button',
  EventPastEventsTrainingJourney = 'event_past_events_training_journey',
  EventCecCertificateButton = 'event_cec_certificate_button',
  EventViewDetail = 'event_view_detail',

  // training outcome page
  EventOutcomeDevelopementSheetDownload = 'event_outcome_development_sheet_download',
  EventOutcomeCecCertificateDownload = 'event_outcome_cec_certificate_download',
  EventOutcomeUploadVideoResubmit = 'event_outcome_upload_video_resubmit',
  EventOutcomeRequestExtension = 'event_outcome_request_extension',

  // Submit certification video section
  EventCertificationUploadVideo = 'event_certification_upload_video',
  EventCertificationVideoGuidelinesView = 'event_certification_video_guidelines_view',
  EventCertificationVideoViewSubmission = 'event_certification_video_view_submission',

  // Events Header
  EventLocationView = 'event_location_view',

  // Events Summary Stepper
  EventStepperTrainingOutcomeHelp = 'event_stepper_training_outcome_help',
  EventStepperCertificationOutcomeHelp = 'event_stepper_certification_outcome_help',

  // Events tabs
  EventTrainingStepsTab = 'event_training_steps_tab',
  EventDetailsTab = 'event_details_tab',
  EventViewCancellationPolicy = 'event_view_cancellation_policy',

  // training booked and emergency contact
  EventEmergencyContactButton = 'event_emergency_contact_button',
  EventEmergencyContactEdit = 'event_emergency_contact_edit',
  EventParq = 'event_parq',
  EventEmergencyContactConfirm = 'event_emergency_contact_confirm',

  // attend training step
  EventOnlineTrainingLink = 'event_online_training_link',
  EventVenueLocationLink = 'event_venue_location_link',

  // Certification Outcome step
  EventCertificationSheetDownload = 'event_certification_sheet_download',
  EventCertificationUploadVideoResubmit = 'event_certification_upload_video_resubmit',
  EventCertificationRequestExtension = 'event_certification_request_extension',

  // Congratulations step
  EventCompletedGetCertificate = 'event_completed_get_certificate',
  EventCompletedCompleteProfile = 'event_completed_complete_profile',
  EventCompletedSearchListings = 'event_completed_search_listings',
  EventCompletedAddFacility = 'event_completed_add_facility',

  // video upload dialogue
  EventVideoGuidelinesModalView = 'event_video_guidelines_modal_view',
  EventVideoModalSelect = 'event_video_modal_select',
  EventVideoModalUpload = 'event_video_modal_upload',
  EventVideoModalCancel = 'event_video_modal_cancel',
  EventVideoModalRetry = 'event_video_modal_retry',

  // booked trainer events page
  EventTrainerEventViewDetail = 'event_trainer_event_view_detail',
  EventTrainerEventIxPortalButton = 'event_trainer_event_ix_portal_button',

  // Trainer experience - Student block events page
  EventTrainerEditTrainingOutcome = 'event_trainer_edit_training_outcome',
  EventTrainerViewVideo = 'event_trainer_view_video',
  EventTrainerCancelEditTrainingOutcome = 'event_trainer_cancel_edit_training_outcome',
  EventTrainerSubmitTrainingOutcome = 'event_trainer_submit_training_outcome',

  EventInstructorLeadFindTraining = 'event_ixlead_find_training',

  // Affiliations
  AffiliationRequest = 'affiliation_request'
}

export enum Event {
  ListingClick = 'listing_click',
  MessageFlow = 'message_flow',
}

export enum EventLocation {
  FindClasses = 'Find Classes',
  ClubProfile = 'Club Profile',
}

export enum EventCategory {
  Tile = 'tile',
  Button = 'button',
  Dropdown = 'dropdown',
  Input = 'input',
}

export enum EventAction {
  Click = 'click',
  Select = 'select',
  Entered = 'entered',
}

export enum RequestType {
  Instructor = 'instructor',
  Club = 'club',
} 

export type UserProperties = {
  firstNameFilled: boolean
  lastNameFilled: boolean
  emailAddressFilled: boolean
  addressFilled: boolean
  phoneNumberFilled?: boolean
  teachingStatus?: InstructorAvailabilityValue
  hiringStatus?: boolean
  personalBioAvailable: boolean
  photosAvailable: boolean
  qualifiedPrograms?: string
  qualificationsAndInterestsAvailable?: boolean
  socialLinks?: string
  websiteAvailable?: boolean
  profileType: UserProfileType
  livePrograms?: string
  availableToSubstitute?: boolean
}

GTM.initialize({
  gtmId: import.meta.env.REACT_APP_GTM_TRACKING_ID!,
})

export interface AnalyticPlatform {
  setUserProperties: (properties: UserProperties) => void
  sendPageView: (screen: string) => void
  /**
   * @deprecated
   * We don't use this anymore, use pushToDatalayer instead
   * GA ticket won't need category and action anymore
   */
  sendEvent: (
    event: string,
    category: EventCategory,
    action: EventAction,
    params?: { [key: string]: any }
  ) => void
  pushToDatalayer: (params: { [key: string]: any }) => void
}

export const analyticsContext = createContext<AnalyticPlatform>({
  sendEvent: () => {},
  sendPageView: () => {},
  setUserProperties: () => {},
  pushToDatalayer: () => {},
})

analyticsContext.displayName = 'analyticsContext'
const { Provider } = analyticsContext

export const AnalyticsProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { data } = useUser()
  const GTMPlatform: AnalyticPlatform = useMemo(
    () => ({
      pushToDatalayer(params: { [key: string]: any }): void {
        try {
          GTM.dataLayer({
            dataLayer: params,
          })
        } catch (err) {
          if (import.meta.env.MODE === 'development') {
            console.warn('Error calling GTM on pushToDatalayer')
          }
        }
      },
      setUserProperties(properties: UserProperties): void {
        try {
          this.pushToDatalayer({
            user: undefined,
          })
          this.pushToDatalayer({
            user: properties,
            event: 'userProperties',
            user_id: data?.id,
          })
        } catch (err) {
          if (import.meta.env.MODE === 'development') {
            console.warn('Error calling GTM on setUserProperties')
          }
        }
      },
      sendEvent(
        event: string,
        category: EventCategory,
        action: EventAction,
        params?: { [key: string]: any }
      ): void {
        try {
          this.pushToDatalayer({
            eventParams: undefined,
          })
          this.pushToDatalayer({
            event: event.toLowerCase(),
            category,
            action,
            eventParams: params,
            user_id: data?.id,
          })
        } catch (err) {
          if (import.meta.env.MODE === 'development') {
            console.warn('Error calling GTM on sendEvent')
          }
        }
      },
      sendPageView(path: string): void {
        try {
          this.pushToDatalayer({
            eventParams: undefined,
          })
          this.pushToDatalayer({
            path,
            location: window.location.href,
            title: document.title,
            user_id: data?.id,
            event: 'pageview',
          })
        } catch (err) {
          if (import.meta.env.MODE === 'development') {
            console.warn('Error calling GTM on sendPageView')
          }
        }
      },
    }),
    [data?.id]
  )
  return <Provider value={GTMPlatform}>{children}</Provider>
}

export function formatInstructorAvailabiltyValue(
  value: InstructorAvailabilityValue
) {
  switch (value) {
    case InstructorAvailabilityValue.Available:
      return 'available to teach'
    case InstructorAvailabilityValue.Unavailable:
      return 'not looking to teach'
    case InstructorAvailabilityValue.Open:
      return 'open to opportunities'
  }
}

export function formatInstructorAvailableToSubstitute(value: boolean) {
  return value ? 'Available to cover classes' : 'Not available to cover classes'
}

export function formatJobListingRoleType(roleType: boolean) {
  return roleType ? 'Substitute role' : 'Regular role'
}

export function formatPrivacySettingValue(value: PrivacySettingValue) {
  switch (value) {
    case PrivacySettingValue.Private:
      return 'visible to me'
    case PrivacySettingValue.Public:
      return 'visible to all facilities'
    case PrivacySettingValue.Restricted:
      return 'visible to affiliated facilities'
  }
}

export function formatReportReasonValue<
  T extends ReportClubListingReasonEnum | ReportClubProfileReasonEnum
>(value: T) {
  switch (value) {
    case ReportClubListingReasonEnum.InappropriateContent:
      return 'inappropriate'
    case ReportClubListingReasonEnum.MisleadingFalseInformation:
      return 'misleading'
    case ReportClubListingReasonEnum.BullyingDiscriminationHarassment:
      return 'discrimination'
  }
}

export function formatSendMessageStepLabel({
  start = false,
  hasMessage = false,
}: {
  start?: boolean
  hasMessage?: boolean
}) {
  if (start) {
    return 'Send message start'
  } else {
    if (hasMessage) {
      return 'Send message complete - with message'
    } else {
      return 'Send message complete - with no message'
    }
  }
}
