import useProfile from 'src/hooks/useProfile'
import { UserRoleInput } from 'src/generated/graphql'
import { profileTypenameToUserRoleType } from 'src/utils/helpers'

const useRoleInput = () => {
  const {
    data: { profile },
  } = useProfile()
  const role: UserRoleInput = {
    id: profile?.id as string,
    roleType: profileTypenameToUserRoleType(profile?.__typename as string)!,
  }
  return {
    role,
  }
}

export default useRoleInput
