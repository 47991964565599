import React from 'react'
import ReactDOM from 'react-dom'

import * as serviceWorker from './serviceWorker'
import Providers from 'src/providers'
import { initRUM } from './utils/datadog'
import { createRoot } from 'react-dom/client'

initRUM()

const app = document.getElementById('root') as Element

const root = createRoot(app)

root.render(<Providers />)

if (import.meta.env.MODE !== 'production') {
  import('@axe-core/react').then(axe => {
    axe.default(React, ReactDOM, 1000)
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
