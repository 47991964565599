import React from 'react'
import styled, { css } from 'styled-components'

import { BoxProps } from '@material-ui/core'
import { Box } from 'src/adaptor/materialUI'

const Wrapper = styled(Box)<{ $fullWidth?: boolean }>(
  ({ theme, $fullWidth }) => css`
    width: 100%;
    padding: ${theme.spacing(0, $fullWidth ? 0 : 2)};
  `
)
const Content = styled(Box)(
  ({ theme }) => css`
    && {
      width: 100%;
      max-width: ${theme.breakpoints.values.lg + theme.spacing(1)}px; /*1288px*/
      margin: 0 auto;
    }
  `
)

const PageContainer: React.FC<BoxProps> = ({ children, ...props }) => (
  <Wrapper {...props}>
    <Content>{children}</Content>
  </Wrapper>
)

export default PageContainer
