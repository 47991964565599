import { useMemo, useState, useEffect } from 'react'

import { NavigationItemProps } from 'src/modules/SharedPlatformNavigation/NavigationItem'

import useProfiles from 'src/hooks/useProfiles'
import useProfile, {
  InstructorProfile,
  ClubProfile,
} from 'src/hooks/useProfile'
import useUser from 'src/hooks/useUser'
import useRoleInput from 'src/hooks/useRoleInput'

import {
  isTeachingProfile,
  isErrorResult,
  isSharedNavigationResult,
} from 'src/utils/typeGuards'

import {
  Maybe,
  useGetSharedNavigationLazyQuery,
  NavKey,
} from 'src/generated/graphql'

const useSharedPlatformNavigation = () => {
  const {
    data: { profile: activeProfile },
  } = useProfile()

  const {
    data: { user },
  } = useUser()

  const {
    data: { profiles },
  } = useProfiles()

  const { role } = useRoleInput()

  const [clubItems, setClubItems] = useState<Maybe<NavigationItemProps>[]>([])
  const [instructorItems, setInstructorItems] = useState<
    Maybe<NavigationItemProps>[]
  >([])
  const [isRefetching, setIsRefetching] = useState(false)

  const [getSharedNavItems, { data, error, loading, refetch }] =
    useGetSharedNavigationLazyQuery()

  const [[instructorProfile], clubProfiles] = useMemo(
    () =>
      profiles.reduce(
        (
          [instructorProfileAcc, clubProfileAcc]: [
            InstructorProfile[],
            ClubProfile[]
          ],
          profile
        ): [InstructorProfile[], ClubProfile[]] =>
          isTeachingProfile(profile)
            ? [[...instructorProfileAcc, profile], clubProfileAcc]
            : [instructorProfileAcc, [...clubProfileAcc, profile]],
        [[], []]
      ),
    [profiles]
  )

  const isDualAccount = useMemo(
    () => !!instructorProfile && !!clubProfiles?.length,
    [clubProfiles?.length, instructorProfile]
  )

  const isError = useMemo(
    () => !!(error || isErrorResult(data?.getSharedNavigation)),
    [data, error]
  )

  const handleRefetchWithLoading = async () => {
    if (refetch) {
      setIsRefetching(true)
      await refetch()
      setIsRefetching(false)
    }
  }

  useEffect(() => {
    if (!data || isErrorResult(data?.getSharedNavigation)) {
      setClubItems([])
      setInstructorItems([])
      return
    }

    if (
      data?.getSharedNavigation &&
      isSharedNavigationResult(data?.getSharedNavigation)
    ) {
      const { instructorNav, clubNav } = data?.getSharedNavigation

      const filteredInstructorItems = instructorNav?.navItems?.filter(item =>
        isDualAccount ? item?.key !== NavKey.LesmillsEquipmentInstructor : item
      )

      setInstructorItems(filteredInstructorItems ?? [])
      setClubItems(clubNav?.navItems ?? [])
    }
  }, [data, isDualAccount])

  const websiteLink = useMemo(
    () =>
      data?.getSharedNavigation &&
      !isErrorResult(data?.getSharedNavigation) &&
      isSharedNavigationResult(data?.getSharedNavigation)
        ? data?.getSharedNavigation.websiteLink
        : undefined,
    [data?.getSharedNavigation]
  )

  return {
    data: {
      instructorProfile,
      clubProfiles,
      instructorItems,
      clubItems,
      websiteLink,
      activeProfile,
      user,
      role,
      isLoading: loading,
      isRefetching,
      isError,
    },
    operations: {
      getSharedNavItems,
      refetch: handleRefetchWithLoading,
    },
  }
}

export default useSharedPlatformNavigation
