import React from 'react'
import { Typography } from '@material-ui/core'
import { Box } from 'src/adaptor/materialUI'
import { Error } from '@material-ui/icons'
import styled from 'styled-components'
import { Trans } from 'react-i18next'
import { SUPPORT_EMAIL } from 'src/utils/constants'
import { allowNewline } from 'src/utils/helpers'

interface ErrorCardProps {
  message?: string
}

const StyledBox = styled(Box)`
  width: 100%;
  min-height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`

const ErrorCard: React.FC<ErrorCardProps> = props => {
  return (
    <StyledBox>
      <Error color="error" />
      <Typography color="error" {...allowNewline}>
        {props.message || (
          <Trans
            i18nKey="Global.pageError"
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            components={[<a href={`mailto:${SUPPORT_EMAIL}`} />]}
            values={{
              email: SUPPORT_EMAIL,
            }}
          />
        )}
      </Typography>
    </StyledBox>
  )
}

export default ErrorCard
