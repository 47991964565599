// Adaptor to make sure material UI Box and Modal types will not cause type check errors
// TODO remove this file when all components are migrated to mui v5

import React from 'react'
import {
  Box as OriginalBox,
  BoxProps,
  Modal as OriginalModal,
  ModalProps,
} from '@material-ui/core'

export const Box = React.forwardRef<unknown, BoxProps>(function Box(
  props,
  ref
) {
  // @ts-ignore
  return <OriginalBox ref={ref} {...props} />
})

export function Modal(props: ModalProps) {
  return <OriginalModal {...props} />
}
