import styled, { css } from 'styled-components'
import BackgroundImage from 'src/assets/images/background-account-signup.jpg'
import { Typography, Paper } from '@material-ui/core'
import { Box } from 'src/adaptor/materialUI'
import Illustration, { LogoIcon } from 'src/components/dataDisplay/Illustration'
import Button from 'src/components/button/Button'
import NotAvailableBanner from 'src/assets/images/headers/not-available-banner.png'
import { useTranslation } from 'react-i18next'
import useLogout from 'src/hooks/useLogout'

const Layout = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url(${BackgroundImage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    ${theme.breakpoints.down('sm')} {
      background-position: right;
    }
  `
)

export const Container = styled(Paper)(
  ({ theme }) =>
    css`
      width: 582px;
      height: 729px;
      margin: auto;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;

      ${theme.breakpoints.down('sm')} {
        width: 100%;
        height: 100%;
        position: sticky;
        bottom: 0;
        transform: none;
        border-radius: 0px;
      }
    `
)

const TopContentContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: ${theme.spacing(8, 6)};
    background-color: ${theme.palette.brand.lightGray};

    ${theme.breakpoints.down('sm')} {
      height: 50%;
    }
  `
)

const BottomContentContainer = styled(Box)(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: ${theme.spacing(4)}px;

    ${theme.breakpoints.down('sm')} {
      height: 50%;
      padding: ${theme.spacing(2)}px;
    }
  `
)

const TextContainer = styled(Box)(
  ({ theme }) => css`
    text-align: center;
    padding-bottom: ${theme.spacing(3)}px;
  `
)

const StyledImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const UnsupportedMarket = () => {
  const { t } = useTranslation()
  const { logout } = useLogout()

  const handleClick = () => {
    window.open(t('UnsupportedMarket.contactUrl'), '_blank')
    logout()
  }

  return (
    <Layout>
      <Container elevation={4}>
        <TopContentContainer>
          <Illustration mb={6} type={LogoIcon.LesMillsBlack} />
          <StyledImg src={NotAvailableBanner} alt="Instructors in action" />
        </TopContentContainer>
        <BottomContentContainer>
          <TextContainer>
            <Typography variant="h6" gutterBottom>
              {t('UnsupportedMarket.title')}
            </Typography>
            <Typography variant="body1">
              {t('UnsupportedMarket.description')}
            </Typography>
          </TextContainer>
          <Button onClick={handleClick}>
            {t('UnsupportedMarket.buttonLabel')}
          </Button>
        </BottomContentContainer>
      </Container>
    </Layout>
  )
}

export default UnsupportedMarket
