import { useCallback, useEffect, useRef, useState } from 'react'
import { ContentContainerId } from 'src/components/layout/AuthorizedAppLayout'
import throttle from 'lodash.throttle'

interface UseContentScrollOptions {
  threshold?: number
  throttleMs?: number
  disableCalculations?: boolean // use only to capture the scroll event, no need for other calculations
  onScroll?: (event: Event) => void
}

// Handling scroll event(s) on the content container
const useContentScroll = (options?: UseContentScrollOptions) => {
  const {
    onScroll,
    threshold = 100,
    disableCalculations,
    throttleMs = 250,
  } = options || {}
  const el = useRef<HTMLElement | null>()
  const [isOnTop, setIsOnTop] = useState(false)
  const [isOnBottom, setIsOnBottom] = useState(false)

  const scrollHandler = useCallback(
    (event: Event) => {
      onScroll && onScroll(event)
      if (disableCalculations) {
        return
      }
      const el = event.target as HTMLElement
      const { offsetHeight, scrollTop, scrollHeight } = el
      setIsOnTop(scrollTop <= (threshold || 0))
      setIsOnBottom(offsetHeight + scrollTop >= scrollHeight - (threshold || 0))
    },
    [disableCalculations, onScroll, threshold]
  )

  const scrollToTop = () => {
    if (!el.current) {
      el.current = document.getElementById(ContentContainerId)
    }
    el.current?.scrollTo(0, 0)
  }

  const scrollToCoordinates = (xCoordinate?: number, yCoordinate?: number) => {
    if (!el.current) {
      el.current = document.getElementById(ContentContainerId)
    }
    el.current?.scrollTo(xCoordinate ?? 0, yCoordinate ?? 0)
  }

  const scrollIntoView = (elementId: string) => {
    const element = document.getElementById(elementId)
    element?.scrollIntoView()
  }

  useEffect(() => {
    // the hook got called without handler
    if (!onScroll && disableCalculations) {
      console.warn(
        'You have called useContentScroll hook without scroll handler and disabled calculations.'
      )
      return
    }
    const handler = throttleMs
      ? throttle(scrollHandler, throttleMs)
      : scrollHandler
    el.current = document.getElementById(ContentContainerId)
    el.current?.addEventListener('scroll', handler)
    return () => el.current?.removeEventListener('scroll', handler)
  }, [disableCalculations, onScroll, scrollHandler, throttleMs])

  return {
    scrollToTop,
    scrollToCoordinates,
    scrollIntoView,
    isOnTop,
    isOnBottom,
  }
}

export default useContentScroll
