import { Avatar, Typography } from '@mui/material'
import { Box } from 'src/adaptor/materialUI'
import React from 'react'
import styled, { css } from 'styled-components'
import { ProfileAvatar } from 'src/components/dataDisplay/ProfilePhoto'
import { Wrapper } from 'src/components/navigation/ProfileSection'

const AvatarCardContainer = styled(Box)`
  width: calc(100% - 55px);
  display: flex;
`

const StyledAvatar = styled(Avatar)`
  width: ${({ theme }) => theme.spacing(6)}px;
  height: ${({ theme }) => theme.spacing(6)}px;
`

const ContainerInfo = styled.div<{ $fullWidth?: boolean }>(
  ({ $fullWidth }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    max-width: ${$fullWidth ? '100%' : '140px'};
  `
)

const EllipsisText = styled(Typography)`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const StyledProfileAvatar = styled(ProfileAvatar)`
  min-width: 48px;
`

interface AvatarCardProps {
  photoUrl?: string
  name: string
  description?: string
  fullWidth?: boolean
  hideImage?: boolean
}

const AvatarCard: React.FC<AvatarCardProps> = ({
  photoUrl,
  name,
  description,
  fullWidth,
  hideImage,
}) => {
  return (
    <AvatarCardContainer>
      {photoUrl ? (
        <Box mr={2}>
          <StyledAvatar src={photoUrl} />
        </Box>
      ) : (
        !fullWidth &&
        !hideImage && (
          <Wrapper width={48} height={48} mr={2}>
            <StyledProfileAvatar $isSmallVersion>{name[0]}</StyledProfileAvatar>
          </Wrapper>
        )
      )}

      <ContainerInfo $fullWidth={fullWidth}>
        <EllipsisText variant="body2">{name}</EllipsisText>
        {description && (
          <EllipsisText variant="body1">{description}</EllipsisText>
        )}
      </ContainerInfo>
    </AvatarCardContainer>
  )
}

export default AvatarCard
