import React from 'react'
import styled from 'styled-components'

import { Checkbox, CheckboxProps } from '@material-ui/core'

import HeartFilled from 'src/components/icons/HeartFilled'
import HeartOutline from 'src/components/icons/HeartOutline'

const StyledCheckbox = styled(Checkbox)`
  &&.Mui-disabled {
    opacity: 0.45;
    cursor: unset;
  }
`

interface HeartCheckboxProps extends CheckboxProps {}
const HeartCheckbox: React.FC<HeartCheckboxProps> = ({
  onClick,
  disabled,
  ...props
}) => (
  <StyledCheckbox
    {...props}
    icon={<HeartOutline />}
    checkedIcon={<HeartFilled />}
    onClick={onClick}
    disabled={disabled}
  />
)

export default HeartCheckbox
