import React from 'react'
import styled, { css } from 'styled-components'

interface Props {
  children: React.ReactNode
  href: string
}

const Link = styled.a(
  ({ theme }) => css`
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
    clip: rect(0 0 0 0);

    &:active,
    &:focus {
      clip: auto;
      width: auto;
      height: auto;
      display: block;
      text-decoration: none;
      background-color: #fff;
      top: ${theme.spacing(1)}px;
      left: ${theme.spacing(1)}px;
      padding: ${theme.spacing(2)}px;
    }
  `
)

const SkipLink: React.FC<Props> = ({ href, children, ...props }) => {
  return (
    <Link href={href} {...props}>
      {children}
    </Link>
  )
}

export default SkipLink
