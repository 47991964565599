import { useContext } from 'react'

import { InitDataContext } from 'src/contexts/InitData'

import useProfile from 'src/hooks/useProfile'

import { isClubProfile } from 'src/utils/typeGuards'
import {
  WalkthroughRequired,
  UserRoleType,
} from 'src/generated/graphql-react-query'

export const useWalkthroughUtils = () => {
  const { user: userResult, updateUser } = useContext(InitDataContext)!

  const {
    data: { profile, selectedProfileId },
  } = useProfile()

  const isClubProfileSelected = !!profile && isClubProfile(profile)

  const {
    club: isClubProfileWalkthroughRequired,
    instructor: isInstructorProfileWalkthroughRequired,
  } = userResult?.user?.userMetaData?.walkthroughRequired as WalkthroughRequired

  const currentRole = isClubProfileSelected
    ? UserRoleType.Club
    : UserRoleType.Instructor

  const setCurrentProfileWalkthroughAsSeen = () => {
    const walkthroughRequired = {
      club: isClubProfileSelected ? false : isClubProfileWalkthroughRequired,
      instructor: !isClubProfileSelected
        ? false
        : isInstructorProfileWalkthroughRequired,
    }

    updateUser({
      ...userResult,
      user: {
        ...userResult!.user,
        userMetaData: {
          walkthroughRequired,
        },
      },
    })
  }

  return {
    currentRole,
    selectedProfileId,
    isClubProfileSelected,
    setCurrentProfileWalkthroughAsSeen,
    isClubProfileWalkthroughRequired,
    isInstructorProfileWalkthroughRequired,
  }
}
